import React, { Component } from "react";
import Swal from "sweetalert2";
import swal from "sweetalert";
import * as API from "../../configuration/apiconfig";
const renameFile = (originalFile, newName) => {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
};
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadStatus: false,
      imageValues: {
        loaded: false,
        url: "",
        type: "",
        name: "",
        source: "",
      },
    };
  }

  uploadFile = async (e) => {
    this.setState({
      uploadStatus: true,
    });
    try {
      let file = this.state.imageValues.source;

      const fileFormat = file.type.split("/")[1];
      const queryObj = {
        fileFormat,
        type: "roadblock",
      };

      await API.callEndpoint(
        "GET",
        "Bearer",
        `/api/files/presigned?fileFormat=${queryObj.fileFormat}&type=${queryObj.type}`
      )
        .then((response) => {
          const { key, url } = response.data.data;
          const fileName = `${key}.${fileFormat}`;
          const renamedFile = renameFile(file, fileName);

          const myHeaders = new Headers({ "Content-Type": "image/*" });
          fetch(url, {
            method: "PUT",
            headers: myHeaders,
            body: renamedFile,
          })
            .then(() => {
              API.callEndpoint(
                "GET",
                "Bearer",
                `/api/files/presigned-url?fileName=${fileName}&type=${queryObj.type}`
              )
                .then((response) => {
                  console.log(response.data?.data.split("?X-")[0]);
                  var raw = {
                    url: response.data?.data.split("?X-")[0],
                  };
                  API.callEndpoint(
                    "POST",
                    "Bearer",
                    `/api/road-block/update-image`,
                    raw
                  )
                    .then((response) => {
                      this.setState({
                        uploadStatus: false,
                      });
                      swal({
                        text: "Updated Success",
                        icon: "success",
                      });
                    })
                    .catch((error) => {
                      console.log(error.error);
                      swal({
                        text: "An error occured, try again!",
                        icon: "info",
                      });
                    });
                  this.setState({
                    tradeSuccessMsg: "Uploaded!",
                    tradeErrorMsg: "",
                    tradeImageURL: response.data?.data.split("?X-")[0],
                  });
                })
                .catch((error) => {
                  console.log(error.error);
                  this.setState({
                    tradeErrorMsg: error.error,
                    tradeSuccessMsg: "",
                  });
                });
            })
            .catch((error) => {
              console.log(error.error);
              this.setState({
                tradeErrorMsg: error.error,
                tradeSuccessMsg: "",
              });
            });
        })
        .catch((error) => {
          console.log(error.error);
          this.setState({ tradeErrorMsg: error.error, tradeSuccessMsg: "" });
        });
    } catch (e) {
      console.log(e);
      this.setState({ tradeErrorMsg: e.error, tradeSuccessMsg: "" });
    }
  };

  updateImage = async () => {
    this.uploadFile();
  };
  componentDidMount() {
    this.fetchRoadBlock();
  }

  fetchRoadBlock = async () => {
    const data = {};

    return await API.callEndpoint("GET", "Bearer", `/api/road-block`, data)
      .then((response) => {
        this.setState({
          imageValues: {
            loaded: true,
            url: response?.data?.data?.data?.url,
          },
        });
      })
      .catch((error) => {
        console.log(error.error);
        swal({
          text: "An error occured, try again!",
          icon: "info",
        });
      });
  };
  onImageChange = async (e) => {
    if (e?.target?.files && e?.target?.files[0]) {
      let img = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      var srcss = await getBase64(e.target.files[0]);
      // console.log(srcss, "srcss");
      if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
        this.setState({
          imageValues: {
            loaded: true,
            source: e?.target?.files[0],
            url: srcss,
          },
        });
      } else {
        // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
      }
    }
  };

  render() {
    return (
      <>
        <section className={`usermanage`}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1 className="head text-uppercase m-0 mt-lg-4 mb-lg-4 pt-lg-3 pb-lg-2">
              Road Block Image
            </h1>
          </div>
          <div className="row">
            <div className="col-md-3">
              <img
                style={{
                  width: "100%",
                }}
                src={this.state?.imageValues?.url}
              />
            </div>
            <div className="col-md-6">
              <div>
                <input
                  onChange={(e) => this.onImageChange(e)}
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                />
                <button
                  onClick={() => {
                    if(this.state.uploadStatus ==false){
                      this.updateImage()
                    }
                  }}
                  style={{
                    marginTop: 10,
                  }}
                  className="btn btn-primary"
                >
                  {this.state?.uploadStatus ? "Uploading...": "Upload"}
                </button>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default index;

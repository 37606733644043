import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Datatable from "react-bs-datatable";
import ReactExport from "react-export-excel";
import Moment from "moment";
import Select from "react-select";
import * as API from "../../configuration/apiconfig";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const header = [
  { title: "Name", prop: "name", filterable: true },
  { title: "Email", prop: "email", filterable: true },
  { title: "Mobile No.", prop: "mobile", filterable: true },
  { title: "Date of request", prop: "register", filterable: true },
  { title: "Experienced/Newbie", prop: "userexp", filterable: true },
  { title: "Sub", prop: "subscribe", filterable: true },
  { title: "Status", prop: "signup", filterable: true },
  { title: "Comment", prop: "comment", filterable: true },
];

const body = [
  {
    name: "Shameer",
    email: "shameer@influx.co.in",
    mobile: "98765431",
    register: "31.05.2021",
    userexp: "Experience",
    subscribe: "Free",
    signup: "Email",
    comment: "Comments",
  },
  {
    name: "Prateek",
    email: "prateek@influx.co.in",
    mobile: "25463656",
    register: "29.05.2021",
    userexp: "Experience",
    subscribe: "Crown",
    signup: "Mobile",
    comment: "Comments",
  },
  {
    name: "Lionel",
    email: "lionel@influx.co.in",
    mobile: "453654654",
    register: "26.05.2021",
    userexp: "Newbie",
    subscribe: "Free",
    signup: "Email",
    comment: "Comments",
  },
  {
    name: "Influx",
    email: "influx@influx.co.in",
    mobile: "453654654",
    register: "26.05.2021",
    userexp: "Newbie",
    subscribe: "Free",
    signup: "Email",
    comment: "Comments",
  },
];

const trderBody = [
  {
    name: "Shameer",
    email: "shameer@influx.co.in",
    mobile: "98765431",
    request: "31.05.2021",
    subscribe: "Free",
  },
  {
    name: "Prateek",
    email: "Prateek@influx.co.in",
    mobile: "25463656",
    request: "29.05.2021",
    subscribe: "Crown",
  },
  {
    name: "Lionel",
    email: "Lionel@influx.co.in",
    mobile: "453654654",
    request: "26.05.2021",
    subscribe: "Newbie",
  },
  {
    name: "Influx",
    email: "influx@influx.co.in",
    mobile: "453654654",
    request: "26.05.2021",
    subscribe: "Newbie",
  },
];
const traderHeader = [
  { title: "Name", prop: "name", filterable: true },
  { title: "Email", prop: "email", filterable: true },
  { title: "Mobile no", prop: "mobile", filterable: true },
  { title: "Date of request", prop: "request", filterable: true },
  { title: "Subscription details", prop: "subscribe", filterable: true },
];

const statusSelect = [
  { value: "Not interested", label: "Not interested" },
  { value: "Not Picking", label: "Not Picking" },
  { value: "Callback later", label: "Callback later" },
  { value: "Follow up ", label: "Follow up " },
  { value: "Closed for prime", label: "Closed for prime" },
  { value: "Closed for crown", label: "Closed for crown" },
];

const callBackData = [
  {
    name: "Shameer",
    email: "shameer@influx.co.in",
    mobileNo: "98765431	",
    request: "31.05.2021",
    expNewbie: "Experience",
    sub: "Free",
    comment: "Comments",
  },
  {
    name: "Prateek",
    email: "prateek@influx.co.in",
    mobileNo: "25463656",
    request: "29.05.2021",
    expNewbie: "Experience",
    sub: "Crown",
    comment: "Comments",
  },
  {
    name: "Lionel",
    email: "lionel@influx.co.in",
    mobileNo: "453654654",
    request: "26.05.2021",
    expNewbie: "Experience",
    sub: "Newbie",
    comment: "Comments",
  },
  {
    name: "Influx",
    email: "influx@influx.co.in",
    mobileNo: "453654654",
    request: "26.05.2021",
    expNewbie: "Experience",
    sub: "Newbie",
    comment: "Comments",
  },
];

class Sales extends Component {
  constructor(props) {
    let getDate = new Date();
    let getMonth = Moment(getDate).format("MMM");
    super(props);
    this.state = {
      fromdate: new Date(),
      todate: new Date(),
      getMonth: getMonth,

      tradeFromdate: new Date(),
      tradeTodate: new Date(),
      tradeGetMonth: getMonth,

      commentValue: "",
      commentsTotal: [],
      commentSelectValue: "",
      // commentTotalSelectValue: [],
      commentTotalSelectArray: [],
    };
  }

  // Comments > popup > textarea > onchange
  commentsOnchange = (e, parTxt) => {
    this.setState({
      [parTxt]: e.target.value,
    });
  };

  // Comments > popup > submit
  commentsSubmit = () => {
    if (this.state.commentValue !== "") {
      this.setState({
        commentsTotal: [
          ...this.state.commentsTotal,
          {
            text: this.state.commentValue,
          },
        ],
      });
      document.getElementById("comments").value = "";
      this.state.commentValue = "";
    }
  };

  // Comments select > onchange > function
  commentsSelectOnChange = (commentSelectValue, index) => {
    // console.log(commentSelectValue, index, "Index value");
    this.state.commentTotalSelectArray[index] = commentSelectValue.value;
    this.setState({
      commentSelectValue: commentSelectValue,
      commentTotalSelectArray: this.state.commentTotalSelectArray,
      commentSelectValue,
    });
  };

  render() {
    return (
      <section className="usermanage admin-sales">
        <h1 className="head text-uppercase m-0 mt-lg-4 mb-lg-4 pt-lg-3 pb-lg-2">
          Sales report
        </h1>
        <section className="sales-tabs-blk">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="call-back-report-tab"
                data-bs-toggle="tab"
                data-bs-target="#call-report"
                type="button"
                role="tab"
                aria-controls="call-report"
                aria-selected="true"
              >
                Call back report
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="trade-report-tab"
                data-bs-toggle="tab"
                data-bs-target="#trade-report"
                type="button"
                role="tab"
                aria-controls="trade-report"
                aria-selected="false"
              >
                2hr trader report
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="call-report"
              role="tabpanel"
              aria-labelledby="call-back-report-tab"
            >
              <section className={`contblk`}>
                <section className="row m-0 p-0">
                  <section className="col-lg-3 m-0 p-0">
                    <label className={`pe-2`}>From Date:</label>
                    <DatePicker
                      placeholderText="Select Date"
                      selected={this.state.fromdate}
                      onChange={(date) => this.setState({ fromdate: date })}
                    />
                  </section>
                  <section className="col-lg-3 m-0 p-0">
                    <label className={`pe-2`}>To Date:</label>
                    <DatePicker
                      placeholderText="Select Date"
                      selected={this.state.todate}
                      onChange={(date) => this.setState({ todate: date })}
                    />
                  </section>
                  {API.getCookie("role") === "CMSUSER" ? <section className="col-lg-6 m-0 p-0 btnexport">
                    <section className="row">
                      <section className="col-lg-3 offset-md-9">
                        <ExcelFile
                          filename={`user_${this.state.getMonth}`}
                          element={<button>Download</button>}
                        >
                          <ExcelSheet data={body} name="Users">
                            <ExcelColumn label="name" value="name" />
                            <ExcelColumn label="email" value="email" />
                            <ExcelColumn label="mobile" value="mobile" />
                            <ExcelColumn label="register" value="register" />
                            <ExcelColumn label="userexp" value="userexp" />
                            <ExcelColumn label="subscribe" value="subscribe" />
                            <ExcelColumn label="signup" value="signup" />
                            <ExcelColumn label="comment" value="comment" />
                          </ExcelSheet>
                        </ExcelFile>
                      </section>
                    </section>
                  </section> : null}
                </section>
                <section className={`row m-0 p-0 mt-3`}>
                  <aside className={`col-12 m-0 p-0`}>
                    {/* <Datatable tableHeaders={header} id="table-to-xls" tableBody={body} rowsPerPage={5} labels={{filterPlaceholder:'Search'}} /> */}
                    <section className="data-table">
                      <table className="table table-bordered">
                        <thead>
                          <tr className="bg">
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile no</th>
                            <th>Date of request</th>
                            <th>Experienced/Newbie</th>
                            <th>User type</th>
                            <th>Status</th>
                            <th>Comment</th>
                          </tr>
                        </thead>
                        <tbody>
                          {callBackData.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{data.name}</td>
                                <td>{data.email}</td>
                                <td>{data.mobileNo}</td>
                                <td>{data.request}</td>
                                <td>{data.expNewbie}</td>
                                <td>{data.sub}</td>
                                <td className="w-18">
                                  <aside className="member-select">
                                    <Select
                                      onChange={(e) =>
                                        this.commentsSelectOnChange(e, index)
                                      }
                                      options={statusSelect}
                                      placeholder="Select"
                                    />
                                  </aside>
                                </td>
                                <td>
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#commentsModal"
                                  >
                                    {data.comment}
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </section>
                  </aside>
                </section>
              </section>
            </div>
            <div
              className="tab-pane fade"
              id="trade-report"
              role="tabpanel"
              aria-labelledby="trade-report-tab"
            >
              <section className={`contblk`}>
                <section className="row m-0 p-0">
                  <section className="col-lg-3 m-0 p-0">
                    <label className={`pe-2`}>From Date:</label>
                    <DatePicker
                      placeholderText="Select Date"
                      selected={this.state.tradeFromdate}
                      onChange={(date) =>
                        this.setState({ tradeFromdate: date })
                      }
                    />
                  </section>
                  <section className="col-lg-3 m-0 p-0">
                    <label className={`pe-2`}>To Date:</label>
                    <DatePicker
                      placeholderText="Select Date"
                      selected={this.state.tradeTodate}
                      onChange={(date) => this.setState({ tradeTodate: date })}
                    />
                  </section>
                  <section className="col-lg-6 m-0 p-0 btnexport">
                    <section className="row">
                      <section className="col-lg-3 offset-md-9">
                        <ExcelFile
                          filename={`user_${this.state.getMonth}`}
                          element={<button>Download</button>}
                        >
                          <ExcelSheet data={trderBody} name="Users">
                            <ExcelColumn label="name" value="name" />
                            <ExcelColumn label="email" value="email" />
                            <ExcelColumn label="mobile" value="mobile" />
                            <ExcelColumn label="request" value="request" />
                            <ExcelColumn label="subscribe" value="subscribe" />
                          </ExcelSheet>
                        </ExcelFile>
                      </section>
                    </section>
                  </section>
                </section>
                <section className={`row m-0 p-0 mt-3`}>
                  <aside className={`col-12 m-0 p-0`}>
                    {/* <Datatable tableHeaders={traderHeader} id="table-to-xls" tableBody={trderBody} rowsPerPage={5} labels={{filterPlaceholder:'Search'}}/> */}
                    <section className="data-table">
                      <table className="table table-bordered">
                        <thead>
                          <tr className="bg">
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile no</th>
                            <th>Date of request</th>
                            <th>Subscription details</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Shameer</td>
                            <td>shameer@influx.co.in</td>
                            <td>98765431</td>
                            <td>31.05.2021</td>
                            <td>Free</td>
                          </tr>
                          <tr>
                            <td>Prateek</td>
                            <td>prateek@influx.co.in</td>
                            <td>25463656</td>
                            <td>29.05.2021</td>
                            <td>Crown</td>
                          </tr>
                          <tr>
                            <td>Lionel</td>
                            <td>lionel@influx.co.in</td>
                            <td>453654654</td>
                            <td>26.05.2021</td>
                            <td>Newbie</td>
                          </tr>
                          <tr>
                            <td>Influx</td>
                            <td>influx@influx.co.in</td>
                            <td>453654654</td>
                            <td>26.05.2021</td>
                            <td>Newbie</td>
                          </tr>
                        </tbody>
                      </table>
                    </section>
                  </aside>
                </section>
              </section>
            </div>
          </div>
        </section>

        <div
          className="modal fade comments-popup-blk"
          id="commentsModal"
          aria-labelledby="commentsLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="commentsLabel">
                  Add comments
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <section className="accordion-blk">
                  <div className="accordion" id="accordionExample">
                    {this.state.commentsTotal.map((comments, index) => {
                      return (
                        <div className="accordion-item" key={index}>
                          <h2
                            className="accordion-header"
                            id={`heading_${index + 1}`}
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse_${index + 1}`}
                              aria-expanded="false"
                              aria-controls={`collapse_${index + 1}`}
                            >
                              <span>{comments.text}</span>
                            </button>
                          </h2>
                          <div
                            id={`collapse_${index + 1}`}
                            className="accordion-collapse collapse"
                            aria-labelledby={`heading_${index + 1}`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>{comments.text}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </section>
                <textarea
                  id="comments"
                  className="form-control"
                  onChange={(e) => this.commentsOnchange(e, "commentValue")}
                ></textarea>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.commentsSubmit}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Sales;

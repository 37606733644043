import React from "react";
import { saveAs } from "file-saver";
import { NavLink } from "react-router-dom";
import Calendar from 'react-calendar';
import * as API from "../../configuration/apiconfig";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import Datatable from "react-bs-datatable";
import { async } from "q";
const format1 = "DD/MM/YYYY hh:mm A";



const calCulatePnlNew = (leg, leg_lot, trade_lot, info_lot) => {
  let { action, entry_price_start, exit_price, cmp, quantity, quanity, symbol } = leg
  let entry = parseFloat(entry_price_start)
  let exit = parseFloat(exit_price)
  let qty = parseInt(quantity)
  let lot = 1
  if (leg_lot) {
    lot = leg_lot
  } else {
    if (trade_lot) {
      lot = trade_lot
    }
  }
  if (exit_price == "") {
    exit = parseFloat(cmp)
  }
  if (isNaN(qty)) {
    qty = quanity
  }


  if (exit_price == '' && cmp == '') return ''
  // console.log("after null",symbol)
  let pnl = 0
  if (action == "BUY" || action == 'Buy') {
    pnl = (exit - entry) * (qty * lot)
  }
  if (action == "SELL" || action == 'Sell') {
    pnl = (entry - exit) * (qty * lot)
  }
  pnl = pnl.toFixed(2)
  return pnl
}

const restructureArray = (arr) => {
  // Separate blank rows and group non-blank rows by 'planid'
  const groups = {};
  const blankRows = [];
  
  arr.forEach((item, index) => {
    if (item.id.value === 'blank-row') {
      blankRows.push({ index, item });
    } else {
      if (!groups[item.planid.value]) {
        groups[item.planid.value] = [];
      }
      groups[item.planid.value].push(item);
    }
  });

  // Sort the planid keys to maintain the order of groups
  const sortedKeys = Object.keys(groups).sort((a, b) => parseInt(a) - parseInt(b));

  // Reconstruct the array
  const result = [];
  let currentGroupIndex = 0;
  
  arr.forEach((_, index) => {
    if (blankRows.length > 0 && blankRows[0].index === index) {
      // If the current index matches the index of the next blank row, insert the blank row
      result.push(blankRows.shift().item);
    } else {
      // Otherwise, insert the next item from the current group
      if (groups[sortedKeys[currentGroupIndex]] && groups[sortedKeys[currentGroupIndex]].length > 0) {
        result.push(groups[sortedKeys[currentGroupIndex]].shift());
      } else {
        // If the current group is empty, move to the next group
        currentGroupIndex++;
        if (groups[sortedKeys[currentGroupIndex]] && groups[sortedKeys[currentGroupIndex]].length > 0) {
          result.push(groups[sortedKeys[currentGroupIndex]].shift());
        }
      }
    }
  });

  return result;
};

class PnLReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calls: [],
      callsSummary: [],
      rawSpanApply: ['id','planid', 'scripname', 'createdAt', 'trade_type', 'net'],
      hideClosed: false,
      headerList: [{ title: 'Stock', prop: 'scripname' },
      { title: 'Plan Id', prop: 'id' },
      { title: 'Trade Id', prop: 'id' },
      { title: 'Strike', prop: 'strike' },
      { title: 'Date', prop: 'createdAt' },
      { title: 'Trade Type', prop: 'trade_type' },
      { title: 'Action', prop: 'action' },

      { title: 'Entry', prop: 'entry' },

      { title: 'Exit', prop: 'exit' },
      { title: 'No. Of lots', prop: 'numberOfLots' },
      { title: 'Lot', prop: 'lot' },
      { title: 'CMP', prop: 'cmp' },
      { title: 'Bid', prop: 'bid' },
      { title: 'Ask', prop: 'ask' },
      { title: 'PnL', prop: 'pnl' },
      { title: 'Net', prop: 'net' },
      // { title: 'Net B/A', prop: 'net' },
      { title: 'Rol', prop: 'rol' },
      { title: 'Status', prop: 'status' },
      { title: 'Capital', prop: 'capital' },
      { title: 'Booked', prop: 'booked' },
      { title: 'Last Modified', prop: 'last_modified' }
      ],
      onlyShowHeaderList: ['id', 'planid','scripname', 'createdAt', 'trade_type', 'net', 'last_modified']
    };
  }

  newCallFunction = (getTxt) => {
    this.setState({
      newcall: getTxt,
    });
  };

  getColor = (planid) => {
    if(planid == 1){
      return '#90ee90'
    }
    if(planid == 3){
      return '#ADD8E6'
    }
    if(planid == 2){
      return '#FBBF77'
    }
    if(planid == 5){
      return '#00ff00'
    }
    return '#fff'
  }

  getData = async () => {
    // `/api/calls/deleted-calls`
    await API.callEndpoint("GET", "Bearer", `/open/api/callspnl`)
      .then(async (response) => {
        // console.log(response, "=== bundle response")
        let formattedTrades = [];
        let formattedTradesSummary = []
        // filter out all trades which have all closed legs
        response.data = response.data.filter(obj => obj.info.legs.some(leg => leg.exit_price === ''));
        response.data.forEach(trade => {
          let TradeformattedTrades = []
          if (this.state.hideClosed) {
            trade.info.legs = trade.info.legs.filter(leg => leg.exit_price == "")

          }

          trade.info.legs.forEach((leg, index) => {
            // console.log(leg,trade)
            let obj
            if (index == 0) {
              obj = {
                id:
                {
                  value: trade.id,
                  rowSpan: trade.info.legs.length
                },
                planid :{
                  value: trade.planid,
                  rowSpan: trade.info.legs.length
                },
                scripname: { value: trade.scripname, rowSpan: trade.info.legs.length },
                strike: {
                  value: trade.call_class ? "Option" ? leg.strike_price
                    ? `${trade.scripname} ${leg?.series || ''}${leg?.strike_price || ''}`
                    : `${trade.scripname} ${leg?.series || ''}${leg?.strike || ''}` : trade.scripname : trade.scripname,
                  rowSpan: 1
                },
                action: { value: leg.action, rowSpan: 1 },
                entry: { value: `${leg.entry_price_start}`, rowSpan: 1 },
                exit: { value: `${leg.exit_price}`, rowSpan: 1 },
                numberOfLots: { value: leg.quantity, rowSpan: 1 },
                lot: { value: trade.info.lot_size, rowSpan: 1 },
                createdAt: { value: moment(trade.createdAt).format('DD-MM-yyyy'), rowSpan: trade.info.legs.length },
                trade_type: { value: trade.strategy, rowSpan: trade.info.legs.length },
                status: { value: leg.exit_price === "" ? 'Open' : 'Closed', rowSpan: 1 },

                cmp: {
                  value: leg.cmp, rowSpan: 1
                },
                bid: {
                  value: 0, rowSpan: 1
                },
                ask: {
                  value: 0, rowSpan: 1
                },
                // pnl: {
                //   value: leg.exit_price != "" 
                //   ? 
                //   calCulatePnl(leg.action, parseFloat(leg.entry_price_start),parseFloat(leg.exit_price),parseFloat(leg.quantity),parseFloat(trade.info.lot_size)) 
                //   : leg.cmp != "" ? calCulatePnl(leg.action, parseFloat(leg.entry_price_start),parseFloat(leg.cmp),parseFloat(leg.quantity),parseFloat(trade.info.lot_size)) : ''
                //   , rowSpan: 1               
                // },
                pnl: {
                  value: calCulatePnlNew(leg, trade.info.lot_size, trade.lot_size)
                },
                net: {
                  value: 0, rowSpan: trade.info.legs.length
                },
                rol: {
                  value: 0, rowSpan: trade.info.legs.length
                },
                capital: {
                  value: 0, rowSpan: trade.info.legs.length
                },
                booked: {
                  value: 0, rowSpan: trade.info.legs.length
                },
                last_modified: {
                  value: moment(trade.updatedAt).format('DD-MM-yyyy') != moment(trade.createdAt).format('DD-MM-yyyy')
                    ? moment(trade.updatedAt).format('DD-MM-yyyy') : '',
                  rowSpan: trade.info.legs.length
                }
              }
            } else {
              obj = {
                strike: {
                  value: trade.call_class ? "Option" ? leg.strike_price
                    ? `${trade.scripname} ${leg?.series || ''}${leg?.strike_price || ''}`
                    : `${trade.scripname} ${leg?.series || ''}${leg?.strike || ''}` : trade.scripname : trade.scripname,
                  rowSpan: 1
                },
                action: { value: leg.action, rowSpan: 1 },
                entry: { value: `${leg.entry_price_start}`, rowSpan: 1 },
                exit: { value: `${leg.exit_price}`, rowSpan: 1 },
                numberOfLots: { value: leg.quantity, rowSpan: 1 },
                lot: { value: trade.info.lot_size, rowSpan: 1 },
                // createdAt: { value : moment(trade.createdAt).format('DD-MM-yyyy'), rowSpan : 1},
                // trade_type: { value : index === 0 ? trade.strategy : '', rowSpan : 1},
                status: { value: leg.exit_price === "" ? 'Open' : 'Closed', rowSpan: 1 },

                cmp: {
                  value: leg.cmp, rowSpan: 1
                },
                bid: {
                  value: 0, rowSpan: 1
                },
                ask: {
                  value: 0, rowSpan: 1
                },
                // pnl: {
                //   value: leg.exit_price != "" 
                //   ? 
                //   calCulatePnl(leg.action, parseFloat(leg.entry_price_start),parseFloat(leg.exit_price),parseFloat(leg.quantity),parseFloat(trade.info.lot_size)) 
                //   : leg.cmp != "" ? calCulatePnl(leg.action, parseFloat(leg.entry_price_start),parseFloat(leg.cmp),parseFloat(leg.quantity),parseFloat(trade.info.lot_size)) : ''
                //   , rowSpan: 1               
                // },
                pnl: {
                  value: calCulatePnlNew(leg, trade.info.lot_size, trade.lot_size)
                },
              }
            }
            // console.log(obj,"==== obj")
            // formattedTrades.push(obj)
            TradeformattedTrades.push(obj);
          });
          let netCal = TradeformattedTrades.reduce((acc, curr) => {
            // console.log(curr)
            const value = Number(curr.pnl.value) || 0; // Convert value to number, if it's not a number, use 0
            return acc + value;
          }, 0);
          // console.log(netCal,"=== netcal")
          TradeformattedTrades[0].net.value = netCal
          // let tempArry = TradeformattedTrades[0]
          formattedTradesSummary.push(TradeformattedTrades[0])
          formattedTrades = [...formattedTrades, ...TradeformattedTrades]
          formattedTrades.push({
            id: { value: 'blank-row' }
          })

          formattedTradesSummary.push({
            id: { value: 'blank-row' }
          })
        });
        // console.log(formattedTrades)
        // console.log(formattedTradesSummary,"==== formatedsummary")
        this.setState({
          calls: formattedTrades,
          callsSummary: formattedTradesSummary
        });
        // console.log(this.state.calls)
      })
      .catch((error) => {
        console.log(error.error);
      });


  };

  componentDidMount = async () => {
    this.getDataInterval = setInterval(this.getData, 10000); // every 30 seconds
    await this.getData();
  };
  componentWillUnmount() {
    clearInterval(this.getDataInterval); // to clear the interval when the component unmounts
  }


  render() {
    return (
      <>
        <section className={`usermanage`}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1 className="head text-uppercase m-0 mt-lg-4 mb-lg-4 pt-lg-3 pb-lg-2">
              PnL Report
            </h1>
          </div>
          <section className={`contblk`}>

          </section>
          <div>
            Hide Closed

            <input

              style={{ appearance: 'auto',marginLeft:'10px',height:'20px',width:'20px',cursor:'pointer' }}
              type="checkbox"
              checked={this.state.hideClosed}
              onChange={(event) => { this.setState({ hideClosed: event.target.checked }); this.getData(); }}
            />
          </div>


          <h1><strong>Summary of PnL</strong></h1>
          <section className={`row m-0 p-0 mt-1`}>
            <aside style={{ overflow: 'auto' }} className={`col-12 m-0 p-0`}>
              {/* <span className="display-rows-placement">
                Display rows :{" "}
                <select
                  className="display-select"
                  onChange={(e) => {
                    if (this.state.Usertabledatas.length === 0) {
                      this.setState({
                        rowsPerPage: 1,
                      });
                    } else if (e.target.value === "All") {
                      this.setState({
                        rowsPerPage: this.state.Usertabledatas.length,
                      });
                    } else {
                      this.setState({ rowsPerPage: e.target.value });
                    }
                  }}
                >
                  {[5, 10, 15, 20, "All"].map((item) => {
                    return <option value={item}>{item}</option>;
                  })}
                </select>
              </span> */}

              <table class="table-datatable__root table"><thead class="thead"><tr class="thead-tr">
                {
                  this.state.headerList.filter(i => this.state.onlyShowHeaderList.includes(i.prop))
                    .map((i) => <td class="thead-th">{i.title}<span class="pull-right"></span></td>)
                }
              </tr></thead>
                <tbody className="tbody">
                  {
                    this.state.callsSummary.length > 0 && restructureArray(this.state.callsSummary).map((i) => (

                      <>
                        <>{
                          // JSON.stringify(i)
                        }</>
                        {
                          i?.id?.value == 'blank-row' ? <tr className="tbody-tr"><td className="tbody-td" colSpan={21}></td></tr> :

                            <tr className="tbody-tr" style={{background : this.getColor(i.planid.value)}}>
                              {
                                this.state.onlyShowHeaderList.includes('scripname') && i?.scripname && <td className="tbody-td" rowSpan={1}>{i.scripname.value}</td>
                              }

                              {
                                this.state.onlyShowHeaderList.includes('planid') && i?.scripname && <td className="tbody-td" rowSpan={1}>{i.planid.value}</td>
                              }

                              {
                                this.state.onlyShowHeaderList.includes('id') && i?.id && <td className="tbody-td" rowSpan={1} >{i.id.value}</td>
                              }
                              {this.state.onlyShowHeaderList.includes('strike') && <td className="tbody-td">{i.strike.value}</td>}
                              {

                                this.state.onlyShowHeaderList.includes('createdAt') && i?.createdAt && <td className="tbody-td" rowSpan={1} >{i.createdAt.value}</td>
                              }
                              {

                                this.state.onlyShowHeaderList.includes('trade_type') && i?.trade_type && <td className="tbody-td" rowSpan={1} >{i.trade_type.value}</td>
                              }
                              {/* <td className="tbody-td">{i.trade_type.value}</td> */}
                              {
                                this.state.onlyShowHeaderList.includes('action') && <td className="tbody-td">{i.action.value}</td>
                              }
                              {
                                this.state.onlyShowHeaderList.includes('entry') && <td className="tbody-td">{i.entry.value}</td>
                              }
                              {
                                this.state.onlyShowHeaderList.includes('exit') && <td className="tbody-td">{i.exit.value}</td>
                              }

                              {
                                this.state.onlyShowHeaderList.includes('numberOfLots') && <td className="tbody-td">{i.numberOfLots.value}</td>

                              }
                              {
                                this.state.onlyShowHeaderList.includes('lot') && <td className="tbody-td">{i.lot.value}</td>

                              }
                              {
                                this.state.onlyShowHeaderList.includes('cmp') && <td className="tbody-td">{i.cmp.value}</td>

                              }
                              {
                                this.state.onlyShowHeaderList.includes('bid') && <td className="tbody-td">{i.bid.value}</td>

                              }
                              {
                                this.state.onlyShowHeaderList.includes('ask') && <td className="tbody-td">{i.ask.value}</td>

                              }
                              {
                                this.state.onlyShowHeaderList.includes('pnl') && <td className="tbody-td">{i.pnl.value}</td>

                              }
                              {

                                this.state.onlyShowHeaderList.includes('net') && i?.net && <td className="tbody-td" rowSpan={1} >{i.net.value}</td>
                              }
                              {/* {

                                this.state.onlyShowHeaderList.includes('net') && i?.net && <td className="tbody-td" rowSpan={1} >{i.net.value}</td>
                              } */}
                              {

                                this.state.onlyShowHeaderList.includes('rol') && i?.rol && <td className="tbody-td" rowSpan={1} >{i.rol.value}</td>
                              }
                              {
                                this.state.onlyShowHeaderList.includes('status') && <td className="tbody-td">{i.status.value}</td>
                              }

                              {

                                this.state.onlyShowHeaderList.includes('capital') && i?.capital && <td className="tbody-td" rowSpan={1} >{i.capital.value}</td>
                              }
                              {/* <td className="tbody-td">{i.capital.value}</td> */}
                              {/* <td className="tbody-td">{i.booked.value}</td> */}
                              {

                                this.state.onlyShowHeaderList.includes('booked') && i?.booked && <td className="tbody-td" rowSpan={1} >{i.booked.value}</td>
                              }
                              {

                                this.state.onlyShowHeaderList.includes('last_modified') && i?.last_modified && <td className="tbody-td" rowSpan={1} >{i.last_modified.value}</td>
                              }

                              {/* { console.log(i?.last_modified.value,"====== ")} */}
                            </tr>
                        }
                      </>

                    ))
                  }
                </tbody>

              </table>
              {/* <td class="thead-th">Stock<span class="pull-right">
  </span></td><td class="thead-th">Trade Id<span class="pull-right"></span></td><td class="thead-th">Strike<span class="pull-right"></span></td><td class="thead-th">Date<span class="pull-right"></span></td><td class="thead-th">Trade Type<span class="pull-right"></span></td><td class="thead-th">Action<span class="pull-right"></span></td><td class="thead-th">Entry<span class="pull-right"></span></td><td class="thead-th">Exit<span class="pull-right"></span></td><td class="thead-th">No. Of lots<span class="pull-right"></span></td><td class="thead-th">Lot<span class="pull-right"></span></td><td class="thead-th">CMP<span class="pull-right"></span></td><td class="thead-th">Bid<span class="pull-right"></span></td><td class="thead-th">Ask<span class="pull-right"></span></td><td class="thead-th">PnL<span class="pull-right"></span></td><td class="thead-th">Net<span class="pull-right"></span></td><td class="thead-th">Net B/A<span class="pull-right"></span></td><td class="thead-th">Rol<span class="pull-right"></span></td><td class="thead-th">Status<span class="pull-right"></span></td><td class="thead-th">Capital<span class="pull-right"></span></td><td class="thead-th">Booked<span class="pull-right"></span></td><td class="thead-th">Last Modified<span class="pull-right"></span></td></tr></thead></table> */}

            </aside>
          </section>

          <h1><strong>In Details</strong></h1>
          <section className={`row m-0 p-0 mt-1`}>
            <aside style={{ overflow: 'auto' }} className={`col-12 m-0 p-0`}>
              {/* <span className="display-rows-placement">
                Display rows :{" "}
                <select
                  className="display-select"
                  onChange={(e) => {
                    if (this.state.Usertabledatas.length === 0) {
                      this.setState({
                        rowsPerPage: 1,
                      });
                    } else if (e.target.value === "All") {
                      this.setState({
                        rowsPerPage: this.state.Usertabledatas.length,
                      });
                    } else {
                      this.setState({ rowsPerPage: e.target.value });
                    }
                  }}
                >
                  {[5, 10, 15, 20, "All"].map((item) => {
                    return <option value={item}>{item}</option>;
                  })}
                </select>
              </span> */}

              <table class="table-datatable__root table"><thead class="thead"><tr class="thead-tr">
                {
                  this.state.headerList.map((i) => <td class="thead-th">{i.title}<span class="pull-right"></span></td>)
                }
              </tr></thead>
                <tbody className="tbody">
                  {
                    this.state.calls.length > 0 && this.state.calls.map((i) => (
                      <>
                        {
                          i?.id?.value == 'blank-row' ? <tr className="tbody-tr"><td className="tbody-td" colSpan={21}></td></tr> :

                            <tr className="tbody-tr">
                              {
                                i?.scripname && <td className="tbody-td" rowSpan={i.scripname.rowSpan}>{i.scripname.value}</td>
                              }

{
                                i?.planid && <td className="tbody-td" rowSpan={i.planid.rowSpan}>{i.planid.value}</td>
                              }

                              
                              {
                                i?.id && <td className="tbody-td" rowSpan={i.id.rowSpan} >{i.id.value}</td>
                              }
                              <td className="tbody-td">{i.strike.value}</td>
                              {

                                i?.createdAt && <td className="tbody-td" rowSpan={i.createdAt.rowSpan} >{i.createdAt.value}</td>
                              }
                              {

                                i?.trade_type && <td className="tbody-td" rowSpan={i.trade_type.rowSpan} >{i.trade_type.value}</td>
                              }
                              {/* <td className="tbody-td">{i.trade_type.value}</td> */}
                              <td className="tbody-td">{i.action.value}</td>
                              <td className="tbody-td">{i.entry.value}</td>
                              <td className="tbody-td">{i.exit.value}</td>
                              <td className="tbody-td">{i.numberOfLots.value}</td>
                              <td className="tbody-td">{i.lot.value}</td>
                              <td className="tbody-td">{i.cmp.value}</td>
                              <td className="tbody-td">{i.bid.value}</td>
                              <td className="tbody-td">{i.ask.value}</td>
                              <td className="tbody-td">{i.pnl.value}</td>
                              {

                                i?.net && <td className="tbody-td" rowSpan={i.net.rowSpan} >{i.net.value}</td>
                              }
                              {

                                i?.net && <td className="tbody-td" rowSpan={i.net.rowSpan} >{i.net.value}</td>
                              }
                              {

                                i?.rol && <td className="tbody-td" rowSpan={i.rol.rowSpan} >{i.rol.value}</td>
                              }
                              {/* <td className="tbody-td">{i.rol.value}</td> */}
                              {
                                //  ( this.state.hideClosed && i.status.value == 'Closed') ? <></> : 
                                <td className="tbody-td">{i.status.value}</td>
                              }

                              {

                                i?.capital && <td className="tbody-td" rowSpan={i.capital.rowSpan} >{i.capital.value}</td>
                              }
                              {/* <td className="tbody-td">{i.capital.value}</td> */}
                              {/* <td className="tbody-td">{i.booked.value}</td> */}
                              {

                                i?.booked && <td className="tbody-td" rowSpan={i.booked.rowSpan} >{i.booked.value}</td>
                              }
                              {

                                i?.last_modified && <td className="tbody-td" rowSpan={i.last_modified.rowSpan} >{i.last_modified.value}</td>
                              }
                            </tr>
                        }
                      </>

                    ))
                  }
                </tbody>

              </table>
              {/* <td class="thead-th">Stock<span class="pull-right">
  </span></td><td class="thead-th">Trade Id<span class="pull-right"></span></td><td class="thead-th">Strike<span class="pull-right"></span></td><td class="thead-th">Date<span class="pull-right"></span></td><td class="thead-th">Trade Type<span class="pull-right"></span></td><td class="thead-th">Action<span class="pull-right"></span></td><td class="thead-th">Entry<span class="pull-right"></span></td><td class="thead-th">Exit<span class="pull-right"></span></td><td class="thead-th">No. Of lots<span class="pull-right"></span></td><td class="thead-th">Lot<span class="pull-right"></span></td><td class="thead-th">CMP<span class="pull-right"></span></td><td class="thead-th">Bid<span class="pull-right"></span></td><td class="thead-th">Ask<span class="pull-right"></span></td><td class="thead-th">PnL<span class="pull-right"></span></td><td class="thead-th">Net<span class="pull-right"></span></td><td class="thead-th">Net B/A<span class="pull-right"></span></td><td class="thead-th">Rol<span class="pull-right"></span></td><td class="thead-th">Status<span class="pull-right"></span></td><td class="thead-th">Capital<span class="pull-right"></span></td><td class="thead-th">Booked<span class="pull-right"></span></td><td class="thead-th">Last Modified<span class="pull-right"></span></td></tr></thead></table> */}

            </aside>
          </section>
        </section>
        {/* </section> */}

      </>
    );
  }
}

export default PnLReport;

import React, { Component } from "react";
import Swal from "sweetalert2";
import * as API from "../../configuration/apiconfig";
import Memcontblk from "./Memcontblk";
import Videomodal from "./Videomodal";
import moment from "moment-timezone";
import Modifymodal from "./Modifymodal";
import Draganddrop from "./Draganddrop";
import { DateTimeFormatISO } from "../dateTimeFormat/DateTimeFormat";
import axios from "axios";
import { vimeoBearerToken } from "../../configuration/config";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const dateTimeFormat1 = "YYYY-MM-DD";
const dateTimeFormat2 = "DD/MM/YYYY h:mm A";
const config = {
  headers: { Authorization: `Bearer ${vimeoBearerToken}` },
};

const addmembertype = [
  // { value: "All", label: "All" },
  // { value: "Free", label: "Free" },
  // { value: "Prime", label: "Prime" },
  // { value: "Crown", label: "Crown" },
  { value: "Free", label: "Free" },
  { value: "Membership", label: "Membership" },
  { value: "2Hr Trader", label: "2Hr Trader" }
];

const categorytype = [
  { value: "Trading", label: "Trading" },
  { value: "Investment", label: "Investment" },
  { value: "Membership", label: "Membership" },
  { value: "Live from hedged", label: "Live from hedged" },
  { value: "Hedged tutorials", label: "Hedged tutorials" },
  { value: "2Hr Trader", label: "2Hr Trader" },
  { value: "Ace Of Spades", label: "Ace Of Spades" }
];


const videoType = [
  { value: "vimeo", label: "Vimeo" },
  { value: "zoom", label: "Zoom" },
];

const liveFromHedgedSelected = [
  { value: "Free", label: "Free" },
  { value: "Membership", label: "Membership" },
  { value: "2Hr Trader", label: "2Hr Trader" }
];

class Vidmaincomp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      fileinfo: "",
      imginfo: "",
      // curMemtab: "All",
      curMemtab: "Free",
      fakeobj: [
        {
          evtdt: new Date(),
          evttime: new Date(),
          bannerStartDate: new Date(),
          bannerEndDate: new Date(),
          featured: false,
          publish: false,
  
        },
      ],
      navList: [
        { id: 1, tabname: "Trading", navStatus: true },
        { id: 2, tabname: "Investment", navStatus: false },
        { id: 3, tabname: "Membership", navStatus: false },
        { id: 4, tabname: "Live from hedged", navStatus: false },
        { id: 5, tabname: "Hedged tutorials", navStatus: false },
        { id: 6, tabname: "2Hr Trader", navStatus: false },
        { id: 8, tabname: "Ace Of Spades", navStatus: false  },
        { id: 7, tabname: "Live Video", navStatus: false },
      ],
      navMembernav: [
        // { id: 1, tabname: "All", navStatus: true },
        // { id: 2, tabname: "Free", navStatus: false },
        // { id: 3, tabname: "Prime", navStatus: false },
        // { id: 4, tabname: "Crown", navStatus: false },
        { id: 1, tabname: "Free", navStatus: true },
        { id: 2, tabname: "Membership", navStatus: false },
        { id: 3, tabname: "2Hr Trader", navStatus: false }
      ],
      videolist: {
        Trading: [
          // {id:1,videourl:myvid,title:'Basics of Hedging',description:'Learn the basics of hedging',publishfrom:'25 mins 20 Apr - 1 May'},
          // {id:2,videourl:myvid,title:'Features of Hedging',description:'Learn the basics of hedging',publishfrom:'25 mins 20 Apr - 1 May'},
          // {id:3,videourl:myvid,title:'Basics of Hedging',description:'Learn the basics of hedging',publishfrom:'25 mins 20 Apr - 1 May'},
          // {id:4,videourl:myvid,title:'Features of Hedging',description:'Learn the basics of hedging',publishfrom:'25 mins 20 Apr - 1 May'},
          // {id:5,videourl:myvid,title:'Basics of Hedging',description:'Learn the basics of hedging',publishfrom:'25 mins 20 Apr - 1 May'},
          // {id:6,videourl:myvid,title:'Features of Hedging',description:'Learn the basics of hedging',publishfrom:'25 mins 20 Apr - 1 May'}
        ],
        Investment: [
          // {id:1,videourl:myvid,title:'Basics of Hedging',description:'Learn the basics of hedging',publishfrom:'25 mins 20 Apr - 1 May'},
          // {id:2,videourl:myvid,title:'Features of Hedging',description:'Learn the basics of hedging',publishfrom:'25 mins 20 Apr - 1 May'},
          // {id:3,videourl:myvid,title:'Basics of Hedging',description:'Learn the basics of hedging',publishfrom:'25 mins 20 Apr - 1 May'},
          // {id:4,videourl:myvid,title:'Features of Hedging',description:'Learn the basics of hedging',publishfrom:'25 mins 20 Apr - 1 May'}
        ],
        Membership: [
          // {id:1,videourl:myvid,title:'Basics of Hedging',description:'Learn the basics of hedging',publishfrom:'25 mins 20 Apr - 1 May',subnav:'Free'},
          // {id:2,videourl:myvid,title:'Features of Hedging',description:'Learn the basics of hedging',publishfrom:'25 mins 20 Apr - 1 May',subnav:'Prime'},
          // {id:3,videourl:myvid,title:'Advantages of Hedging',description:'Learn the basics of hedging',publishfrom:'25 mins 20 Apr - 1 May',subnav:'Crown'},
          // {id:4,videourl:myvid,title:'Basics of Hedging',description:'Learn the basics of hedging',publishfrom:'25 mins 20 Apr - 1 May',subnav:'Crown'},
          // {id:5,videourl:myvid,title:'Advantages of Hedging',description:'Learn the basics of hedging',publishfrom:'25 mins 20 Apr - 1 May',subnav:'Crown'},
          // {id:6,videourl:myvid,title:'Basics of Hedging',description:'Learn the basics of hedging',publishfrom:'25 mins 20 Apr - 1 May',subnav:'Free'}
        ],
        LiveVideo: [
          // {id:1,videourl:myvid,title:'Basics of Hedging',description:'Learn the basics of hedging',publishfrom:'25 mins 20 Apr - 1 May', subnav:'Free'},
          // {id:2,videourl:myvid,title:'Features of Hedging',description:'Learn the basics of hedging',publishfrom:'25 mins 20 Apr - 1 May', subnav:'Prime'},
          // {id:3,videourl:myvid,title:'Basics of Hedging',description:'Learn the basics of hedging',publishfrom:'25 mins 20 Apr - 1 May',subnav:'Crown'},
          // {id:4,videourl:myvid,title:'Features of Hedging',description:'Learn the basics of hedging',publishfrom:'25 mins 20 Apr - 1 May', subnav:'Free'},
          // {id:5,videourl:myvid,title:'Basics of Hedging',description:'Learn the basics of hedging',publishfrom:'25 mins 20 Apr - 1 May', subnav:'Crown'}
        ],

        isAvail: false,
      },
      vidmodal: {
        addvid: false,
        livvid: false,
        memType: "",
        catType: "",
        livmemtyp: "",
        vidsize: 20,
        alertbox: false,
        alertmsg: "",
        iscatmem: "",
      },
      modifymodal: {
        showModal: false,
      },
      publishOnOffState: false,
      featuredOnOffState: false,
      videoListAPIData: [],
      addVideoGetID: "",
      subCategoryDisabled: false,
      liveEditSaveBtnShow: false,
      liveEditGetId: "",
      dragAndDrop: false,
      curTab: "Trading",
      gridInitiated: false,
      videoPutDataSave: [],
      lockModifyVideoCall: true,
    };
  }
  componentDidMount() {
    //     let newadvid = new Object();
    //     newadvid.videourl = '';
    //     newadvid.title = '';
    //     newadvid.description = '';
    //     newadvid.category = '';
    //     newadvid.membership = '';
    //     newadvid.publishfrom = '';
    //     newadvid.publishto = '';
    //     let copyfakeobj = [...this.state.fakeobj, newadvid];
    //     this.setState({showModal:true,fakeobj:copyfakeobj})
    // let makeobj =URL.createObjectURL(myvid);
    // //console.log(makeobj);
    this.videoListAPI();
  }
  openModal = () => {
    this.setState({
      showModal: true,
      liveEditSaveBtnShow: false,
      imginfo: "",
    });
    let videoModal = this.state.vidmodal;
    videoModal.addvid = false;
  };
  hidemodalmodify = () => {
    let getobj = [...this.state.fakeobj];
    let getObjj = { ...this.state.modifymodal };
    getobj.pop();
    getObjj.showModal = false;
    this.setState({ fakeobj: getobj, modifymodal: getObjj });
  };
  hidemodal = () => {
    let getobj = [...this.state.fakeobj];
    let getObjj = { ...this.state.vidmodal };
    getobj.pop();
    getObjj.addvid = false;
    getObjj.livvid = false;
    getObjj.iscatmem = false;
    getObjj.alertmsg = "";
    getObjj.alertbox = false;
    this.setState({
      showModal: false,
      fakeobj: getobj,
      vidmodal: getObjj,
      fileinfo: "",
    });
  };

  videoListAPI = () => {
    API.callEndpoint("GET", "Bearer", "/api/videos")
      .then((response) => {
        this.setState({
          videoListAPIData: response.data,
        });
      })
      .catch((error) => {
        //console.log(error.error);
      });
  };

  // addvidsave = () => {
  //   let getObj = [...this.state.fakeobj];
  //   let getvidmodalState = { ...this.state.vidmodal };
  //   let getoriobj = { ...this.state.videolist };
  //   let getCatname = getObj[0].category.replace(" ", "");
  //   let getMemname = getObj[0].membership;
  //   let getLength = getoriobj[getCatname].length;
  //   let getPubfrm = getObj[0].publishfrom;
  //   let getPubto = getObj[0].publishto;
  //   let setPubfrm = Moment(getPubfrm).format("Do MMM");
  //   let setPubto = Moment(getPubto).format("Do MMM");
  //   getObj[0].id = getLength + 1;
  //   getObj[0].publishfrom = setPubfrm;
  //   getObj[0].publishto = setPubto;
  //   getObj[0].publishfromfull = getObj[0].publishfrom;
  //   getObj[0].publishtofull = getObj[0].publishto;
  //   //console.log(getObj[0].publishfromfull);
  //   //console.log(getObj[0].publishtofull);
  //   if (getCatname === "Membership") {
  //     getObj[0].subnav = getMemname;
  //     getoriobj[getCatname].push(getObj[0]);
  //   } else {
  //     getoriobj[getCatname].push(getObj[0]);
  //   }
  //   getoriobj.isAvail = true;
  //   getvidmodalState.addvid = false;
  //   getvidmodalState.livvid = false;
  //   getvidmodalState.iscatmem = false;
  //   getObj.pop();
  //   //console.log(getoriobj);
  //   this.setState({
  //     videolist: getoriobj,
  //     fakeobj: getObj,
  //     vidmodal: getvidmodalState,
  //     showModal: false,
  //     fileinfo: "",
  //   });
  // };

  fetchImageUrl = async (videoid) => {
    return await axios
      .get(
        `https://api.vimeo.com/videos/${this.state.fakeobj[0].videourl}`,
        config
      )
      .then((response) => {
        try {
          let sizeArr = response.data.pictures.sizes;
          // check with there is size 640
          let neededSize = sizeArr.filter((item) => item.width === 640);
          // if 640 width not present use the last element in the array
          if (neededSize.length === 0) {
            neededSize.push(sizeArr[sizeArr.length - 1]);
          }
          return neededSize[0].link;
        } catch (e) {
          console.log(e);
          return "";
        }
      })
      .catch((error) => {
        console.log(error);
        return "";
      });
  };

  addvidsave = async () => {
    if (this.state.lockModifyVideoCall) {
      this.setState({ showModal: false, lockModifyVideoCall: false });
      const addVideoFormData = {};
      addVideoFormData.url = this.state.fakeobj[0].videourl;
      addVideoFormData.imageUrl = await this.fetchImageUrl(
        this.state.fakeobj[0].videourl
      );

      addVideoFormData.islive = false;
      addVideoFormData.source = this.state.fakeobj[0].source ?  this.state.fakeobj[0].source :  "vimeo";
      addVideoFormData.zoomPassCode = this.state.fakeobj[0].password  ?  this.state.fakeobj[0].password  :  "";

      addVideoFormData.description = this.state.fakeobj[0].description;
      addVideoFormData.category = this.state.fakeobj[0].category;
      addVideoFormData.subcategory = this.state.fakeobj[0].hedgedCategory;

      addVideoFormData.publish =
        this.state.fakeobj[0].publish === undefined
          ? false
          : this.state.fakeobj[0].publish;

      addVideoFormData.organiser = null;
      addVideoFormData.isFeature =
        this.state.fakeobj[0].featured === undefined
          ? false
          : this.state.fakeobj[0].featured;

      addVideoFormData.eventDate = null;
      addVideoFormData.eventTime = null;
      addVideoFormData.bannerStartDate = null;
      addVideoFormData.bannerEndtime = null;
      addVideoFormData.file = null;
      addVideoFormData.topic = this.state.fakeobj[0].title;

      API.callEndpoint("POST", "Bearer", "/api/videos", addVideoFormData)
        .then((response) => {
          //console.log(response.data);

          // let newadvid = this.state.fakeobj[0];
          // newadvid.title = "";
          // newadvid.description = "";
          // newadvid.videourl = "";
          // newadvid.category = null;
          // newadvid.publish = false;
          // newadvid.featured = false;

          this.videoListAPI();
          let hedgedSubCat = this.state.vidmodal;
          hedgedSubCat.iscatmem = false;

          this.setState({
            fakeobj: [
              {
                evtdt: new Date(),
                evttime: new Date(),
                bannerStartDate: new Date(),
                bannerEndDate: new Date(),
                featured: false,
                publish: false,
              },
            ],
            featuredOnOffState: false,
            publishOnOffState: false,
            vidmodal: hedgedSubCat,
            lockModifyVideoCall: true,
          });
        })
        .catch((error) => {
          //console.log(error.error);
          let hedgedSubCat = this.state.vidmodal;
          hedgedSubCat.iscatmem = false;
          this.setState({
            fakeobj: [],
            featuredOnOffState: false,
            publishOnOffState: false,
            vidmodal: hedgedSubCat,
            lockModifyVideoCall: true,
          });
        });
    }
  };

  newVideoSaveDisabled = () => {
    if (this.state.subCategoryDisabled == false) {
      // //console.log("if");
      var disabled = false;
      if (this.state.fakeobj.length > 0) {
        if (
          this.state.fakeobj[0].title == "" ||
          this.state.fakeobj[0].videourl == "" ||
          this.state.fakeobj[0].description == "" ||
          this.state.fakeobj[0].category == null
        ) {
          disabled = true;
        } else {
          disabled = false;
        }
        return disabled;
      }
    } else {
      //console.log("else");
      var disabled = false;
      if (this.state.fakeobj.length > 0) {
        if (
          this.state.fakeobj[0].title == "" ||
          this.state.fakeobj[0].videourl == "" ||
          this.state.fakeobj[0].description == "" ||
          this.state.fakeobj[0].category == null ||
          this.state.fakeobj[0].hedgedCategory == ""
        ) {
          disabled = true;
        } else {
          disabled = false;
        }
        return disabled;
      }
    }
  };

  videoEditBtn = (getId) => {
    // console.log(getId, "get ID edit");
    let getModimodal = { ...this.state.modifymodal };
    let getObjj = this.state.vidmodal;
    getModimodal.showModal = true;
    getObjj.iscatmem = false;
    this.setState({ modifymodal: getModimodal, addVideoGetID: getId });

    // let createObj = new Object();
    // createObj.title = "";
    // createObj.description = "";
    // createObj.videourl = "";
    // createObj.category = null;
    // createObj.hedgedCategory = "";
    // createObj.publish = false;
    // createObj.featured = false;
    // let pushArry = [];
    // pushArry.push(createObj);
    // this.setState({
    //   fakeobj: pushArry,
    // });

    {
      this.state.videoListAPIData
        .filter((getDataID) => getDataID.id == getId)
        .map((filteredEditId) =>
          this.setState(
            {
              videoPutDataSave: filteredEditId,
            },
            () => {
              let createObj = new Object();
              createObj.title = this.state.videoPutDataSave.topic;
              createObj.source = this.state.videoPutDataSave.source;
              createObj.password = this.state.videoPutDataSave.zoomPassCode;
              createObj.description = this.state.videoPutDataSave.description;
              createObj.videourl = this.state.videoPutDataSave.url;
              createObj.category = this.state.videoPutDataSave.category;
              createObj.hedgedCategory =
                this.state.videoPutDataSave.subcategory;
              createObj.publish = this.state.videoPutDataSave.publish;
              createObj.featured = this.state.videoPutDataSave.isFeature;
              let pushArry = [];
              pushArry.push(createObj);
              this.setState({
                fakeobj: pushArry,
              });
            }
          )
        );
    }
  };

  videoDeleteBtn = (getId) => {
    //console.log(getId, "get id delete");
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        API.callEndpoint("DELETE", "Bearer", `/api/videos/${getId}`)
          .then((response) => {
            //console.log(response.data);
            this.videoListAPI();
          })
          .catch((error) => {
            //console.log(error.error);
            Swal.fire(error.error);
          });
      }
    });
  };

  addModifyVidsave = async () => {
    if (this.state.lockModifyVideoCall) {
      this.setState({ lockModifyVideoCall: false });
      let getId = this.state.addVideoGetID;

      const addModifyFormData = {};
      addModifyFormData.id = getId;
      addModifyFormData.url = this.state.fakeobj[0].videourl;
      addModifyFormData.imageUrl = await this.fetchImageUrl(
        this.state.fakeobj[0].videourl
      );

      addModifyFormData.islive = false;
      // addModifyFormData.source = "vimeo";
      addModifyFormData.source = this.state.fakeobj[0].source ?  this.state.fakeobj[0].source :  "vimeo";
      addModifyFormData.zoomPassCode = this.state.fakeobj[0].password  ?  this.state.fakeobj[0].password  :  "";
      addModifyFormData.description = this.state.fakeobj[0].description;

      addModifyFormData.category = this.state.fakeobj[0].category;
      addModifyFormData.subcategory = this.state.fakeobj[0].hedgedCategory;

      addModifyFormData.publish =
        this.state.fakeobj[0].publish === undefined
          ? false
          : this.state.fakeobj[0].publish;

      addModifyFormData.organiser = null;
      addModifyFormData.isFeature =
        this.state.fakeobj[0].featured === undefined
          ? false
          : this.state.fakeobj[0].featured;

      addModifyFormData.eventDate = null;
      addModifyFormData.eventTime = null;
      addModifyFormData.bannerStartDate = null;
      addModifyFormData.bannerEndtime = null;
      addModifyFormData.file = null;
      addModifyFormData.topic = this.state.fakeobj[0].title;

      API.callEndpoint("PUT", "Bearer", "/api/videos", addModifyFormData)
        .then((response) => {
          // console.log(response.data);
          let getModimodal = { ...this.state.modifymodal };
          getModimodal.showModal = false;
          this.setState({
            fakeobj: [
              {
                evtdt: new Date(),
                evttime: new Date(),
                bannerStartDate: new Date(),
                bannerEndDate: new Date(),
                featured: false,
                publish: false,
              },
            ],
            modifymodal: getModimodal,
            addVideoGetID: "",
            lockModifyVideoCall: true,
            publishOnOffState: false,
            featuredOnOffState: false,
          });
          this.videoListAPI();
        })
        .catch((error) => {
          //console.log(error.error);
          this.setState({
            lockModifyVideoCall: true,
            publishOnOffState: false,
            featuredOnOffState: false,
          });
        });
    }
  };

  // lividsave = () => {
  //   let getObj = [...this.state.fakeobj];
  //   let getvidmodalState = { ...this.state.vidmodal };
  //   let getoriobj = { ...this.state.videolist };
  //   let getMemname = getObj[0].membership;
  //   let getevtdt = getObj[0].evtdt;
  //   let getevttme = getObj[0].evttime;
  //   let getcountdwn = getObj[0].countdown;
  //   let setevtdt = Moment(getevtdt).format("Do MMM");
  //   let setevttm = Moment(getevttme).format("h:mm a");
  //   let setcountdown = Moment(getcountdwn).format("MMMM Do YYYY, h:mm:ss a");
  //   getObj[0].evtdt = setevtdt;
  //   getObj[0].evttime = setevttm;
  //   getObj[0].countdown = setcountdown;
  //   getObj[0].subnav = getMemname;
  //   getObj[0].category = "LiveVideo";
  //   getoriobj.LiveVideo.push(getObj[0]);
  //   getvidmodalState.addvid = false;
  //   getvidmodalState.livvid = false;
  //   getObj.pop();
  //   this.setState({
  //     videolist: getoriobj,
  //     fakeobj: getObj,
  //     vidmodal: getvidmodalState,
  //     showModal: false,
  //     fileinfo: "",
  //   });
  // };

  lividsave = async () => {
    if (this.state.lockModifyVideoCall) {
      this.setState({ lockModifyVideoCall: false });
      let getvidmodalState = { ...this.state.vidmodal };
      getvidmodalState.addvid = false;
      getvidmodalState.livvid = false;
      this.setState({
        vidmodal: getvidmodalState,
        showModal: false,
      });

      const liveVideoFormData = {};
      liveVideoFormData.url = this.state.fakeobj[0].videourl;
      liveVideoFormData.imageUrl = await this.fetchImageUrl(
        this.state.fakeobj[0].videourl
      );

      liveVideoFormData.islive = true;
      // liveVideoFormData.source = "vimeo";
      liveVideoFormData.source = this.state.fakeobj[0].source ?  this.state.fakeobj[0].source :  "vimeo";
      liveVideoFormData.zoomPassCode = this.state.fakeobj[0].password  ?  this.state.fakeobj[0].password  :  "";
      liveVideoFormData.description = this.state.fakeobj[0].description;

      liveVideoFormData.category = null;
      liveVideoFormData.subcategory = this.state.fakeobj[0].membership;
      liveVideoFormData.publish = true;
      liveVideoFormData.organiser = this.state.fakeobj[0].organiser;
      liveVideoFormData.isFeature = null;
      liveVideoFormData.eventDate = moment(this.state.fakeobj[0].evtdt).format(
        dateTimeFormat1
      );

      liveVideoFormData.eventTime = moment(
        this.state.fakeobj[0].evttime
      ).format("HH:mm:ss");

      liveVideoFormData.bannerStartDate = moment(
        this.state.fakeobj[0].bannerStartDate
      )
        .tz("UTC")
        .format();

      liveVideoFormData.bannerEndDate = moment(
        this.state.fakeobj[0].bannerEndDate
      )
        .tz("UTC")
        .format();

      const fileData = await getBase64(this.state.imginfo);
      liveVideoFormData.append("file", fileData);
      liveVideoFormData.append("topic", this.state.fakeobj[0].topic);

      // console.log( this.state.fakeobj[0],
      //   this.state.fakeobj[0].evtdt,moment(this.state.fakeobj[0].evtdt).tz('UTC').format(),
      //   this.state.fakeobj[0].evttime, moment(this.state.fakeobj[0].evttime).format("LT"),
      //   this.state.fakeobj[0].bannerStartDate,moment(this.state.fakeobj[0].bannerStartDate).tz('UTC').format(),
      //   this.state.fakeobj[0].bannerEndDate,moment(this.state.fakeobj[0].bannerEndDate).tz('UTC').format()
      // )

      API.callEndpoint("POST", "Bearer", "/api/videos", liveVideoFormData)
        .then((response) => {
          //console.log(response.data);
          this.videoListAPI();
          this.setState({
            fakeobj: [
              {
                evtdt: new Date(),
                evttime: new Date(),
                bannerStartDate: new Date(),
                bannerEndDate: new Date(),
              },
            ],
            imginfo: "",
            lockModifyVideoCall: true,
          });
        })
        .catch((error) => {
          //console.log(error.error);
          this.setState({ lockModifyVideoCall: true });
        });
    }
  };

  liveVideoSaveDisabled = () => {
    var disabled = false;
    if (this.state.fakeobj.length > 0) {
      if (
        this.state.fakeobj[0].topic == "" ||
        this.state.fakeobj[0].videourl == "" ||
        this.state.fakeobj[0].description == "" ||
        this.state.fakeobj[0].membership == null ||
        this.state.fakeobj[0].organiser == "" ||
        // this.state.fakeobj[0].evtdt == "" ||
        // this.state.fakeobj[0].evttime == "" ||
        // this.state.fakeobj[0].bannerStartDate == "" ||
        // this.state.fakeobj[0].bannerEndDate == ""
        this.state.fakeobj[0].thumbimg == ""
      ) {
        disabled = true;
      } else {
        disabled = false;
      }
      return disabled;
    }
  };

  liveVideoEdit = async (getId) => {
    //console.log(getId, "Get id");
    let getvidmodalState = { ...this.state.vidmodal };
    getvidmodalState.addvid = false;
    getvidmodalState.livvid = true;
    let selectedLiveVideo = this.state.videoListAPIData.filter(
      (item) => item.id === getId
    )[0];

    let createObj = new Object();
    createObj.videourl = selectedLiveVideo.url;
    createObj.source = selectedLiveVideo.source;
    createObj.password = selectedLiveVideo.zoomPassCode;
    createObj.description = selectedLiveVideo.description;
    createObj.organiser = selectedLiveVideo.organiser;
    createObj.membership = selectedLiveVideo.subcategory;
    createObj.evtdt = selectedLiveVideo.eventDate;
    createObj.evttime = selectedLiveVideo.eventTime;
    createObj.bannerStartDate = selectedLiveVideo.bannerStartDate;
    createObj.bannerEndDate = selectedLiveVideo.bannerEndDate;
    createObj.topic = selectedLiveVideo.topic;
    let pushArry = [];
    pushArry[0] = createObj;
    this.setState({
      vidmodal: getvidmodalState,
      showModal: true,
      liveEditSaveBtnShow: true,
      liveEditGetId: getId,
      imginfo: selectedLiveVideo.imageUrl,
      fakeobj: pushArry,
    });
  };

  liveEditSaveBtn = async () => {
    if (this.state.lockModifyVideoCall) {
      this.setState({ lockModifyVideoCall: false });
      console.log(this.state.fakeobj[0]);
      if (typeof this.state.fakeobj[0].evtdt === "string") {
        this.state.fakeobj[0].evtdt = new Date(this.state.fakeobj[0].evtdt);
      }
      if (typeof this.state.fakeobj[0].evttime === "string") {
        var oldDate = this.state.fakeobj[0].evttime.split(":");
        var newDate = new Date();
        newDate.setHours(oldDate[0]);
        newDate.setMinutes(oldDate[1]);
        newDate.setSeconds(oldDate[2]);
        this.state.fakeobj[0].evttime = newDate;
      }
      const liveVideoFormData = new FormData();
      liveVideoFormData.append("url", this.state.fakeobj[0].videourl);
      liveVideoFormData.append(
        "imageUrl",
        await this.fetchImageUrl(this.state.fakeobj[0].videourl)
      );
      liveVideoFormData.append("islive", true);
      liveVideoFormData.append("source", "vimeo");

      liveVideoFormData.append("source", this.state.fakeobj[0].source ?  this.state.fakeobj[0].source :  "vimeo");
      liveVideoFormData.append("zoomPassCode", this.state.fakeobj[0].password  ?  this.state.fakeobj[0].password  :  "");

      liveVideoFormData.append(
        "description",
        this.state.fakeobj[0].description
      );
      liveVideoFormData.append("category", null);
      liveVideoFormData.append("subcategory", this.state.fakeobj[0].membership);
      liveVideoFormData.append("publish", true);
      liveVideoFormData.append("organiser", this.state.fakeobj[0].organiser);
      liveVideoFormData.append("isFeature", null);
      liveVideoFormData.append(
        "eventDate",
        moment(this.state.fakeobj[0].evtdt).format(dateTimeFormat1)
      );
      liveVideoFormData.append(
        "eventTime",
        moment(this.state.fakeobj[0].evttime).format("HH:mm:ss")
      );
      liveVideoFormData.append(
        "bannerStartDate",
        moment(this.state.fakeobj[0].bannerStartDate).tz("UTC").format()
      );
      // liveVideoFormData.append("bannerEndtime", moment(this.state.fakeobj[0].bannerEndDate).format(dateTimeFormat2));
      liveVideoFormData.append(
        "bannerEndDate",
        moment(this.state.fakeobj[0].bannerEndDate).tz("UTC").format()
      );
      const fileData = await getBase64(this.state.imginfo);
      liveVideoFormData.append("file", fileData);
      liveVideoFormData.append("id", this.state.liveEditGetId);
      liveVideoFormData.append("topic", this.state.fakeobj[0].topic);

      API.callEndpoint("PUT", "Bearer", "/api/videos", liveVideoFormData)
        .then((response) => {
          //console.log(response.data);
          this.videoListAPI();
          this.setState({
            fakeobj: [
              {
                evtdt: new Date(),
                evttime: new Date(),
                bannerStartDate: new Date(),
                bannerEndDate: new Date(),
              },
            ],
            imginfo: "",
            liveEditGetId: "",
            showModal: false,
            lockModifyVideoCall: true,
          });
        })
        .catch((error) => {
          //console.log(error.error);
          this.setState({ lockModifyVideoCall: true });
        });
    }
  };

  liveVideoDelete = (getID) => {
    //console.log(getID, "Get id");
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        API.callEndpoint("DELETE", "Bearer", `/api/videos/${getID}`)
          .then((response) => {
            //console.log(response.data);
            this.videoListAPI();
          })
          .catch((error) => {
            //console.log(error.error);
            Swal.fire(error.error);
          });
      }
    });
  };

  featuredBtn = (getId) => {
    const featuredFormData = new FormData();
    featuredFormData.append("id", getId);
    featuredFormData.append("isFeature", 1);

    API.callEndpoint(
      "PUT",
      "Bearer",
      `/api/videos?isFeature=${getId}`,
      featuredFormData
    )
      .then((response) => {
        //console.log(response.data);
        this.videoListAPI();
      })
      .catch((error) => {
        //console.log(error.error);
      });
  };

  featuredBtnActive = (getId) => {
    const featuredFormData = new FormData();
    featuredFormData.append("id", getId);
    featuredFormData.append("isFeature", 0);

    API.callEndpoint(
      "PUT",
      "Bearer",
      `/api/videos?isFeature=${getId}`,
      featuredFormData
    )
      .then((response) => {
        //console.log(response.data);
        this.videoListAPI();
      })
      .catch((error) => {
        //console.log(error.error);
      });
  };

  changemenuTab = (menuname, index) => {
    let newMen = [...this.state.navList];
    console.log(newMen, 'navList');
    newMen.map((items) => {
      return (items.navStatus = false);
    });
    newMen[index].navStatus = true;
    this.setState({
      navList: newMen,
      curTab: menuname,
    });
  };
  changememTab = (index) => {
    let newMen = [...this.state.navMembernav];
    newMen.map((items) => {
      return (items.navStatus = false);
    });
    newMen[index].navStatus = true;
    let getmemnavname = newMen[index].tabname;
    this.setState({
      navMembernav: newMen,
      curMemtab: getmemnavname,
    });
  };
  getRadioinfo = (eve) => {
    let getVal = eve.target.id;
    let getObj = { ...this.state.vidmodal };
    if (getVal === "add") {
      getObj.addvid = true;
      getObj.livvid = false;
      let newadvid = new Object();
      newadvid.videourl = "";
      newadvid.title = "";
      newadvid.description = "";
      newadvid.category = null;
      newadvid.hedgedCategory = "";
      // newadvid.membership = null;
      // newadvid.publishfrom = "";
      // newadvid.publishto = "";
      newadvid.publish = false;
      newadvid.featured = false;

      newadvid.thumbimg = "";
      newadvid.organiser = "";
      newadvid.membership = null;
      newadvid.evtdt = "";
      newadvid.evttime = "";
      newadvid.bannerStartDate = "";
      newadvid.bannerEndDate = "";

      let copyfakeobj = [...this.state.fakeobj, newadvid];
      this.setState({ vidmodal: getObj, fakeobj: copyfakeobj });
    } else {
      getObj.addvid = false;
      getObj.livvid = true;
      let newlivid = new Object();
      // newlivid.title = "";
      newlivid.thumbimg = "";
      newlivid.videourl = "";
      newlivid.description = "";
      newlivid.organiser = "";
      newlivid.membership = null;
      newlivid.evtdt = "";
      newlivid.evttime = "";
      // newlivid.chatsts = "";
      // newlivid.countdown = "";
      newlivid.bannerStartDate = "";
      newlivid.bannerEndDate = "";
      let copyfakeobj = [...this.state.fakeobj, newlivid];
      this.setState({ vidmodal: getObj, fakeobj: copyfakeobj });
    }
  };
  formatByte = (bytes) => {
    var marker = 1024;
    var decimal = 0;
    var megaBytes = marker * marker;
    var gigaBytes = marker * marker * marker;
    if (bytes < gigaBytes) return (bytes / megaBytes).toFixed(decimal);
  };
  handlevidupload = async (event) => {
    let getvidmodalState = { ...this.state.vidmodal };
    let getObj = [...this.state.fakeobj];
    let getsize = event.target.files[0].size;
    let vidsize = this.formatByte(getsize);
    let getvidsie = this.state.vidmodal.vidsize;
    if (vidsize > getvidsie) {
      getvidmodalState.alertmsg =
        "Video size should be below " + getvidsie + " MB";
      getvidmodalState.alertbox = true;
      this.setState({
        vidmodal: getvidmodalState,
      });
    } else {
      getvidmodalState.alertmsg = "";
      getvidmodalState.alertbox = false;
      getObj[0].videourl = await getBase64(event.target.files[0]);
      this.setState({
        fakeobj: getObj,
        fileinfo: event.target.files[0],
        vidmodal: getvidmodalState,
      });
    }
    //console.log(getObj[0].videourl);
  };
  handleimgupload = async (event) => {
    let getObj = [...this.state.fakeobj];
    getObj[0].thumbimg = await getBase64(event.target.files[0]);
    this.setState({
      fakeobj: getObj,
      imginfo: event.target.files[0],
    });
    //console.log(getObj[0].thumbimg);
    //console.log(getObj[0].thumbimg.name);
  };
  inputchange = (event) => {
    let getObj = [...this.state.fakeobj];
    if (event.target.name === "videourl") {
      getObj[0][event.target.name] = event.target.value
        .split("")
        .filter((item) => item.match(/[0-9]/i))
        .join("");
    } else {
      getObj[0][event.target.name] = event.target.value;
    }
    this.setState({
      fakeobj: getObj,
    });
    //console.log(getObj[0][event.target.name]);
  };

  publishOnOff = (event) => {
    let getObj = [...this.state.fakeobj];
    getObj[0]["publish"] = !getObj[0]["publish"];
    if (!getObj[0]["publish"]) {
      getObj[0]["featured"] = false;
    }
    this.setState({
      publishOnOffState: !this.state.publishOnOffState,
      featuredOnOffState: !getObj[0]["publish"]
        ? false
        : this.state.featuredOnOffState,
      fakeobj: getObj,
    });
  };

  featuredOnOff = (event) => {
    let getObj = [...this.state.fakeobj];
    if (getObj[0]["publish"]) {
      getObj[0]["featured"] = !getObj[0]["featured"];
      this.setState({
        featuredOnOffState: !this.state.featuredOnOffState,
        fakeobj: getObj,
      });
    }
  };

  checkchange = (event) => {
    let getObj = [...this.state.fakeobj];
    if (event.target.checked) {
      getObj[0].chatsts = true;
    } else {
      getObj[0].chatsts = false;
    }
    this.setState({
      fakeobj: getObj,
    });
    //console.log(getObj[0].chatsts);
  };
  categoryOnChange = (catType) => {
    let getvidmodalState = { ...this.state.vidmodal };
    let getObj = [...this.state.fakeobj];
    let getcattype = catType.value;
    getObj[0].category = getcattype;
    // if (getcattype === "Membership") {
    //   getvidmodalState.iscatmem = true;
    // } else {
    //   getvidmodalState.iscatmem = false;
    // }
    if (getcattype === "Live from hedged") {
      getvidmodalState.iscatmem = true;
      this.setState({
        subCategoryDisabled: true,
      });
    } else {
      getvidmodalState.iscatmem = false;
      this.setState({
        subCategoryDisabled: false,
      });
    }
    this.setState({ fakeobj: getObj, vidmodal: getvidmodalState });
    //console.log(getObj[0].category);
  };

 
  liveHedgedcategoryOnChange = (liveHedgedType) => {
    let getObj = [...this.state.fakeobj];
    let getcattype = liveHedgedType.value;
    getObj[0].hedgedCategory = getcattype;
    this.setState({ fakeobj: getObj });
    //console.log(getObj[0].hedgedCategory);
  };

  memberOnChange = (memType) => {
    let getObj = [...this.state.fakeobj];
    getObj[0].membership = memType.value;
    this.setState({ fakeobj: getObj });
    //console.log(getObj[0].membership);
  };
  setDatefun = (date, vrname) => {
    //console.log(date);
    let getObj = [...this.state.fakeobj];
    getObj[0][vrname] = date;
    //console.log(getObj);
    this.setState({ fakeobj: getObj });
  };
  edititem = (item) => {
    let getModimodal = { ...this.state.modifymodal };
    let getItem = [...this.state.fakeobj, item];
    getModimodal.showModal = true;
    this.setState({ fakeobj: getItem, modifymodal: getModimodal });
  };

  dragAndDropShow = (value) => {
    this.setState({ dragAndDrop: value });
    this.videoListAPI();
  };

  setGridInitiated = (value) => {
    this.setState({ gridInitiated: value });
  };

  videoTypeOnChange = (type) => {
    let getObj = [...this.state.fakeobj];
    let getvideoType = type.value;
    getObj[0].source = getvideoType;
    // if (getcattype === "Membership") {
    //   getvidmodalState.iscatmem = true;
    // } else {
    //   getvidmodalState.iscatmem = false;
    // }
    this.setState({ fakeobj: getObj });
    //console.log(getObj[0].category);
  };


  render() {
    let getTbname = this.state.navList.filter((tbnm) => {
      return tbnm.navStatus === true;
    });
    let tabname = getTbname[0].tabname.replace(" ", "");
    console.log(this.state.featuredOnOffState, this.state.publishOnOffState);
    return (
      <>
        <Draganddrop
          show={this.state.dragAndDrop}
          setShow={this.dragAndDropShow}
          tab={this.state.curTab}
          state={this.state}
          setGridInitiated={this.setGridInitiated}
          gridInitiated={this.state.gridInitiated}
        />
        <section className="vidblk ps-lg-2">
          <h1 className="head text-uppercase m-0">Videos</h1>
          <aside className="addbtn d-lg-block text-end mb-3">
            <button
              className="d-inline-block py-2 px-4 rounded-2"
              onClick={this.openModal}
            >
              Add Video
            </button>
            {this.state.curTab !== "Live from hedged" &&
              this.state.curTab !== "Live Video" ? (
              <button
                className="d-inline-block py-2 px-4 rounded-2 ms-2"
                onClick={(e) => {
                  this.setState({ dragAndDrop: true, gridInitiated: false });
                }}
              >
                Order featured Videos
              </button>
            ) : null}
          </aside>
          <section className="vid-mainblk d-lg-block pt-4">
            <ul className="vid-navblk mb-3">
              {this.state.navList.map((menu, index) => {
                return (
                  <li
                    key={menu.id}
                    // className={`d-inline-block py-2 px-4 me-2 rounded-2 ${
                    //   menu.navStatus ? "active" : ""
                    // }`}
                    className={`d-inline-block ${menu.navStatus ? "active" : ""
                      }`}
                    onClick={() => this.changemenuTab(menu.tabname, index)}
                  >
                    <span className="d-block py-2 px-4">{menu.tabname}</span>
                  </li>
                );
              })}
            </ul>
            <section className="vid-contblk pt-3 px-3">
              <Memcontblk
                state={this.state}
                changememTab={this.changememTab}
                featuredBtn={this.featuredBtn}
                featuredBtnActive={this.featuredBtnActive}
                edititem={this.edititem}
                liveVideoEdit={this.liveVideoEdit}
                liveVideoDelete={this.liveVideoDelete}
                tabname={tabname}
                memtabname={this.state.curMemtab}
                videoEditBtn={this.videoEditBtn}
                videoDeleteBtn={this.videoDeleteBtn}
              />
            </section>
          </section>
        </section>

        <Videomodal
          show={this.state.showModal}
          liveEditSaveBtn={this.liveEditSaveBtn}
          onHide={this.hidemodal}
          featuredOnOff={this.featuredOnOff}
          liveVideoSaveDisabled={this.liveVideoSaveDisabled}
          addvidsave={this.addvidsave}
          newVideoSaveDisabled={this.newVideoSaveDisabled}
          lividsave={this.lividsave}
          handlevidupload={this.handlevidupload}
          handleimgupload={this.handleimgupload}
          getRadioinfo={this.getRadioinfo}
          inputchange={this.inputchange}
          publishOnOff={this.publishOnOff}
          checkchange={this.checkchange}
          memberOnChange={this.memberOnChange}
          categoryOnChange={this.categoryOnChange}
          liveHedgedcategoryOnChange={this.liveHedgedcategoryOnChange}
          setDatefun={this.setDatefun}
          addmembertype={addmembertype}
          categorytype={categorytype}
          liveFromHedgedSelected={liveFromHedgedSelected}
          state={this.state}
          videoType={videoType}
          videoTypeOnChange={this.videoTypeOnChange}
        />

        <Modifymodal
          state={this.state}
          addvidsave={this.addvidsave}
          featuredOnOff={this.featuredOnOff}
          newVideoSaveDisabled={this.newVideoSaveDisabled}
          addModifyVidsave={this.addModifyVidsave}
          show={this.state.modifymodal.showModal}
          onHide={this.hidemodalmodify}
          handlevidupload={this.handlevidupload}
          handleimgupload={this.handleimgupload}
          inputchange={this.inputchange}
          publishOnOff={this.publishOnOff}
          checkchange={this.checkchange}
          memberOnChange={this.memberOnChange}
          categoryOnChange={this.categoryOnChange}
          videoTypeOnChange={this.videoTypeOnChange}
          liveHedgedcategoryOnChange={this.liveHedgedcategoryOnChange}
          setDatefun={this.setDatefun}
          addmembertype={addmembertype}
          categorytype={categorytype}
          videoType={videoType}
          liveFromHedgedSelected={liveFromHedgedSelected}
          info={this.state.fakeobj}
        />
      </>
    );
  }
}

export default Vidmaincomp;

import React from "react";

let dtt = new Date();
let gettHour = dtt.getHours();
let gettMin = dtt.getMinutes();
let gettCurdt = dtt.getDate();
export let Hr = gettHour > 9 ? "" + gettHour : "0" + gettHour;
export let Min = gettMin > 9 ? "" + gettMin : "0" + gettMin;
export let Dayy = dtt.toString().split(" ")[0];
export let Mont = dtt.toLocaleString("default", { month: "short" });
export let Dat = gettCurdt > 9 ? "" + gettCurdt : "0" + gettCurdt;

const Ordinal = (n) => {
  var s = ["th", "st", "nd", "rd"];
  var v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

const getTmeFrmt = (getTimeStamp, method) => {
  // Get Hour Min AM PM
  let getHour = getTimeStamp[0];
  let getMin = getTimeStamp[1];
  let Hour, Mins, AMPM, setHour, res;
  if (parseInt(getHour) >= 12) {
    Hour =
      parseInt(getHour) > 9 ? "" + parseInt(getHour) : "0" + parseInt(getHour);
    Mins = getMin;
    AMPM = "PM";
    if (parseInt(getHour) > 12) {
      setHour = parseInt(getHour) - 12;
      Hour = setHour > 9 ? "" + setHour : "0" + setHour;
    }
  } else {
    Hour =
      parseInt(getHour) > 9 ? "" + parseInt(getHour) : "0" + parseInt(getHour);
    Mins = getMin;
    AMPM = "AM";
  }

  if (method == "default") {
    return (res = Hour + ":" + Mins + " " + AMPM);
  } else if (method == "NoMeridiem") {
    return (res = Hour + ":" + Mins);
  }
};
export const IsoToLocale = () => {
  let timeZoneOffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  let localTimeZone = new Date(Date.now() - timeZoneOffset)
    .toISOString()
    .slice(0, -1);
  return localTimeZone;
};
export const DateTimeFormatISO = (datestamp, method) => {
  // Splitting Date and Time
  let splitDt = datestamp.split("T");

  // Original Date format as per Datestamp
  let orgDtfrmt = splitDt[0];

  // Original Time format as per Datestamp
  let orgTmefrmt = splitDt[1];

  // console.log(splitDt[0]);
  // Getting Date and Replacing - to /
  let getDate = splitDt[0].replace(/-/g, "/");

  let dt = new Date(getDate);
  let result;
  let monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Splitting Dateformat as Date Month year
  let specificdt = getDate.split("/");

  // Splitting Timeformat as Hour Mins (Seconds,AMPM)
  let getTimeStamp = splitDt[1].split(":");

  switch (method) {
    case "Year":
      result = specificdt[0];
      //console.log(result);
      return result;
      break;
    case "YearLastTwoDigit":
      result = specificdt[0].toString().substr(-2);
      //console.log(result);
      return result;
      break;
    case "Month":
      result = specificdt[1];
      //console.log(result);
      return result;
      break;
    case "MonthFullName":
      result = monthNames[dt.getMonth()];
      //console.log(result);
      return result;
      break;
    case "MonthHalfName":
      result = dt.toLocaleString("en-us", { month: "short" });
      //console.log(result);
      return result;
      break;
    case "Date":
      result = specificdt[2];
      //console.log(result);
      return result;
      break;
    case "DateOrdinal":
      let getDtord = specificdt[2];
      let setdtord;
      let getindexof = getDtord.indexOf("0");
      if (getindexof == 0) {
        setdtord = getDtord.replace("0", "");
        // console.log(setdtord);
      } else {
        setdtord = getDtord;
      }
      result = Ordinal(setdtord);
      //console.log(result);
      return result;
      break;
    case "DayFullName":
      result = dayNames[dt.getDay()];
      //console.log(result);
      return result;
      break;
    case "DayHalfName":
      result = dt.toString().split(" ")[0];
      //console.log(result);
      return result;
      break;
    case "TimeDefault":
      result = getTmeFrmt(getTimeStamp, "default");
      //console.log(result);
      return result;
      break;
    case "TimeNoMeridiem":
      result = getTmeFrmt(getTimeStamp, "NoMeridiem");
      //console.log(result);
      return result;
      break;
    case "ShowDate":
      let setDate = getDate.split("/");
      result = setDate[2] + "/" + setDate[1] + "/" + setDate[0];
      //console.log(result);
      return result;
      break;
    default:
      result = orgDtfrmt;
      //console.log(result);
      return result;
  }
};

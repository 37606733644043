import axios from 'axios';
import constant from '../configuration/config';
// var accessTokenExpiry = 14400;
var accessTokenExpiry = 43200;
var methodType, authorisationType, URL, data;
export async function callEndpoint(methodType, authorisationType, URL, data) {
  methodType = methodType;
  authorisationType = authorisationType;
  URL = URL;
  data = data;
  if (authorisationType === 'Bearer') {
    var accessToken = getCookie('accessToken');
    // console.log(" accessToken : " + accessToken);
    if (accessToken === null) {
      return new Promise((resolve, reject) => {
        //window.location.href = "/login";
      });
    } else {
      return new Promise((resolve, reject) => {
        axios({
          url: constant.baseUrl + URL,
          method: methodType,
          headers: {
            Authorization: 'Bearer ' + getCookie('accessToken'),
            dataversion: 'en-US',
            appplatform: 'WEBSITE',
            appversion: '1.0.0',
            // "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "*",
            // "Content-Type": "multipart/form-data",
          },
          data: data,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((ex) => {
            try {
              if (ex.response.data.message !== undefined) {
                if (ex.response.data.message.toLowerCase() === 'unauthorized') {
                  getRefreshToken().then((response) => {
                    callEndpoint(methodType, authorisationType, URL, data)
                      .then((response) => {
                        resolve(response);
                      })
                      .catch(reject);
                  });
                } else {
                  reject({ error: ex.response.data.message });
                }
              } else {
                if (ex.toString().includes('Network Error')) {
                  reject({ error: 'Network Error' });
                } else if (axios.isCancel(ex)) {
                  reject({ Cancel: '' });
                } else {
                  reject({ error: ex.response.data.message });
                }
              }
            } catch (e) {
              // console.log(ex, e);
              if (URL === '/api/files/upload') {
                reject({ error: 'image size too large' });
              } else {
                reject({ error: 'Network Error' });
              }
            }
          });
      });
    }
  }
  else if (authorisationType === 'Basic') {
    var headerObject = new Object();
    headerObject.Authorization =
      'Basic ' +
      new Buffer.from(
        constant.username_cinema_app + ':' + constant.password_cinema_app
      ).toString('base64');
    headerObject.appplatform = 'WEBSITE';
    headerObject.appversion = '1.0.0';
    return new Promise((resolve, reject) => {
      axios({
        url: constant.baseUrl + '' + URL,
        method: methodType,
        headers: headerObject,
        data: data,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((ex) => {
          reject({ error: ex.response.data.message });
        });
    });
  }
}
export async function swaggerEndpoint(methodType, authorisationType, url, data, query, token) {
  methodType = methodType;
  authorisationType = authorisationType;
  // URL = url;
  data = data;
  // query = query;
  let axiosObj = {};
  let endpoint = constant.swaggerUrl + url;
  if (query) {
    console.log('line 105');
    URL = `${endpoint}?${query}`
    axiosObj.url = `${endpoint}?${query}`;
    axiosObj.method = methodType;
  }
  else {
    console.log('line 109');
    URL = endpoint;
    axiosObj.url = endpoint;
    axiosObj.method = methodType;
  }
  if (Object.keys(data).length > 0) {
    axiosObj.data = data;
  }
  if (authorisationType === 'Bearer') {
    axiosObj.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  else {
    axiosObj.headers = {
      Accept: 'application/json',
      'system-token': token,
    }
  }
  return new Promise((resolve, reject) => {
    axios(axiosObj)
      .then((response) => {
        console.log(response, 'response');
        resolve(response);
      })
      .catch((ex) => {
        console.log(ex, 'error ');
      });
  });

}

export async function callDownloadEndpoint(
  methodType,
  authorisationType,
  URL,
  data
) {
  methodType = methodType;
  authorisationType = authorisationType;
  URL = URL;
  data = data;
  if (authorisationType === 'Bearer') {
    var accessToken = getCookie('accessToken');
    // console.log(" accessToken : " + accessToken);
    if (accessToken === null) {
      return new Promise((resolve, reject) => {
        //window.location.href = "/login";
      });
    } else {
      return new Promise((resolve, reject) => {
        axios({
          url: constant.baseUrl + URL,
          method: methodType,
          responseType: 'arraybuffer',
          responseEncoding: 'binary',
          headers: {
            Authorization: 'Bearer ' + getCookie('accessToken'),
            dataversion: 'en-US',
            appplatform: 'WEBSITE',
            appversion: '1.0.0',
            'Content-Type': 'application/json',
          },
          data: data,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((ex) => {
            try {
              if (ex.response.data.message !== undefined) {
                if (ex.response.data.message.toLowerCase() === 'unauthorized') {
                  getRefreshToken().then((response) => {
                    callEndpoint(methodType, authorisationType, URL, data)
                      .then((response) => {
                        resolve(response);
                      })
                      .catch(reject);
                  });
                } else {
                  reject({ error: ex.response.data.message });
                }
              } else {
                if (ex.toString().includes('Network Error')) {
                  reject({ error: 'Network Error' });
                } else if (axios.isCancel(ex)) {
                  reject({ Cancel: '' });
                } else {
                  reject({ error: ex.response.data.message });
                }
              }
            } catch (e) {
              console.log(ex, e);
              if (URL === '/api/files/upload') {
                reject({ error: 'image size too large' });
              } else {
                reject({ error: 'Network Error' });
              }
            }
          });
      });
    }
  } else if (authorisationType === 'Basic') {
    var headerObject = new Object();
    headerObject.Authorization =
      'Basic ' +
      new Buffer.from(
        constant.username_cinema_app + ':' + constant.password_cinema_app
      ).toString('base64');
    headerObject.appplatform = 'WEBSITE';
    headerObject.appversion = '1.0.0';
    return new Promise((resolve, reject) => {
      axios({
        url: constant.baseUrl + '' + URL,
        method: methodType,
        headers: headerObject,
        data: data,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((ex) => {
          reject({ error: ex.response.data.message });
        });
    });
  }
}
export async function callFormDataEndpoint(
  methodType,
  authorisationType,
  URL,
  data
) {
  methodType = methodType;
  authorisationType = authorisationType;
  URL = URL;
  data = data;
  if (authorisationType === 'Bearer') {
    var accessToken = getCookie('accessToken');
    // console.log(" accessToken : " + accessToken);
    if (accessToken === null) {
      return new Promise((resolve, reject) => {
        //window.location.href = "/login";
      });
    } else {
      return new Promise((resolve, reject) => {
        axios({
          url: constant.baseUrl + URL,
          method: methodType,
          headers: {
            Authorization: 'Bearer ' + getCookie('accessToken'),
            dataversion: 'en-US',
            appplatform: 'WEBSITE',
            appversion: '1.0.0',
            // "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "*",
            'Content-Type': 'multipart/form-data',
          },
          data: data,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((ex) => {
            try {
              if (ex.response.data.message !== undefined) {
                if (ex.response.data.message.toLowerCase() === 'unauthorized') {
                  getRefreshToken().then((response) => {
                    callEndpoint(methodType, authorisationType, URL, data)
                      .then((response) => {
                        resolve(response);
                      })
                      .catch(reject);
                  });
                } else {
                  reject({ error: ex.response.data.message });
                }
              } else {
                if (ex.toString().includes('Network Error')) {
                  reject({ error: 'Network Error' });
                } else if (axios.isCancel(ex)) {
                  reject({ Cancel: '' });
                } else {
                  reject({ error: ex.response.data.message });
                }
              }
            } catch (e) {
              console.log(ex, e);
              if (URL === '/api/files/upload') {
                reject({ error: 'image size too large' });
              } else {
                reject({ error: 'Network Error' });
              }
            }
          });
      });
    }
  } else if (authorisationType === 'Basic') {
    var headerObject = new Object();
    headerObject.Authorization =
      'Basic ' +
      new Buffer.from(
        constant.username_cinema_app + ':' + constant.password_cinema_app
      ).toString('base64');
    headerObject.appplatform = 'WEBSITE';
    headerObject.appversion = '1.0.0';
    return new Promise((resolve, reject) => {
      axios({
        url: constant.baseUrl + '' + URL,
        method: methodType,
        headers: headerObject,
        data: data,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((ex) => {
          reject({ error: ex.response.data.message });
        });
    });
  }
}

export async function getAccessToken(
  username,
  password,
  keepmesignedin = true
) {
  var headerObject = new Object();
  headerObject.Authorization =
    'Basic ' + new Buffer.from(username + ':' + password).toString('base64');
  var data = new Object();
  data.accessTokenExpiry = accessTokenExpiry;
  data.isWeb = 1;
  // data.keepmesignedin = keepmesignedin;
  //data.getUserInfo = true;
  return new Promise((resolve, reject) => {
    axios({
      url: constant.baseUrl + '/users/login',
      method: 'POST',
      headers: headerObject,
      data: data,
    })
      .then((response) => {
        const admins = [
          'CMSUSER',
          'CMSREASEARCHADMINUSER',
          'CMSSALESADMINUSER',
          'CMSAUDITUSER'
        ];
        if (!admins.includes(response.data.role.toUpperCase())) {
          throw new Error('Not a admin');
        }
        document.cookie = 'accessToken=' + response.data.accessToken;
        document.cookie = 'refreshToken=' + response.data.refreshToken;
        document.cookie = 'role=' + response.data.role;
        resolve(response);
      })
      .catch((ex) => {
        reject({ error: ex });
      });
  });
}

export async function createAdmin(getdata, keepmesignedin = true) {
  var headerObject = new Object();
  headerObject.Authorization =
    'Basic ' +
    new Buffer.from(
      constant.username_cinema_app + ':' + constant.password_cinema_app
    ).toString('base64');
  var data = new Object();
  data.accessTokenExpiry = accessTokenExpiry;
  // data.keepmesignedin = keepmesignedin;
  //data.getUserInfo = true;
  console.log('createAdmin data', getdata);
  return new Promise((resolve, reject) => {
    axios({
      url: constant.baseUrl + '/users/create-admin',
      method: 'POST',
      headers: headerObject,
      data: getdata,
    })
      .then((response) => {
        document.cookie = 'accessToken=' + response.data.accessToken;
        document.cookie = 'refreshToken=' + response.data.refreshToken;
        resolve(response);
      })
      .catch((ex) => {
        reject({ error: ex });
      });
  });
}

function getRefreshToken() {
  var headerDetails =
    'Basic ' +
    new Buffer.from(
      constant.username_cinema_app + ':' + constant.password_cinema_app
    ).toString('base64');
  var data = new Object();
  data.refreshToken = getCookie('refreshToken');
  data.accessTokenExpiry = accessTokenExpiry;
  return new Promise((resolve, reject) => {
    axios({
      url: constant.baseUrl + '/users/refreshtoken',
      method: 'POST',
      headers: { Authorization: headerDetails },
      data: data,
    })
      .then((response) => {
        document.cookie = 'accessToken=' + response.data.accessToken;
        document.cookie = 'refreshToken=' + response.data.refreshToken;
        resolve(response);
      })
      .catch((ex) => {
        document.cookie = 'accessToken=' + '';
        document.cookie = 'refreshToken=' + '';
        document.cookie = 'role=' + '';
        // if (axios.isCancel(ex)) {
        //   reject({ Cancel: "" });
        // } else if (
        //   ex.response.data.code !== 200 ||
        //   ex.response.data.message.toLowerCase() === "token invalid"
        // ) {
        //   getAccessToken(
        //     constant.username_cinema_app,
        //     constant.password_cinema_app
        //   ).then((response) => {
        //     callEndpoint(methodType, authorisationType, URL, data)
        //       .then((response) => {
        //         resolve(response);
        //       })
        //       .catch(reject);
        //   });
        // }
      });
  });
}

export function getValueFromCookie(cookieName) {
  return getCookie(cookieName);
}

export function getCookie(name) {
  var cookieArr = document.cookie.split(';');
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split('=');
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}

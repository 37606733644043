import React from 'react';

class SystemBulishPriceVolume extends React.Component {
  render() {
    return (
      <section className="price-volume-page">
          <section className="pv-inner">
            <div className="row">
              <h2>Entry &amp; Exit</h2>
              <div className="col-lg-6">
                <ul className="list-group">
                  <li className="list-group-item"><span>Entry Price &amp; Volume</span><span className="price">Rs. {this.props.state.entryPrice} ({this.props.state.entryPriceQuantity})</span></li>
                  <li className="list-group-item"><span>Exit Price &amp; Volume</span><span className="price">Rs. {this.props.state.exitPrice} ({this.props.state.exitPriceQuantity})</span></li>
                  <li className="list-group-item"><span>Stop Loss</span><span className="price">Rs. {this.props.state.stopLoss}</span></li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul className="list-group">
                  {
                    this.props.state.target.map((t, index) => {
                      return(
                        <li className="list-group-item" key={index}><span>Target {index+1}</span><span className="price">Rs. {t}</span></li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
            <aside className="price-exit-btn">
              <p onClick={this.props.priceExitBtn}>&nbsp;</p>
            </aside>
          </section>
      </section>
    )
  }
}

export default SystemBulishPriceVolume;
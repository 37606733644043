import React,{useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Eventmodal = (props) => {
    const [membType,setmembType] = useState('');
    const [countdown, setcountdown] = useState(null);
    const [evtDate, setevtDate] = useState(new Date());
    const [evtTime, setevtTime] = useState(null);
    
    const memberOnChange = membType => {
        setmembType(membType);
        props.memberOnChange(membType)
    };
    const stCountdown = (date) =>{
        setcountdown(date);
        props.setDatefun(date,'cnttim');
    }
    const stevtDt = (date) =>{
        setevtDate(date)
        props.setDatefun(date,'evtdt');
    }
    const stevtTime = (date) =>{
        setevtTime(date)
        props.setDatefun(date,'evttime');
    }
    const VideoThumb = ({ vdeo }) => {
        return (
            <video width="100%" height="100%" autoPlay={true} muted={true} loop={true} className={`vidresponive`}>
                <source src={URL.createObjectURL(vdeo)} type="video/mp4"  alt={vdeo.name} className={`img-responsive`}/>
            </video>
        )
    }
    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className={`modalblkk`} >
            <Modal.Body>
                <section className="row m-0 p-0 mt-4 justify-content-center addblk">
                    <section className="col-lg-12">
                        <section className="dndblk text-center position-relative rounded-0 mb-3">
                            <label className="my-4 text-center position-absolute">Drag and drop a file here or click browse</label>
                            <input type="file" onChange={props.handlevidupload} name="myVid" placeholder="Browse" accept="image/x-png,image/gif,image/jpeg" id="vidfile"/>
                            <button className={`btnbrwose`}>Browse</button>
                            {props.state.vidinfo && <VideoThumb vdeo={props.state.vidinfo}/>}
                            {props.state.fileName && <p>{props.state.fileName}</p>}
                        </section>
                        <section className="row m-0 p-0 justify-content-center">
                            <section className="col-lg-8 formblk">
                                <aside className="blk mt-3 w-100">
                                    <label>Name</label><input type="text" name="title" placeholder="" className="inpfield" onChange={(e)=>props.inputchange(e)}/>
                                </aside>
                                <aside className="blk mt-3 w-100">
                                <label>Description</label><input type="text" name="desc" placeholder="" className="inpfield" onChange={(e)=>props.inputchange(e)}/>
                                </aside>
                                <aside className="blk mt-3 w-100">
                                    <label>Organiser</label><input type="text" name="org" placeholder="" className="inpfield" onChange={(e)=>props.inputchange(e)}/>
                                </aside>
                                <aside className="blk mt-3 w-100">
                                    <label>Membership</label>
                                    <Select
                                        value={membType}
                                        onChange={memberOnChange}
                                        options={props.addmembertype}
                                        placeholder='Membership'
                                        classNamePrefix="addsele"
                                    />
                                </aside>
                                <section className="row m-0 p-0 mt-4">
                                    {/* <aside className="col-lg-6 pe-3 ps-0">
                                        <h4>Event Date and Time</h4>
                                        <DatePicker 
                                        placeholderText="Select Date" 
                                        selected={evtDate} 
                                        onChange={date =>stevtDt(date)} 
                                        className="rounded-0 mt-3 dtblk" 
                                        id="evtdate"
                                        />
                                    </aside> */}
                                    {/* <aside className="col-lg-6 pe-3 ps-0">
                                        <h4>Event Date</h4>
                                        <DatePicker 
                                        placeholderText="Select Date" 
                                        selected={evtDate} 
                                        onChange={date =>stevtDt(date)} 
                                        className="rounded-0 mt-3 dtblk" id="evtdate"/>
                                    </aside> */}
                                    <aside className="col-lg-6 pe-3 ps-0">
                                        <h4>Event Date & Time</h4>
                                        <DatePicker 
                                        placeholderText="Select Date & Time" 
                                        selected={evtTime} 
                                        onChange={date =>stevtTime(date)} 
                                        className="rounded-0 mt-3 dtblk"  
                                        showTimeSelect  
                                        timeIntervals={15} 
                                        timeCaption="Time" 
                                        dateFormat="dd/MM/yyyy h:mm aa"/>
                                    </aside>
                                    <aside className="col-lg-6 pe-3 ps-0">
                                        <h4>Count Down Timer From</h4>
                                        <DatePicker placeholderText="Select Date/Time" selected={countdown} onChange={date =>stCountdown(date)} timeInputLabel="Time:" className="rounded-0 mt-3 dtblk" showTimeInput dateFormat="dd/MM/yyyy h:mm aa"/>
                                    </aside>
                                </section>
                                {/* <section className="row m-0 p-0 mt-4" style={{pointerEvents:'none !important'}}>
                                </section> */}
                                <aside className="blk mt-3 w-100">
                                    <label>Event Link</label><input type="text" name="evtlnk" placeholder="" className="inpfield" onChange={(e)=>props.inputchange(e)}/>
                                </aside>
                            </section>
                        </section> 
                        <section className="row justify-content-center p-0 m-0 my-5">
                            <section className="col-3">
                                <button className="btnsave" onClick={props.evetsave}>Save</button>
                            </section>
                        </section>
                    </section>
                </section>
            </Modal.Body>
        </Modal>
    )
}

export default Eventmodal

import * as API from "../../configuration/apiconfig";
import swal from "sweetalert";
import moment from "moment";
import constant from "../../configuration/config";

const format1 = "YYYY-MM-DD HH:mm:ss";

const fieldName = {
  title: "Live Fund Title",
  // label: "Live Fund Label",
  planid: "Subscription",
  expected_returns: "Expected Return (%)",
  equity_capital_required: "Equity Capital Required",
  hedged_capital_required: "Hedged Capital Required",
  equity_notes: "Equity Notes",
  hedged_notes: "Hedged Notes",
  current_returns: "Current Returns",
};

// function to create a new Bundle (Equity / Options)
export async function createBundle(data, setDoneBtnDisable) {
  console.log("----------------- API Create Bundle -------------------");
  let error = false;
  // console.log("data",data);
  await data.data.optionsAddScripArray.map(async (item) => {
    await item.legs.map((leg, index) => {
      if (
        leg.exitPriceToggle &&
        (leg.exitPrice === undefined || leg.exitPrice === "")
      ) {
        swal({
          title: `Enter Exit Price for Leg ${index + 1}`,
          text: "Leg cannot be closed without Exit Price",
          icon: "info",
        });
        error = true;
      }
    });
  });

  //console.log(data.data);

  if (error) {
    setDoneBtnDisable(false);
    return false;
  } else {
    try {
      let data1 = await formartData(data);
      // console.log("data1",data1);
      // check if all fields are present
      if (checkIfAllFieldsArePresent(data1)) {
        setDoneBtnDisable(false);
        return false;
      } else {
        return await API.callEndpoint("POST", "Bearer", "/api/bundles", data1)
          .then((response) => {
            try {
              console.log(response);
              setDoneBtnDisable(false);
              return true;
            } catch (e) {
              console.log(e);

              swal({
                title: "An error occured, try again!",
                text: e.error,
                icon: "info",
              });
              setDoneBtnDisable(false);
              return false;
            }
          })
          .catch((error) => {
            console.log(error);
            swal({
              title: "An error occured, try again!",
              text: error.error,
              icon: "info",
            });
            setDoneBtnDisable(false);
            return false;
          });
      }
    } catch (e) {
      swal({
        title: "An error occured, try again!",
        text: e.error,
        icon: "info",
      });
      setDoneBtnDisable(false);
    }
  }
}

// function to update a new Bundle (Equity / Options)
export async function updateBundle(data, id, setDoneBtnDisable) {
  console.log("----------------- API Update Bundle -------------------");
  let error = false;
  // console.log("data",data);
  await data.data.optionsAddScripArray.map(async (item) => {
    await item.legs.map((leg, index) => {
      if (
        leg.exitPriceToggle &&
        (leg.exitPrice === undefined || leg.exitPrice === "")
      ) {
        swal({
          title: `Enter Exit Price for Leg ${index + 1}`,
          text: "Leg cannot be closed without Exit Price",
          icon: "info",
        });
        error = true;
      }

      // if (
      //   leg.instrument_id == ""
      // ) {
      //   swal({
      //     title: `Instrument Not Found for Leg ${index + 1}`,
      //     text: "Leg cannot be Add without instrument Id",
      //     icon: "info",
      //   });
      //   error = true;
      // }
    });
  });

  if (error) {
    setDoneBtnDisable(false);
    return false;
  } else {
    try {
      let data1 = await updateformartData(data);

      // console.log("data1",data1);
      data1["id"] = id;
      // data1["bundles_calls"] = data1.bundles_calls.map((item) => {
      //   item["bundle_id"] = id;
      //   return item;
      // });

      // check if all fields are present
      if (checkIfAllFieldsArePresent(data1)) {
        setDoneBtnDisable(false);
        // console.log("updateformartData(data) if", data1);

        return false;
      } else {
        // console.log("updateformartData(data) else", data1);

        return await API.callEndpoint("PUT", "Bearer", "/api/bundles", data1)
          .then(async (response) => {
            try {
              console.log(response);
              setDoneBtnDisable(false);
              await API.swaggerEndpoint(
                "POST",
                "System",
                "/system/mark_bundle_modifications",
                {},
                `bundleId=${data1.id}`,
                constant.swaggerSystemToken
              );
              return true;
            } catch (e) {
              console.log("1", e);

              swal({
                title: "An error occured, try again!",
                text: e.error,
                icon: "info",
              });
              setDoneBtnDisable(false);
              return false;
            }
          })
          .catch((error) => {
            console.log("2", error);
            swal({
              title: "An error occured, try again!",
              text: error.error,
              icon: "info",
            });
            setDoneBtnDisable(false);
            return false;
          });
      }
    } catch (e) {
      console.log("3", e);

      swal({
        title: "An error occured, try again!",
        text: e.error,
        icon: "info",
      });
      setDoneBtnDisable(false);
    }
  }
}

let getInstrumentID = async (name, series, strike) => {
  try {
    const response = await fetch(
      `${constant.swaggerUrl}instruments/get_instrument?name=${name}&series=${series}&strike_price=${strike}`,
      {
        method: "GET",
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    const instrumentId = data?.instrument_id || "";

    return instrumentId;
  } catch (error) {
    return "";
  }
};

//this function is used to format the data in the desired format for backend to injust the data
let formartData = async (obj) => {
  let data = obj.data;
  //console.log(data rohan);
  let outputData = {};

  //planid
  let planid = 1;
  if (data.subscription === "Prime") {
    planid = 2;
  } else if (data.subscription === "Crown") {
    planid = 3;
  }
  let equityAddScripArray = [];

  //equity
  equityAddScripArray = data.equityAddScripArray.map((item) => {
    // console.log("item",item);
    let out = {
      name: item.scripNameValue.label,
      scrip_symbol: item.scripNameValue.value,
      call_class: "Equity",
      call_type: item.call_type.value,
      capital_required: 0,
      call_status: item.call_status.value,
      close_type:
        item.call_status.value === "closed"
          ? item.closeType.value || null
          : null,

      returns:
        item.call_status.value === "closed"
          ? parseInt(item.closeTotal) || null
          : null,
      info: {
        minprice: parseInt(item.minPriceValue) || null,
        maxprice: parseInt(item.maxPriceValue) || null,
        quantity: parseInt(item.quantityValue) || null,
        quantity_percent: parseInt(item.quantityPercentValue) || null,
        stop_loss_type: item?.stopLossTypeEquity?.value,
      },
    };
    if (item.id !== undefined && item.id !== "") {
      out["id"] = item.id;
    }

    return out;
  });
  let optionsAddScripArray = [];

  // Define a helper function for processing options data
  const processOptionsData = async (item) => {
    return Promise.all(
      item.legs.map(async (leg) => {
        const instrument_id = await getInstrumentID(
          item?.scripNameValue?.label,
          moment(leg.expiry).format("Do MMM'YY"),
          `${leg.strikePrice}${leg.option.value}`
        );

        return {
          type: leg.action.value,
          quantity: parseInt(leg.qtyValue),
          strike_price: `${leg.strikePrice}${leg.option.value}`,
          series: moment(leg.expiry).format("Do MMM'YY"),
          price: leg.Price,
          exit_price: leg.exitPrice === undefined ? "" : leg.exitPrice,
          instrument_id: instrument_id,
        };
      })
    );
  };

  optionsAddScripArray = await Promise.all(
    data.optionsAddScripArray.map(async (item) => {
      let legs_info = await processOptionsData(item);

      let out = {
        name: item.scripNameValue.label,
        scrip_symbol: item.scripNameValue.value,
        call_class: "Option",
        call_type: item.call_type.value,
        capital_required: parseInt(item.capitalReq) || null,
        call_status: item.call_status.value,
        close_type:
          item.call_status.value === "closed"
            ? item.closeType.value || null
            : null,
        returns:
          item.call_status.value === "closed"
            ? parseInt(item.closeTotal) || null
            : null,
        info: {
          strategy: item.strategy.value,
          max_loss: parseInt(item.maxLoss) || null,
          stopp_loss_value: parseInt(item.stopLoss) || null,
          stop_loss_type: item.stopLossTypeOption.value,
          exit_price: parseInt(item.exitPrice),
          legs_info: [...legs_info],
        },
      };
      if (item.id !== undefined && item.id !== "") {
        out["id"] = item.id;
      }
      return out;
    })
  );

  outputData = {
    title: data.bundleTitle,
    // label:null,
    planid: parseInt(planid) || null,
    expected_returns: parseInt(data.expectedReturn) || null,
    equity_capital_required: parseInt(data.equityCapital) || null,
    hedged_capital_required: parseInt(data.optionsCapital) || null,
    equity_notes: data.eqtynote,
    hedged_notes: data.optionalnote,
    current_returns: data.currentReturn || null,
    use_old_formula: data.use_old_formula || 0,
    enter_status: data.enter_status || 0,
    status: 1,
    bundles_calls: [...optionsAddScripArray, ...equityAddScripArray],
    bundle_type: data.type,
    bundle_version: 1,
    is_notify : data?.notify
  };
  //console.log(outputData);
  return outputData;
};

let updateformartData = async (obj) => {
  let data = obj.data;
  //console.log(data rohan);
  let outputData = {};

  //planid
  let planid = 1;
  if (data.subscription === "Prime") {
    planid = 2;
  } else if (data.subscription === "Crown") {
    planid = 3;
  }
  let equityAddScripArray = [];

  //equity
  equityAddScripArray = data.equityAddScripArray.map((item) => {
    // console.log("item",item);
    let out = {
      name: item.scripNameValue.label,
      scrip_symbol: item.scripNameValue.value,
      call_class: "Equity",
      call_type: item.call_type.value,
      capital_required: 0,
      call_status: item.call_status.value,
      close_type:
        item.call_status.value === "closed"
          ? item.closeType.value || null
          : null,

      returns:
        item.call_status.value === "closed"
          ? parseInt(item.closeTotal) || null
          : null,
      info: {
        minprice: parseInt(item.minPriceValue) || null,
        maxprice: parseInt(item.maxPriceValue) || null,
        quantity: parseInt(item.quantityValue) || null,
        quantity_percent: parseInt(item.quantityPercentValue) || null,
        stop_loss_type: item?.stopLossTypeEquity?.value,
        // close_total:item.closeTotal,
        exit_price: parseInt(item.exit_price),
        close_date: new Date(),
      },
      closed_on:
        item?.closed_on !== null
          ? item?.closed_on
          : item.call_status.value === "closed"
            ? moment().format("YYYY-MM-DD HH:mm:ss")
            : null,
    };
    if (item.id !== undefined && item.id !== "") {
      out["id"] = item.id;
    }
    if (item.leg !== undefined && item.leg !== "") {
      // If leg exists, add legs_info to the info object
      let modifyLegs = {
        ...item?.leg[0],
        legStatus: item?.leg[0]?.legStatus ? item?.leg[0]?.legStatus : 0,
        exit_price: parseInt(item.exit_price),
        returns: item?.leg[0]?.returns !== null ? item?.leg[0]?.returns :
          item.call_status.value === "closed"
            ? parseInt(item.closeTotal) || null
            : null,
        call_type: item?.leg[0]?.call_type !== null ? item?.leg[0]?.call_type : item.call_status.value === "closed" ? item.call_type.value : null,
        closed_type:
          item.call_status.value === "closed"
            ? item.closeType.value || null
            : null,
        closed_on:
          item?.leg[0]?.closed_on !== null
            ? item?.leg[0]?.closed_on
            : item.call_status.value === "closed"
              ? moment().format("YYYY-MM-DD HH:mm:ss")
              : null,
        status: item?.leg[0]?.status == 'closed' ? item?.leg[0]?.status : item?.exit_price ? 'open' : item?.leg[0]?.status,
      };
      out.info["leg_info"] = [modifyLegs];
    }

    return out;
  });

  //options

  let optionsAddScripArray = [];

  // Define a helper function for processing options data
  const processOptionsData = async (item) => {
    return Promise.all(
      item.legs.map(async (leg) => {
        const instrument_id = await getInstrumentID(
          item?.scripNameValue?.label,
          moment(leg.expiry).format("Do MMM'YY"),
          `${leg.strikePrice}${leg.option.value}`
        );

        let leg_out = {
          type: leg?.type ? leg?.type : "Option",
          quantity: parseInt(leg.qtyValue),
          strike_price: `${leg.strikePrice}${leg.option.value}`,
          series: moment(leg.expiry).format("Do MMM'YY"),
          price: parseInt(leg.Price),
          exit_price: leg.exitPrice === undefined ? "" : leg.exitPrice,
          bundle_calls_id: item?.id,
          bundleId: data?.bundleId,
          fund_hedged_id: item?.fund_hedged_id,
          entry_price_start: leg?.Price,
          entry_price_end: leg?.Price,
          price: leg?.Price,
          returns: leg?.returns !== null ? parseInt(leg?.returns) : item.call_status.value === "closed"
            ? parseInt(item.closeTotal) || null
            : null,
          call_type: leg.id == undefined ? item.call_type.value : leg?.call_type !== null ? leg?.call_type : item.call_status.value === "closed" ? item.call_type.value : null,
          // call_type: leg?.call_type,
          // returns: leg?.returns,
          stop_loss_type: item.stopLossTypeOption.value,
          close_type:
            item.call_status.value === "closed"
              ? item.closeType.value || null
              : null,
          status:
            leg.exitPrice === undefined || leg.exitPrice === ""
              ? "Open"
              : "closed",
          action: leg.action.value,
          instrument_id: instrument_id, // Add instrument_id if it is not undefined
          closed_on:
            leg?.closed_on !== null
              ? leg?.closed_on
              : leg.exitPrice !== undefined && leg.exitPrice !== ""
                ? moment().format("YYYY-MM-DD HH:mm:ss")
                : null,
          legStatus: leg?.legStatus ? leg?.legStatus : 0,
        };

        if (leg.id !== undefined && leg.id !== "") {
          leg_out["id"] = leg.id;
        }
        // if (leg.instrument_id !== undefined && item.instrument_id !== "") {
        //   leg_out["instrument_id"] = leg.instrument_id;
        // }
        if (leg.instrument_type !== undefined && item.instrument_type !== "") {
          leg_out["instrument_type"] = leg.instrument_type;
        }
        if (leg?.entry_version !== undefined && leg?.entry_version !== "") {
          leg_out["entry_version"] = leg?.entry_version;
        }
        if (leg?.exit_version !== undefined && leg?.exit_version !== "") {
          leg_out["exit_version"] = leg?.exit_version;
        }
        return leg_out;
      })
    );
  };

  optionsAddScripArray = await Promise.all(
    data.optionsAddScripArray.map(async (item) => {
      let legs_info = await processOptionsData(item);

      let out = {
        bundle_id: data?.bundleId,
        name: item.scripNameValue.label,
        scrip_symbol: item.scripNameValue.value,
        call_class: "Option",
        call_type: item.call_type.value,
        capital_required: parseInt(item.capitalReq) || null,
        call_status: item.call_status.value,
        close_type:
          item.call_status.value === "closed"
            ? item.closeType.value || null
            : null,
        returns:
          item.call_status.value === "closed"
            ? parseInt(item.closeTotal) || null
            : null,
        current_price: null,
        market_trend: null,
        closed_on:
          item?.closed_on !== null
            ? item?.closed_on
            : item.call_status.value === "closed"
              ? moment().format("YYYY-MM-DD HH:mm:ss")
              : null,
        info: {
          strategy: item.strategy.value,
          max_loss: parseInt(item.maxLoss) || null,
          stopp_loss_value: parseInt(item.stopLoss) || null,
          stop_loss_type: item.stopLossTypeOption.value,
          exit_price: parseInt(item.exitPrice),
          legs_info: [...legs_info],
        },
      };
      if (item.id !== undefined && item.id !== "") {
        out["id"] = item.id;
      }
      return out;
    })
  );

  // let optionsAddScripArray = [];

  // optionsAddScripArray = data.optionsAddScripArray.map((item) => {
  //   let legs_info = item.legs.map((leg) => {
  //     let leg_out = {
  //       type: leg?.type ? leg?.type : "Option",
  //       quantity: parseInt(leg.qtyValue),
  //       strike_price: `${leg.strikePrice}${leg.option.value}`,
  //       series: moment(leg.expiry).format("Do MMM'YY"),
  //       price: parseInt(leg.Price),
  //       exit_price: leg.exitPrice === undefined ? "" : leg.exitPrice,
  //       bundle_calls_id: item?.id,
  //       bundle_id: data?.bundleId,
  //       fund_hedged_id: item?.fund_hedged_id,
  //       entry_price_start: leg?.Price,
  //       entry_price_end: leg?.Price,
  //       status:
  //         leg.exit_price === undefined || leg.exit_price === ""
  //           ? "Open"
  //           : "closed",
  //       action: leg.action.value,
  //     };

  //     if (leg.id !== undefined && leg.id !== "") {
  //       leg_out["id"] = leg.id;
  //     }
  //     if (leg.instrument_id !== undefined && item.instrument_id !== "") {
  //       leg_out["instrument_id"] = leg.instrument_id;
  //     }
  //     if (leg.instrument_type !== undefined && item.instrument_type !== "") {
  //       leg_out["instrument_type"] = leg.instrument_type;
  //     }
  //     if (leg?.entry_version !== undefined && leg?.entry_version !== "") {
  //       leg_out["entry_version"] = leg?.entry_version;
  //     }
  //     if (leg?.exit_version !== undefined && leg?.exit_version !== "") {
  //       leg_out["exit_version"] = leg?.exit_version;
  //     }
  //     return leg_out;
  //   });
  //   // console.log(item);
  //   let out = {
  //     bundle_id: data?.bundleId,
  //     name: item.scripNameValue.label,
  //     scrip_symbol: item.scripNameValue.value,
  //     call_class: "Option",
  //     call_type: item.call_type.value,
  //     capital_required: parseInt(item.capitalReq) || null,
  //     call_status: item.call_status.value,
  //     close_type:
  //       item.call_status.value === "closed"
  //         ? item.closeType.value || null
  //         : null,
  //     returns:
  //       item.call_status.value === "closed"
  //         ? parseInt(item.closeTotal) || null
  //         : null,
  //     current_price: null,
  //     market_trend: null,
  //     closed_on: item?.closed_on,
  //     info: {
  //       strategy: item.strategy.value,
  //       max_loss: parseInt(item.maxLoss) || null,
  //       stopp_loss_value: parseInt(item.stopLoss) || null,
  //       stop_loss_type: item.stopLossTypeOption.value,
  //       exit_price: parseInt(item.exitPrice),
  //       legs_info: [...legs_info],
  //     },
  //   };
  //   if (item.id !== undefined && item.id !== "") {
  //     out["id"] = item.id;
  //   }
  //   return out;
  // });

  outputData = {
    id: data?.bundleId,
    title: data.bundleTitle,
    planid: parseInt(planid) || null,
    expected_returns: parseInt(data.expectedReturn) || null,
    equity_capital_required: parseInt(data.equityCapital) || null,
    hedged_capital_required: parseInt(data.optionsCapital) || null,
    equity_notes: data.eqtynote,
    hedged_notes: data.optionalnote,
    current_returns: data.currentReturn || null,
    use_old_formula: data.use_old_formula || 0,
    enter_status: data.enter_status || 0,
    status: 1,
    bundles_calls: [...optionsAddScripArray, ...equityAddScripArray],
    bundle_type: data.type,
    bundle_version: data.bundle_version,
    is_notify : data?.notify
  };
  // outputData = {
  //   title: data.bundleTitle,
  //   // label:null,
  //   planid: parseInt(planid) || null,
  //   expected_returns: parseInt(data.expectedReturn) || null,
  //   equity_capital_required: parseInt(data.equityCapital) || null,
  //   hedged_capital_required: parseInt(data.optionsCapital) || null,
  //   equity_notes: data.eqtynote,
  //   hedged_notes: data.optionalnote,
  //   current_returns: data.currentReturn || null,
  //   use_old_formula: data.use_old_formula || 0,
  //   enter_status: data.enter_status || 0,
  //   status: 1,
  //   bundles_calls: [...optionsAddScripArray, ...equityAddScripArray],
  //   bundle_type: data.type,
  // };
  //console.log(outputData);
  return outputData;
};

let checkIfAllFieldsArePresent = (data) => {
  let equity = false;
  let option = false;
  let returnValue = false;

  data.bundles_calls.map((item) => {
    if (item.call_class === "Equity") {
      equity = true;
    } else if (item.call_class === "Option") {
      option = true;
      // item?.info?.leg_info?.map((elem, ind)=>{
      //   if (
      //     elem.instrument_id == ""
      //     ) {
      //       swal({
      //         title: `Instrument Not Found for Leg ${ind + 1}`,
      //         text: "Leg cannot be Add without instrument Id",
      //         icon: "info",
      //       });
      //       returnValue = true;
      //     }
      // })
    }
  });

  for (const property in data) {
    console.log(`property ${property} : ${data[property]}`);
    if (isEmptyField(data[property], !equity, !option, property)) {
      swal({
        text: `${fieldName[property]} field cannot be empty`,
        icon: "error",
      });
      returnValue = true;
    }
  }

  data.bundles_calls.map((call) => {
    // console.log(call);
    if (call.call_class === "Equity") {
      if (
        isEmpty(call.name, "Scrip Name") ||
        isEmpty(call.info.minprice, "Min Price") ||
        isEmpty(call.info.maxprice, "Max Price") ||
        isEmpty(call.info.quantity, "Quantity") ||
        isEmpty(call.info.quantity_percent, "Quantity %") ||
        isEmpty(call.call_type, "Call Type") ||
        isEmpty(call.call_status, "Call Status")
      ) {
        returnValue = true;
      }
    } else if (call.call_class === "Option") {
      if (
        isEmpty(call.name, "Scrip Name") ||
        isEmpty(call.capital_required, "Capital Required") ||
        isEmpty(call.info.strategy, "Strategy") ||
        isEmpty(call.info.max_loss, "Max Loss") ||
        isEmpty(call.info.stopp_loss_value, "Stop Loss Value") ||
        isEmpty(call.info.stop_loss_type, "Stop Loss Type") ||
        isEmpty(call.call_type, "Call Type") ||
        isEmpty(call.call_status, "Call Status")
      ) {
        returnValue = true;
      }
      call.info.legs_info.map((leg, index) => {
        if (
          isEmpty(leg.type, "Action") ||
          checkDate(leg.series, "Series") ||
          checkNAN(leg.price, "Price") ||
          checkNAN(leg.quantity, "Quantity") ||
          isEmpty(
            leg.strike_price.substring(0, leg.strike_price.length - 2),
            "Strike Price"
          ) ||
          isEmptyOption(leg.strike_price.slice(-2), "Strike Price Option") ||
          instrumentEmpty(leg.instrument_id, "Instrument", index, leg)
        ) {
          returnValue = true;
        }
      });
    }
  });

  return returnValue;
};

let isEmptyField = (field, equity, option, property) => {
  if (property === "equity_notes") {
    return false;
  }
  if (property === "hedged_notes") {
    return false;
  }
  if (equity) {
    if (property === "equity_capital_required") {
      return false;
    }
  }
  if (option) {
    if (property === "hedged_capital_required") {
      return false;
    }
  }
  if (field === undefined || field === null || field === "") {
    return true;
  }
  return false;
};

let instrumentEmpty = (field, property, ind, leg) => {
  if (field === undefined || field === null || field === "") {
    // if(property == "Instrument"){

    if (leg?.status == "closed") {
      return false;
    } else {
      swal({
        title: `${property} Not Found for Leg ${ind + 1}`,
        text: "Leg cannot be Add without instrument Id",
        icon: "info",
      });
      return true;
    }
    //   }else{

    //   swal({
    //     text: `${property} field cannot be empty`,
    //     icon: "error",
    //   });
    //   return true;
    // }
  }

  return false;
};

let isEmpty = (field, property) => {
  if (field === undefined || field === null || field === "") {
    // if(property == "Instrument"){

    //   swal({
    //             title: `Instrument Not Found for Leg ${ind + 1}`,
    //             text: "Leg cannot be Add without instrument Id",
    //             icon: "info",
    //           });
    //           return true;
    // }else{

    swal({
      text: `${property} field cannot be empty`,
      icon: "error",
    });
    return true;
    // }
  }

  return false;
};

let checkNAN = (field, property) => {
  if (isNaN(field)) {
    swal({
      text: `${property} field cannot be empty`,
      icon: "error",
    });
    return true;
  }
  return false;
};
let checkDate = (field, property) => {
  if (field === "Invalid date") {
    swal({
      text: `${property} field cannot be empty`,
      icon: "error",
    });
    return true;
  }
  return false;
};

let isEmptyOption = (field, property) => {
  if (field === "CE" || field === "PE") {
    return false;
  }
  swal({
    text: `${property} field cannot be empty`,
    icon: "error",
  });
  return true;
};

import React from 'react';
import Select from 'react-select';
import { getPrice } from './ApiSystemCalls';
import { FiPlusCircle } from 'react-icons/fi';
import { ImCross } from 'react-icons/im';

const optionsValue = [
  { value: 'Long call', label: 'Long call' },
  { value: 'Short Call', label: 'Short Call' },
  { value: 'Long Put', label: 'Long Put' },
  { value: 'Short Put', label: 'Short Put' },
  { value: 'Bull call spread', label: 'Bull call spread' },
  { value: 'Bear Put spread', label: 'Bear Put spread' },
  { value: 'Bull Put Spread', label: 'Bull Put Spread' },
  { value: 'Synthetic', label: 'Synthetic' },
  { value: 'Long Combo', label: 'Long Combo' },
  { value: 'Long Collar', label: 'Long Collar' },
  { value: 'Short Collar', label: 'Short Collar' },
  { value: 'Bear Call Spread', label: 'Bear Call Spread' },
  { value: 'Married Put', label: 'Married Put' },
  { value: 'Long Strangle', label: 'Long Strangle' },
  { value: 'Short Strangle', label: 'Short Strangle' },
  { value: 'Short Straddle', label: 'Short Straddle' },
  { value: 'Long Straddle', label: 'Long Straddle' },
  { value: 'Call Butterfly', label: 'Call Butterfly' },
  { value: 'Put Butterfly', label: 'Put Butterfly' },
  { value: 'Iron Fly', label: 'Iron Fly' },
  { value: 'Iron Condor', label: 'Iron Condor' },
  { value: 'Call Calendar spread', label: 'Call Calendar spread' },
  { value: 'Put Calendar Spread', label: 'Put Calendar Spread' },
  { value: 'Diagonal', label: 'Diagonal' },
  {
    value: 'Modified Futures Call Butterfly',
    label: 'Modified Futures Call Butterfly',
  },
  { value: 'Call Ratio Spread', label: 'Call Ratio Spread' },
  { value: 'Put Ratio spread', label: 'Put Ratio spread' },
  { value: 'Calendar Call Ratio Spread', label: 'Calendar Call Ratio Spread' },
  { value: 'Calendar Put Ratio spread', label: 'Calendar Put Ratio spread' },
  { value: 'Modified Close Call Collar', label: 'Modified Close Call Collar' },
  { value: 'Cross Calender Spread', label: 'Cross Calender Spread' },
  { value: 'Pull Call spread', label: 'Pull Call spread' },
  { value: 'Pull Put spread', label: 'Pull Put spread' },
];

class SystemLeftComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textDropDownToggle: true,
      autoCompleteEquity: '',
    };
  }

  getLatestPrice = async (symbol) => {
    return await getPrice(symbol);
  };

  render() {
    console.log("this.props", this.props.state);
    //  console.log(this.props.state.modifyHeader,this.props.state.newbie);
    return (
      <section className="newcall-lft-blk">
        <section
          className={`list-blk ${!this.props.state.modifyHeader && this.props.state.categoryId ? 'd-none' : null
            }`}
        >
          {/* call target */}
          <h2>Call For</h2>
          <ul className="list-group list-group-horizontal call-list">
            <li
              className={
                'list-group-item border-0 px-2 py-0 ' +
                (this.props.state.free ? 'active' : '')
              }
            >
              <span
                onClick={() => {
                  // if (this.props.state.modifyHeader && (this.props.state.optionPlan == 25 || this.props.state.equityPlan == 25)) {
                    // this.setState({ autoCompleteEquity: '' });
                    // console.log("rohan")
                    this.props.typeofCall('long');
                    this.props.callUserType('free');
                    this.props.updateEquityPlan(1);
                    this.props.updatePlanId(1);

                    if(this.props.state.categoryId){
                    this.props.updateCallStatus(this.props.state.callStatus);
                    }else{
                    this.props.updateCallStatus('');
                    }
                  // }else{
                  //   this.props.typeofCall('long');
                  //   this.props.callUserType('free');
                  //   this.props.updateEquityPlan(1);
                  //   this.props.updatePlanId(1);
                  //   this.props.updateCallStatus('');

                  // }
                }}
                className="d-block text-center"
              >
                Free
              </span>
            </li>
            <li
              className={
                'list-group-item border-0 px-2 py-0 ' +
                (this.props.state.prime ? 'active' : '')
              }
            >
              <span
                onClick={() => {
                  // if (this.props.state.modifyHeader) {
                    // this.setState({ autoCompleteEquity: '' });
                    this.props.typeofCall('long');
                    this.props.callUserType('prime');
                    this.props.updateEquityPlan(2);
                    this.props.updatePlanId(2);
                    if(this.props.state.categoryId){
                    this.props.updateCallStatus(this.props.state.callStatus);
                    }else{
                    this.props.updateCallStatus('');
                    }
                  // }else{
                  //   this.props.typeofCall('long');
                  //   this.props.callUserType('prime');
                  //   this.props.updateEquityPlan(2);
                  //   this.props.updatePlanId(2);
                  //   this.props.updateCallStatus('');

                  // }
                }}
                className="d-block text-center"
              >
                Prime
              </span>
            </li>
            <li
              className={
                'list-group-item border-0 px-2 py-0 ' +
                (this.props.state.crown ? 'active' : '')
              }
            >
              <span
                onClick={() => {
                  // if (this.props.state.modifyHeader && (this.props.state.optionPlan == 25 || this.props.state.equityPlan == 25)) {
                    // this.setState({ autoCompleteEquity: '' });
                    this.props.typeofCall('long');
                    this.props.callUserType('crown');
                    this.props.updateEquityPlan(3);
                    this.props.updatePlanId(3);
                    if(this.props.state.categoryId){
                    this.props.updateCallStatus(this.props.state.callStatus);
                    }else{
                    this.props.updateCallStatus('');
                    }
                 
                  // }else{
                  //   this.props.typeofCall('long');
                  //   this.props.callUserType('crown');
                  //   this.props.updateEquityPlan(3);
                  //   this.props.updatePlanId(3);
                  //   this.props.updateCallStatus('');

                  // }
                }}
                className="d-block text-center"
              >
                Crown
              </span>
            </li>

            <li
              className={
                'list-group-item border-0 px-2 py-0 ' +
                (this.props.state.two_hr ? 'active' : '')
              }
            >
              <span
                onClick={() => {
                  // if (this.props.state.modifyHeader && (this.props.state.optionPlan == 25 || this.props.state.equityPlan == 25)) {
                    // this.setState({ autoCompleteEquity: '' });
                    this.props.typeofCall('long');
                    this.props.callUserType('two_hr');
                    this.props.updateEquityPlan(5);
                    this.props.updatePlanId(5);
                    if(this.props.state.categoryId){
                    this.props.updateCallStatus(this.props.state.callStatus);
                    }else{
                    this.props.updateCallStatus('');
                    }
                  // }else{
                  //   this.props.typeofCall('long');
                  //   this.props.callUserType('two_hr');
                  //   this.props.updateEquityPlan(5);
                  //   this.props.updatePlanId(5);
                  //   this.props.updateCallStatus('');

                  // }
                }}
                className="d-block text-center"
              >
                2HR Trades
              </span>
            </li>

            <li
              className={
                'list-group-item border-0 px-2 py-0 ' +
                (this.props.state.spade ? 'active' : '')
              }
            >
              <span
                onClick={() => {
                  // if (this.props.state.modifyHeader && (this.props.state.optionPlan == 25 || this.props.state.equityPlan == 25)) {
                    // this.setState({ autoCompleteEquity: '' });
                    this.props.typeofCall('long');
                    this.props.callUserType('spade');
                    this.props.updateEquityPlan(6);
                    this.props.updatePlanId(6);
                    if(this.props.state.categoryId){
                    this.props.updateCallStatus(this.props.state.callStatus);
                    }else{
                    this.props.updateCallStatus('');
                    }
                  // }else{
                  //   this.props.typeofCall('long');
                  //   this.props.callUserType('spade');
                  //   this.props.updateEquityPlan(6);
                  //   this.props.updatePlanId(6);
                  //   this.props.updateCallStatus('');

                  // }
                }}
                className="d-block text-center"
              >
                AOS
              </span>
            </li>

          </ul>
        </section>
        <section
          className={`list-blk ${(this.props.state.free ||
            this.props.state.prime ||
            this.props.state.crown ||
            this.props.state.two_hr || this.props.state.spade)
            ? 'd-block'
            : 'd-none'
            }`}
        >
          {/* call target */}
          <h2>Call Target</h2>
          <ul className="list-group list-group-horizontal">
            {this.props.state.crown ? null : (
              <>
                <li
                  className={
                    'list-group-item border-0 px-2 py-0 ' +
                    (this.props.state.experienced ? 'active' : '')
                  }
                >
                  <span
                    onClick={() => {
                      if (!this.props.state.modifyHeader) {
                        this.props.callTarget('experienced');
                        this.props.updateCallStatus('');
                      }
                    }}
                    className="d-block text-center"
                  >
                    Experienced
                  </span>
                </li>
                <li
                  className={
                    'list-group-item border-0 px-2 py-0 ' +
                    (this.props.state.newbie ? 'active' : '')
                  }
                >
                  <span
                    onClick={() => {
                      if (!this.props.state.modifyHeader) {
                        this.props.callTarget('newbie');
                        this.props.updateCallStatus('');
                      }
                    }}
                    className="d-block text-center"
                  >
                    Newbie
                  </span>
                </li>
              </>
            )}
            <li
              className={
                'list-group-item border-0 px-2 py-0 ' +
                (this.props.state.common ? 'active' : '')
              }
            >
              <span
                onClick={() => {
                  if (!this.props.state.modifyHeader) {
                    this.props.callTarget('common');
                    this.props.updateCallStatus('');
                  }
                }}
                className="d-block text-center"
              >
                Both
              </span>
            </li>
          </ul>
        </section>
        {/* call class */}
        <section
          className={`list-blk ${this.props.state.experienced ||
            this.props.state.newbie ||
            this.props.state.common
            ? 'd-block'
            : 'd-none'
            }`}
        >
          <h2>What is the Call Class?</h2>
          <ul className="list-group list-group-horizontal">
            <li
              className={
                'list-group-item border-0 px-2 py-0 ' +
                (this.props.state.equity ? 'active' : '')
              }
            >
              <span
                onClick={() => {
                  // new
                  this.setState({ autoCompleteEquity: '' });
                  if (!this.props.state.modifyHeader) {
                    this.props.callClass('equity');
                    this.props.updateCallStatus('');
                    // new
                    this.setState({ textDropDownToggle: true });
                  }
                }}
                className="d-block text-center"
              >
                Equity
              </span>
            </li>
            <li
              className={`list-group-item border-0 px-2 py-0 ${this.props.state.option ? 'active' : ''
                }`}
            >
              <span
                onClick={() => {
                  if (!this.props.state.modifyHeader) {
                    this.props.callClass('option');
                    this.props.updateCallStatus('');
                    // new
                    this.setState({ textDropDownToggle: true });
                  }
                }}
                className="d-block text-center"
              >
                Option
              </span>
            </li>
          </ul>
        </section>
        {/* call status */}
        {this.props.state.option || this.props.state.equity ? (
          <>
            <section
              className={`list-blk ${this.props.state.option || this.props.state.equity
                ? 'd-block'
                : 'd-none'
                }`}
            >
              <h2>What is the Call Status?</h2>
              <ul className="list-group list-group-horizontal typ-row-wrap">
                <li
                  className={`list-group-item border-0 px-2 py-0 ${this.props.state.callStatus === 'open' ? 'active' : ''
                    }`}
                >
                  <span
                    onClick={() => {
                      // new
                      this.setState({
                        autoCompleteEquity: '',
                        textDropDownToggle: true,
                      });
                      this.props.updateCallStatus('open');
                    }}
                    className="d-block text-center"
                  >
                    Open
                  </span>
                </li>
                <li
                  className={
                    'list-group-item border-0 px-2 py-0 ' +
                    (this.props.state.callStatus === 'modified' ? 'active' : '')
                  }
                >
                  <span
                    onClick={() => {
                      // new
                      this.setState({
                        autoCompleteEquity: '',
                        textDropDownToggle: true,
                      });
                      this.props.updateCallStatus('modified');
                    }}
                    className="d-block text-center"
                  >
                    Modified
                  </span>
                </li>
                <li
                  className={
                    'list-group-item border-0 px-2 py-0 ' +
                    (this.props.state.callStatus === 'view' ? 'active' : '')
                  }
                >
                  <span
                    onClick={() => {
                      // new
                      this.setState({
                        autoCompleteEquity: '',
                        textDropDownToggle: true,
                      });

                      this.props.updateCallStatus('view');
                    }}
                    className="d-block text-center"
                  >
                    {/* View */}
                    Pending
                  </span>
                </li>
                {/* <li
                  className={
                    'list-group-item border-0 px-2 py-0 ' +
                    (this.props.state.callStatus === 'closed' ? 'active' : '')
                  }
                >
                  <span
                    onClick={() => {
                      // new
                      this.setState({
                        autoCompleteEquity: '',
                        textDropDownToggle: true,
                      });

                      this.props.updateCallStatus('closed');
                    }}
                    className="d-block text-center"
                  >
                    Closed
                  </span>
                </li> */}
              </ul>
            </section>
            <section
              className={`list-blk ${this.props.state.option || this.props.state.equity
                ? 'd-block'
                : 'd-none'
                }`}
            >
              <h2>What is the Call Enter Status?</h2>
              <ul className="list-group list-group-horizontal">
                <li
                  className={`list-group-item border-0 px-2 py-0 ${this.props.state.enter_status === 0 ? 'active' : ''
                    }`}
                >
                  <span
                    onClick={() => {
                      // new
                      this.setState({
                        autoCompleteEquity: '',
                        textDropDownToggle: true,
                      });
                      this.props.updateCallEnterStatus(0);
                    }}
                    className="d-block text-center"
                  >
                    Cannot Enter Now
                  </span>
                </li>
                <li
                  className={
                    'list-group-item border-0 px-2 py-0 ' +
                    (this.props.state.enter_status === 1 ? 'active' : '')
                  }
                >
                  <span
                    onClick={() => {
                      // new
                      this.setState({
                        autoCompleteEquity: '',
                        textDropDownToggle: true,
                      });
                      this.props.updateCallEnterStatus(1);
                    }}
                    className="d-block text-center"
                  >
                    Can Still Enter
                  </span>
                </li>
              </ul>
            </section>
          </>
        ) : null}
        {/* call class
        <section
          className={`list-blk ${
            this.props.state.newbie ? "d-block" : "d-none"
          }`}
        >
          <h2>What is the Call Class?</h2>
          <ul className="list-group list-group-horizontal">
            <li
              className={
                "list-group-item border-0 px-2 py-0 " +
                (this.props.state.equity ? "active" : "")
              }
            >
              <span
                onClick={() => this.props.callClass("equity")}
                className="d-block text-center"
              >
                Equity
              </span>
            </li>
            <li
              className={`list-group-item border-0 px-2 py-0 ${
                this.props.state.option ? "active" : ""
              }`}
            >
              <span
                onClick={() => this.props.callClass("option")}
                className="d-block text-center"
              >
                Option
              </span>
            </li>
          </ul>
        </section> */}
        {/* call status
        {this.props.state.option ? (
          <section
            className={`list-blk ${
              this.props.state.equity ? "d-block" : "d-none"
            }`}
          >
            <h2>What is the Call Status?</h2>
            <ul className="list-group list-group-horizontal">
              <li
                className={`list-group-item border-0 px-2 py-0 ${
                  this.props.state.callStatus === "Open" ? "active" : ""
                }`}
              >
                <span
                  onClick={() => this.props.updateCallStatus("Open")}
                  className="d-block text-center"
                >
                  Open
                </span>
              </li>
              <li
                className={
                  "list-group-item border-0 px-2 py-0 " +
                  (this.props.state.callStatus === "Modified" ? "active" : "")
                }
              >
                <span
                  onClick={() => this.props.updateCallStatus("Modified")}
                  className="d-block text-center"
                >
                  Modified
                </span>
              </li>
              <li
                className={
                  "list-group-item border-0 px-2 py-0 " +
                  (this.props.state.callStatus === "View" ? "active" : "")
                }
              >
                <span
                  onClick={() => this.props.updateCallStatus("View")}
                  className="d-block text-center"
                >
                  View
                </span>
              </li>
            </ul>
          </section>
        ) : null} */}
        {/* call type */}
        {this.props.state.equity ? (
          <section
            className={
              'list-blk ' +
              (this.props.state.callStatus !== '' ? 'd-block' : 'd-none')
            }
          >
            <h2>What type of Call?</h2>
            <ul className="list-group list-group-horizontal">
              <li
                className={`list-group-item border-0 px-2 py-0 ${this.props.state.long ? 'active' : ''
                  }`}
              >
                <span
                  onClick={() => {
                    // new
                    this.setState({ autoCompleteEquity: '' });
                    this.props.typeofCall('long');
                  }}
                  className="d-block text-center"
                >
                  Long
                </span>
              </li>
              <li
                className={`list-group-item border-0 px-2 py-0 ${this.props.state.short ? 'active' : ''
                  }`}
              >
                <span
                  onClick={() => {
                    // new
                    this.setState({ autoCompleteEquity: '' });

                    this.props.typeofCall('short');
                  }}
                  className="d-block text-center"
                >
                  Short
                </span>
              </li>
            </ul>
          </section>
        ) : null}
        {/* scrip */}
        <section
          className={`list-blk ${this.props.state.longWhichScripInput ? 'd-block' : 'd-none'
            }`}
        >
          {/* <h2>Which scrip?</h2>
          <div className="form-group">
            <input
              onChange={(e) => this.props.autoCompleteFun(e)}
             
              id="search"
              type="text"
              className="form-control"
              placeholder="Type the first few characters of the scrip's name or code"
              autoComplete="off"
            />
            <ul
              id="autocomplete"
              onClick={this.props.rightComponentShowFun}
            ></ul>
            <aside id="no-results">
              <p>No results</p>
            </aside>
          </div> */}
          {/* ---------------------------------------------equity scrip ---------------------------------------------*/}

          <h2>Which scrip?</h2>
          <div className="form-group">
            <input
              // old
              // onKeyUp={this.props.autoCompleteFun}

              // new
              onChange={(e) => {
                this.setState({ autoCompleteEquity: e.target.value });
                this.props.autoCompleteFun(e);
              }}
              id="search"
              type="text"
              className="form-control"
              placeholder="Type the first few characters of the scrip's name or code "
              autoComplete="off"
            />
            {/* old  */}
            {/* <ul id="autocomplete" onClick={this.props.rightComponentShowFun}>
              {this.props.state.equityScrip.map((item) => (
                <li id={item["symbol"]} key={item["company_name"]}>
                  <span>{item["company_name"]}</span>
                  <span>{}</span>
                </li>
              ))}
            </ul>
            {this.props.state.equityScrip.length === 0 ? (
              <aside id="no-results" style={{ display: "block" }}>
                <p>No results</p>
              </aside>
            ) : null} */}

            {/* new  */}
            {this.props.state.equityScrip.length !== 0 ? (
              <ul id="autocomplete">
                {this.props.state.equityScrip.map((item) => {
                  return (
                    <li
                      id={item['symbol']}
                      key={item['company_name']}
                      onClick={(e) =>
                        this.props.rightComponentShowFun(
                          e,
                          item['company_name']
                        )
                      }
                    >
                      <span>{item['company_name']}</span>
                      <span>{ }</span>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <aside id="no-results" style={{ display: 'block' }}>
                <p>No results</p>
              </aside>
            )}
          </div>
        </section>

        <section
          className={`list-blk ${this.props.state.longWhichScripGray ? 'd-block' : 'd-none'
            }`}
        >
          {/* <h2>Which scrip?</h2>
          <div className="which-scrip-show" id="which-scrip-show">
            <p id="which-scrip-append" className="append-scrip">
              <span>{this.props.state.companyName}</span>
              <span>{this.props.state.priceVal}</span>
            </p>
            <p className="edit-btn" onClick={this.props.editBtn}></p>
          </div> */}
          <h2>Which scrip?</h2>
          <div className="which-scrip-show" id="which-scrip-show">
            <p id="which-scrip-append" className="append-scrip">
              <span>{this.props.state.companyName}</span>
              <span>{this.props.state.priceVal}</span>
            </p>
            {!this.props.state.modifyHeader ? (
              <p className="edit-btn" onClick={this.props.editBtn}></p>
            ) : null}
          </div>
        </section>

        {/* Option > section */}
        {this.props.state.option ? (
          <section
            className={`list-blk ${this.props.state.callStatus !== '' ? 'd-block' : 'd-none'
              }`}
          >
            <h2>What type of call?</h2>
            <ul
              className="list-group list-group-horizontal"
              style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}
            >
              <li
                className={`list-group-item border-0 px-2 py-0 ${this.props.state.optionsBulish ? 'active' : ''
                  }`}
              >
                <span
                  onClick={() => this.props.optionsTypeofCall('optionsBulish')}
                  className="d-block text-center"
                >
                  Bullish
                </span>
              </li>
              <li
                className={`list-group-item border-0 px-2 py-0 ${this.props.state.optionsBearish ? 'active' : ''
                  }`}
              >
                <span
                  onClick={() => this.props.optionsTypeofCall('optionsBearish')}
                  className="d-block text-center"
                >
                  Bearish
                </span>
              </li>
              <li
                className={`list-group-item border-0 px-2 py-0 ${this.props.state.optionsNeutral ? 'active' : ''
                  }`}
              >
                <span
                  onClick={() => this.props.optionsTypeofCall('optionsNeutral')}
                  className="d-block text-center"
                >
                  Neutral
                </span>
              </li>
              <li
                className={`list-group-item border-0 px-2 py-0 ${this.props.state.optionsBidirectional ? 'active' : ''
                  }`}
              >
                <span
                  onClick={() =>
                    this.props.optionsTypeofCall('optionsBidirectional')
                  }
                  className="d-block text-center"
                >
                  Bi-Di
                </span>
              </li>
            </ul>
          </section>
        ) : null}

        {/* old  */}
        {/* <section
          className={`list-blk ${
            this.props.state.optionStrategySelect ? "d-block" : "d-none"
          }`}
        >
          <h2>Strategy</h2>
          <Select
            value={this.props.state.optionsSelect}
            onChange={this.props.optionsSelectOnChange}
            options={optionsValue}
            placeholder="Strategy"
            //menuIsOpen={this.state.menuIsOpen}
          />
        </section> */}
        {/* <section
          className={`list-blk options-gray ${
            this.props.state.optionStrategyGray ? "d-block" : "d-none"
          }`}
        >
          <section className="form-group">
            <h2>Strategy</h2>
            <div className="which-scrip-show">
              <p className="append-scrip">{this.props.state.optionsSelect}</p>
              <p
                className="edit-btn"
                value={optionsValue.filter(
                  (item) => item.value === this.props.state.optionsSelect
                )}
                onClick={this.props.optionCallEdit}
              ></p>
            </div>
          </section>
        </section> */}

        {/* new  */}
        <div className="row">
          {this.state.textDropDownToggle ? (
            <div className="col-lg-6">
              <section
                className={`list-blk ${this.props.state.optionStrategySelect ? 'd-block' : 'd-none'
                  }`}
              >
                <h2>Strategy</h2>
                <Select
                  value={this.props.state.optionsSelect}
                  onChange={this.props.optionsSelectOnChange}
                  options={optionsValue}
                  placeholder="Strategy"
                //menuIsOpen={this.state.menuIsOpen}
                />
              </section>
              <section
                className={`list-blk options-gray ${this.props.state.optionStrategyGray ? 'd-block' : 'd-none'
                  }`}
              >
                <section className="form-group">
                  <h2>Strategy</h2>
                  <div className="which-scrip-show">
                    {/* <p className="append-scrip">{this.props.state.optionsSelect.value}</p> */}
                    <p className="append-scrip">
                      {this.props.state.optionsSelect}
                    </p>
                    <p
                      className="edit-btn"
                      value={{
                        value: this.props.state.optionsSelect,
                        label: this.props.state.optionsSelect,
                      }}
                      onClick={this.props.optionCallEdit}
                    ></p>
                  </div>
                </section>
              </section>
            </div>
          ) : (
            <React.Fragment>
              {(this.props.state.optionsBulish ||
                this.props.state.optionsBearish ||
                this.props.state.optionsNeutral ||
                this.props.state.optionsBidirectional) &&
                this.props.state.option ? (
                <div className="col-lg-6">
                  <section className={`list-blk d-block`}>
                    <h2>Strategy</h2>
                    <div className="form-group">
                      <input
                        onKeyUp={this.props.optionsSelectType}
                        type="text"
                        className="form-control"
                        placeholder="Entry Strategy"
                        autoComplete="off"
                      />
                    </div>
                  </section>
                </div>
              ) : null}
            </React.Fragment>
          )}
          {(this.props.state.optionsBulish ||
            this.props.state.optionsBearish ||
            this.props.state.optionsNeutral ||
            this.props.state.optionsBidirectional) &&
            this.props.state.option &&
            this.state.textDropDownToggle ? (
            <div className="col-lg-1 strategy-btn">
              <FiPlusCircle
                size={25}
                onClick={() => {
                  this.setState({ textDropDownToggle: false });
                }}
              />
            </div>
          ) : null}
          {(this.props.state.optionsBulish ||
            this.props.state.optionsBearish ||
            this.props.state.optionsNeutral ||
            this.props.state.optionsBidirectional) &&
            this.props.state.option &&
            !this.state.textDropDownToggle ? (
            <div className="col-lg-1 strategy-btn">
              <ImCross
                size={17}
                onClick={() => {
                  this.setState({ textDropDownToggle: true });
                  this.props.optionsDeselect();
                }}
              />
            </div>
          ) : null}
        </div>

        {this.props.state.optionStrategyGray ? (
          <section
            className={`list-blk ${this.props.state.whichScrip1Input ? 'd-block' : 'd-none'
              } ${this.props.state.whichScrip1Gray ? 'd-none' : ''}`}
          >
            {/* ----------------------------------------------scrip for options flow ------------------------------------------- */}
            <h2>Which scrip?</h2>
            <div className="form-group">
              <input
                onKeyUp={this.props.autoCompleteFun1}
                id="search1"
                type="text"
                className="form-control"
                placeholder="Type the first few characters of the scrip's name or code "
                autoComplete="off"
              />
              <ul
                id="autocomplete1"
              // new
              // onClick={this.props.optionRightComponentShowFun}
              >
                {this.props.state.optionScrip.map((item) => (
                  //old
                  // <li id={item["symbol"]} key={item["company_name"]}>
                  //   <span>{item["company_name"]}</span>
                  //   <span>{}</span>
                  // </li>

                  //new
                  <li
                    id={item['symbol']}
                    key={item['company_name']}
                    onClick={(e) =>
                      this.props.optionRightComponentShowFun(
                        e,
                        item['company_name']
                      )
                    }
                  >
                    <span>{item['company_name']}</span>
                    <span>{ }</span>
                  </li>
                ))}
              </ul>
              {this.props.state.optionScrip.length === 0 ? (
                <aside id="no-results" style={{ display: 'block' }}>
                  <p>No results</p>
                </aside>
              ) : null}
            </div>
          </section>
        ) : null}

        <section
          className={`list-blk ${this.props.state.whichScrip1Gray ? 'd-block' : 'd-none'
            }`}
        >
          <section className="form-group">
            <h2>Which scrip?</h2>
            <div className="which-scrip-show" id="which-scrip-show1">
              <p id="which-scrip-append1" className="append-scrip">
                <span>{this.props.state.optionCompanyName}</span>
                <span>{this.props.state.optionPriceValue}</span>
              </p>

              {!this.props.state.modifyHeader ? (
                <p className="edit-btn" onClick={this.props.optionWSEdit}></p>
              ) : null}
            </div>
          </section>
        </section>
      </section>
    );
  }
}

export default SystemLeftComponent;

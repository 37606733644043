import React, { Component } from "react";
import Select from "react-select";
import { FiPlusCircle } from "react-icons/fi";
import { ImCross } from "react-icons/im";
import { getPrice } from "./ApiCalls";

const optionsValue = [
  { value: "Long call", label: "Long call" },
  { value: "Bear Call", label: "Bear Call" },
  { value: "Long Put", label: "Long Put" },
  { value: "Bull Put", label: "Bull Put" },
];

class IntradayLeftComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textDropDownToggle: true,
      autoCompleteEquity: "",
    };
  }

  getLatestPrice = async (symbol) => {
    return await getPrice(symbol);
  };

  render() {
    return (
      <section className="newcall-lft-blk">
        <section className="list-blk d-block">
          <h2>What is the Call Status?</h2>
          <ul className="list-group list-group-horizontal">
            <li
              className={`list-group-item border-0 px-2 py-0 ${
                this.props.state.callStatus === "open" ? "active" : ""
              }`}
            >
              <span
                onClick={() => {
                  // new
                  this.setState({
                    autoCompleteEquity: "",
                    textDropDownToggle: true,
                  });
                  this.props.updateCallStatus("open");
                }}
                className="d-block text-center"
              >
                Open
              </span>
            </li>

            <li
              className={
                "list-group-item border-0 px-2 py-0 " +
                (this.props.state.callStatus === "pending" ? "active" : "")
              }
            >
              <span
                onClick={() => {
                  // new
                  this.setState({
                    autoCompleteEquity: "",
                    textDropDownToggle: true,
                  });

                  this.props.updateCallStatus("pending");
                }}
                className="d-block text-center"
              >
                {/* View */}
                Pending
              </span>
            </li>
          </ul>
        </section>
        <section className="list-blk d-block ">
          <h2>Plan</h2>
          <ul
            className="list-group list-group-horizontal"
            style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}
          >
            <li
              className={`list-group-item border-0 px-2 py-0 ${
                this.props.state.planid == 1 ? "active" : ""
              }`}
            >
              <span
                onClick={() =>
                  this.props.updatePlan(1)
                }
                className="d-block text-center"
              >
                Free
              </span>
            </li>
            <li
              className={`list-group-item border-0 px-2 py-0 ${
                this.props.state.planid == 3 ? "active" : ""
              }`}
            >
              <span
                onClick={() =>
                  this.props.updatePlan(3)
                }
                className="d-block text-center"
              >
                Crown
              </span>
            </li>
          </ul>
        </section>
        <section className="list-blk d-block ">
          <h2>Strategy</h2>
          <ul
            className="list-group list-group-horizontal"
            style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}
          >
            <li
              className={`list-group-item border-0 px-2 py-0 ${
                this.props.state.optionsSelect == "Long call" ? "active" : ""
              }`}
            >
              <span
                onClick={() =>
                  this.props.optionsSelectOnChange({
                    value: "Long call",
                  })
                }
                className="d-block text-center"
              >
                Long Call
              </span>
            </li>
            <li
              className={`list-group-item border-0 px-2 py-0 ${
                this.props.state.optionsSelect == "Long Put" ? "active" : ""
              }`}
            >
              <span
                onClick={() =>
                  this.props.optionsSelectOnChange({
                    value: "Long Put",
                  })
                }
                className="d-block text-center"
              >
                Long Put
              </span>
            </li>
            <li
              className={`list-group-item border-0 px-2 py-0 ${
                this.props.state.optionsSelect == "Bear Call" ? "active" : ""
              }`}
            >
              <span
                onClick={() =>
                  this.props.optionsSelectOnChange({
                    value: "Bear Call",
                  })
                }
                className="d-block text-center"
              >
                Bear Call
              </span>
            </li>

            <li
              className={`list-group-item border-0 px-2 py-0 ${
                this.props.state.optionsSelect == "Bull Put" ? "active" : ""
              }`}
            >
              <span
                onClick={() =>
                  this.props.optionsSelectOnChange({
                    value: "Bull Put",
                  })
                }
                className="d-block text-center"
              >
                Bull Put
              </span>
            </li>
          </ul>
        </section>
        <section className="list-blk d-block ">
          <h2>What type of Call?</h2>
          <ul
            className="list-group list-group-horizontal"
            style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}
          >
            <li
              className={`list-group-item border-0 px-2 py-0 ${
                this.props.state.optionsBulish ? "active" : ""
              }`}
            >
              <span
                onClick={() => this.props.optionsTypeofCall("optionsBulish")}
                className="d-block text-center"
              >
                Bullish
              </span>
            </li>
            <li
              className={`list-group-item border-0 px-2 py-0 ${
                this.props.state.optionsBearish ? "active" : ""
              }`}
            >
              <span
                onClick={() => this.props.optionsTypeofCall("optionsBearish")}
                className="d-block text-center"
              >
                Bearish
              </span>
            </li>
          </ul>
        </section>{" "}
        {this.props.state.optionsSelect &&
        (this.props.state.optionsBearish || this.props.state.optionsBulish) ? (
          <>
            {this.props.state.payoffurl && this.props.state.payoffurl ? (
              <section className="list-blk d-block">
                <h2>Payoff Chart : </h2>

                <img
                  src={this.props.state.getpayoffchartUrl}
                  style={{
                    width: "100%",
                  }}
                />
              </section>
            ) : null}
          </>
        ) : null}
        <section className="list-blk d-block">
          <h2>Which scrip?</h2>
          <ul className="list-group list-group-horizontal">
            <li
              className={`list-group-item border-0 px-2 py-0 ${
                this.props.state.scripname === "nifty" ? "active" : ""
              }`}
            >
              <span
                onClick={() => {
                  // new
                  this.setState({
                    autoCompleteEquity: "",
                    textDropDownToggle: true,
                  });
                  this.props.updateScripname("nifty");
                }}
                className="d-block text-center"
              >
                Nifty
              </span>
            </li>

            <li
              className={
                "list-group-item border-0 px-2 py-0 " +
                (this.props.state.scripname === "banknifty" ? "active" : "")
              }
            >
              <span
                onClick={() => {
                  // new
                  this.setState({
                    autoCompleteEquity: "",
                    textDropDownToggle: true,
                  });

                  this.props.updateScripname("banknifty");
                }}
                className="d-block text-center"
              >
                {/* View */}
                BankNifty
              </span>
            </li>
            <li
              className={
                "list-group-item border-0 px-2 py-0 " +
                (this.props.state.scripname === "Finnifty" ? "active" : "")
              }
            >
              <span
                onClick={() => {
                  // new
                  this.setState({
                    autoCompleteEquity: "",
                    textDropDownToggle: true,
                  });

                  this.props.updateScripname("Finnifty");
                }}
                className="d-block text-center"
              >
                {/* View */}
                Finnifty
              </span>
            </li>
          </ul>
        </section>
      </section>
    );
  }
}

export default IntradayLeftComponent;

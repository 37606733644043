import React from 'react';

class CloseCall extends React.Component {
  constructor() {
    super();
    this.state = {
      profitTotal: '',
      losstotal: '',
      profitNotes: '',
      lossNotes: '',
      breakEvenNotes: '',
    };
  }

  render() {
    return (
      <section className="admin-close-call">
        <section className="box-blk">
          <section className="box">
            <h3>Close this call</h3>
            <section className="list-blk">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  onChange={() => this.props.callOnChange('closeProfitChecked')}
                  checked={this.props.state.closeProfitChecked}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  Profit
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  onChange={() => this.props.callOnChange('closeLossChecked')}
                  checked={this.props.state.closeLossChecked}
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  Loss
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio3"
                  onChange={() =>
                    this.props.callOnChange('closeBrokenEvenChecked')
                  }
                  checked={this.props.state.closeBrokenEvenChecked}
                />
                <label className="form-check-label" htmlFor="inlineRadio3">
                  Break even
                </label>
              </div>
            </section>

            <section
              className={`input-fields-blk ${
                this.props.state.closeProfitChecked ? 'd-block' : 'd-none'
              }`}
            >
              <div className="form-group">
                <input
                  value={this.state.profitTotal}
                  className="form-control"
                  type="text"
                  placeholder="Total Profit"
                  onChange={(e) => {
                    this.setState({
                      profitTotal: e.target.value
                        .split('')
                        .filter((item) => item.match(/[0-9\\.]/i))
                        .join(''),
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <textarea
                  value={this.state.profitNotes}
                  className="form-control"
                  placeholder="Notes on this call"
                  onChange={(e) => {
                    this.setState({
                      profitNotes: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
              <aside className="call-final-btn">
                <a
                  title="Close"
                  onClick={() => {
                    this.props.callClosePopup(
                      this.state.profitNotes,
                      this.state.profitTotal
                    );
                  }}
                >
                  Close
                </a>
              </aside>
            </section>

            <section
              className={`input-fields-blk ${
                this.props.state.closeLossChecked ? 'd-block' : 'd-none'
              }`}
            >
              <div className="form-group">
                <input
                  value={this.state.lossTotal}
                  className="form-control"
                  type="text"
                  placeholder="Total Loss"
                  onChange={(e) => {
                    this.setState({
                      lossTotal: e.target.value
                        .split('')
                        .filter((item) => item.match(/[0-9\\.]/i))
                        .join(''),
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <textarea
                  value={this.state.lossNotes}
                  className="form-control"
                  placeholder="Notes on this call"
                  onChange={(e) => {
                    this.setState({ lossNotes: e.target.value });
                  }}
                ></textarea>
              </div>
              <aside className="call-final-btn">
                <a
                  title="Close"
                  onClick={() => {
                    this.props.callClosePopup(
                      this.state.lossNotes,
                      this.state.lossTotal
                    );
                  }}
                >
                  Close
                </a>
              </aside>
            </section>

            <section
              className={`input-fields-blk ${
                this.props.state.closeBrokenEvenChecked ? 'd-block' : 'd-none'
              }`}
            >
              <div className="form-group">
                <textarea
                  value={this.state.breakEvenNotes}
                  className="form-control"
                  placeholder="Notes on this call"
                  onChange={(e) => {
                    this.setState({ breakEvenNotes: e.target.value });
                  }}
                ></textarea>
              </div>
              <aside className="call-final-btn">
                <a
                  title="Close"
                  onClick={() => {
                    this.props.callClosePopup(this.state.breakEvenNotes);
                  }}
                >
                  Close
                </a>
              </aside>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

export default CloseCall;

import React from "react";
import { saveAs } from "file-saver";
import { NavLink } from "react-router-dom";
import Calendar from 'react-calendar';
import * as API from "../../configuration/apiconfig";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import Datatable from "react-bs-datatable";
import { async } from "q";
const format1 = "DD/MM/YYYY hh:mm A";

class AuditCalls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newcall: null,
      plans: [],
      plansData: [],
      bundle_calls: [],
      audit_calls: [],
      filter_type: 'call',
      selectedCallOption: '',
      audit_calls_data: {
        type: '',
        call_type: '',
        planid: '',
        status: '',
        startDate: '',
        endDate: ''
      },
      audit_bundles_data: {
        bundle_type: '',
        planid: '',
        status: '',
        startDate: '',
        endDate: ''
      },
      startDateEnable: false,
      endDateEnable: false,
      startDateEnableBundle: false,
      endDateEnableBundle: false
    };
  }

  newCallFunction = (getTxt) => {
    this.setState({
      newcall: getTxt,
    });
  };

  getData = async () => {
    // `/api/calls/deleted-calls`
    await API.callEndpoint("GET", "Bearer", `/api/audit/auditbundles`)
      .then(async (response) => {
        console.log(response, "=== bundle response")
        this.setState({
          bundle_calls: response?.data?.data?.data,
        });
      })
      .catch((error) => {
        console.log(error.error);
      });


    await API.callEndpoint("GET", "Bearer", `/api/audit/auditcalls`)
      .then(async (response) => {
        this.setState({
          audit_calls: response?.data?.data,
        });
      })
      .catch((error) => {
        console.log(error.error);
      });
  };

  componentDidMount = async () => {
    await this.getData();
  };

  handleExportCalls = async () => {
    await API.callDownloadEndpoint(
      "GET",
      "Bearer",
      `/api/audit/auditcalls/export`
    )
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `calls_${moment().format(format1)}.xlsx`);
      })
      .catch(error => console.error(error));
  }

  handleExportBundles = async () => {
    await API.callDownloadEndpoint(
      "GET",
      "Bearer",
      `/api/audit/auditbundles/export`
    )
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `funds_${moment().format(format1)}.xlsx`);
      })
      .catch(error => console.error(error));
  }

  filterBundleData = async (data) => {
    const queryString = Object.keys(data)
      .map(key => {
        return key + '=' + encodeURIComponent(data[key]);
      })
      .join('&');
    let res = await API.callEndpoint(
      "GET",
      "Bearer",
      `/api/audit/auditbundles?${queryString}`
    )
    let callsData = await res.data.data.data
    this.setState({ bundle_calls: callsData })
  }


  setCallDataFilter = (key, value) => {
    this.setState(prevState => ({
      audit_calls_data: {
        ...prevState.audit_calls_data,  // This spreads out the previous user object
        [key]: value        // This overwrites the name property
      }
    }));
    if (key == 'startDate') {
      this.setState({ startDateEnable: false })
    }
    if (key == 'endDate') {
      this.setState({ endDateEnable: false })
    }
  }

  setBundleDataFilter = (key, value) => {
    this.setState(prevState => ({
      audit_bundles_data: {
        ...prevState.audit_bundles_data,  // This spreads out the previous user object
        [key]: value        // This overwrites the name property
      }
    }));

    if (key == 'startDate') {
      this.setState({ startDateEnableBundle: false })
    }
    if (key == 'endDate') {
      this.setState({ endDateEnableBundle: false })
    }

  }
  filterCallData = async (data) => {
    const queryString = Object.keys(data)
      .map(key => {
        return key + '=' + encodeURIComponent(data[key]);
      })
      .join('&');
    let res = await API.callEndpoint(
      "GET",
      "Bearer",
      `/api/audit/auditcalls?${queryString}`
    )
    let callsData = await res.data.data
    this.setState({ audit_calls: callsData })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.audit_calls_data !== this.state.audit_calls_data) {
      this.filterCallData(this.state.audit_calls_data)
    }
    if (prevState.audit_bundles_data !== this.state.audit_bundles_data) {
      this.filterBundleData(this.state.audit_bundles_data)
    }
  }

  render() {
    return (
      <section className="admin-calls">
        <h1 className="head text-uppercase m-0">Audit</h1>
        <div className="row mb-4">
          <div className="col-md-8 d-flex align-items-center"></div>
          <div className="col-md-4 text-end calls-dropdown">
            <div
              className="new-call-btn"
              style={{
                width: 160,
                marginLeft: 5,
              }}
            >
              <div
                className="new-call-btn"
                onClick={() => this.handleExportCalls()}
                style={{
                  width: 160,
                  marginLeft: 5,
                  display: "block",
                  background: "#5d5d5d",
                  borderRadius: "5px",
                  font: "16px/20px Graphik-Medium",
                  color: "#fff",
                  textAlign: "center",
                  padding: "10px",
                  cursor: "pointer"

                }}
              >
                Export Calls

              </div>
            </div>
            <div
              className="new-call-btn"
              onClick={() => this.handleExportBundles()}
              style={{
                width: 160,
                marginLeft: 5,
                display: "block",
                background: "#5d5d5d",
                borderRadius: "5px",
                font: "16px/20px Graphik-Medium",
                color: "#fff",
                textAlign: "center",
                padding: "10px",
                cursor: "pointer"

              }}
            >
              Export Funds

            </div>
          </div>
        </div>
        <div>
          {this.state.filter_type == 'call' && (
            <>
              <div>

                {/* <Loader className="loaderIcon"></Loader> */}
                <h4>Call Filter : </h4>
                <div>
                  <select className="selected-date-btn" style={{ marginLeft: '10px' }} value={this.state.audit_calls_data.type} onChange={(e) => this.setCallDataFilter('type', e.target.value)}>
                    <option value="">Class</option>
                    <option value="Options">Option</option>
                    <option value="Equity">Equity</option>
                  </select>

                  <select className="selected-date-btn" style={{ marginLeft: '10px' }} value={this.state.audit_calls_data.call_type} onChange={(e) => this.setCallDataFilter('call_type', e.target.value)}>

                    <option value="">Type</option>
                    {
                      ['Bullish', 'Bearish', 'Neutral', 'Bi-Di', 'Long', 'Short'].map((item, idx) => <option value={item} key={idx}>{item}</option>)
                    }

                  </select>

                  <select style={{ marginLeft: '10px' }} className="selected-date-btn" value={this.state.audit_calls_data.status} onChange={(e) => this.setCallDataFilter('status', e.target.value)}>

                    <option value="">Status</option>
                    {
                      ['closed', 'view', 'modified', 'open'].map((item, idx) => <option value={item} key={idx}>{item}</option>)
                    }

                  </select>

                  <select style={{ marginLeft: '10px' }} className="selected-date-btn" value={this.state.audit_calls_data.planid} onChange={(e) => this.setCallDataFilter('planid', e.target.value)}>

                    <option value="">Plan</option>
                    <option value={1}>Free</option>
                    <option value={2}>Prime</option>
                    <option value={3}>Crown</option>
                    <option value={5}>2Hr</option>
                  </select>
                  <div style={{ marginTop: '10px' }}>
                    <span> Start Date : </span>
                    {this.state.startDateEnable ? <span style={{ display: 'inline-block', position: 'absolute' }}> <Calendar onChange={(e) => { this.setCallDataFilter('startDate', moment(e).format('yyyy-MM-DD')) }} /></span> : <button className="selected-date-btn"
                      style={{
                        margin: "0 10px",
                        background: "rgb(93, 93, 93)",
                        borderRadius: "5px",
                        font: "16px / 20px Graphik-Medium",
                        color: " rgb(255, 255, 255)",
                        textAlign: "center",
                        padding: "0 6px",
                        cursor: "pointer"
                      }}
                      onClick={() => this.setState({ startDateEnable: !this.state.startDateEnable })}>{this.state.audit_calls_data.startDate != "" ? `${this.state.audit_calls_data.startDate}` : 'Select Date'}</button>}
                    <span><button
                      style={{
                        margin: "0 10px",
                        background: "rgb(93, 93, 93)",
                        borderRadius: "5px",
                        font: "16px / 20px Graphik-Medium",
                        color: " rgb(255, 255, 255)",
                        textAlign: "center",
                        padding: "0 6px",
                        cursor: "pointer"
                      }}
                      className="selected-date-btn" onClick={() => this.setCallDataFilter('startDate', '')}>Clear</button></span>
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <span> End Date : </span>
                    {this.state.endDateEnable ? <span style={{ display: 'inline-block', position: 'absolute' }}> <Calendar onChange={(e) => { this.setCallDataFilter('endDate', moment(e).format('yyyy-MM-DD')) }} /></span> : <button
                      style={{
                        margin: "0 10px",
                        background: "rgb(93, 93, 93)",
                        borderRadius: "5px",
                        font: "16px / 20px Graphik-Medium",
                        color: " rgb(255, 255, 255)",
                        textAlign: "center",
                        padding: "0 6px",
                        cursor: "pointer"
                      }}
                      className="selected-date-btn" onClick={() => this.setState({ endDateEnable: !this.state.endDateEnable })}>{this.state.audit_calls_data.endDate != "" ? `${this.state.audit_calls_data.endDate}` : 'Select Date'}</button>}
                    <span><button
                      style={{
                        margin: "0 10px",
                        marginBottom: "10px",
                        background: "rgb(93, 93, 93)",
                        borderRadius: "5px",
                        font: "16px / 20px Graphik-Medium",
                        color: " rgb(255, 255, 255)",
                        textAlign: "center",
                        padding: "0 6px",
                        cursor: "pointer"
                      }}
                      className="selected-date-btn" onClick={() => this.setCallDataFilter('endDate', '')}>Clear</button></span>
                  </div>
                </div>
              </div>
            </>
          )}
          {this.state.filter_type == 'funds' && (
            <div>

              {/* <Loader className="loaderIcon"></Loader> */}
              <h4>Fund Filter : </h4>
              <div>

                <select style={{ marginLeft: '10px' }} className="selected-date-btn" value={this.state.audit_bundles_data.bundle_type} onChange={(e) => this.setBundleDataFilter('bundle_type', e.target.value)}>
                  <option value="">Type</option>
                  {
                    ['Large Cap Live Funds', 'All seasons Live Funds', 'Mid Cap Live Funds', 'Multi Cap Live Funds'].map((item, idx) => <option value={item} key={idx}>{item}</option>)
                  }
                </select>

                <select style={{ marginLeft: '10px' }} className="selected-date-btn" value={this.state.audit_bundles_data.status} onChange={(e) => this.setBundleDataFilter('status', e.target.value)}>

                  <option value="">Status</option>

                  <option value={0}>Closed</option>
                  <option value={1}>Open</option>

                </select>

                <select style={{ marginLeft: '10px' }} className="selected-date-btn" value={this.state.audit_bundles_data.planid} onChange={(e) => this.setBundleDataFilter('planid', e.target.value)}>
                  <option value="">Plan</option>
                  <option value={1}>Free</option>
                  <option value={2}>Prime</option>
                  <option value={3}>Crown</option>
                </select>
                <div style={{ marginTop: '10px' }}>
                  <span> Start Date : </span>
                  {this.state.startDateEnableBundle ? <span style={{ display: 'inline-block', position: 'absolute' }}> <Calendar onChange={(e) => { this.setBundleDataFilter('startDate', moment(e).format('yyyy-MM-DD')) }} /></span> : <button
                    style={{
                      margin: "0 10px",
                      background: "rgb(93, 93, 93)",
                      borderRadius: "5px",
                      font: "16px / 20px Graphik-Medium",
                      color: " rgb(255, 255, 255)",
                      textAlign: "center",
                      padding: "0 6px",
                      cursor: "pointer"
                    }}
                    className="selected-date-btn" onClick={() => this.setState({ startDateEnableBundle: !this.state.startDateEnableBundle })}>{this.state.audit_bundles_data.startDate != "" ? `${this.state.audit_bundles_data.startDate}` : 'Select Date'}</button>}
                  <span><button
                    style={{
                      margin: "0 10px",
                      background: "rgb(93, 93, 93)",
                      borderRadius: "5px",
                      font: "16px / 20px Graphik-Medium",
                      color: " rgb(255, 255, 255)",
                      textAlign: "center",
                      padding: "0 6px",
                      cursor: "pointer"
                    }}
                    className="selected-date-btn" onClick={() => this.setBundleDataFilter('startDate', '')}>Clear</button></span>
                </div>
                <div style={{ marginTop: '10px' }}>
                  <span> End Date : </span>
                  {this.state.endDateEnableBundle ? <span style={{ display: 'inline-block', position: 'absolute' }}> <Calendar onChange={(e) => { this.setBundleDataFilter('endDate', moment(e).format('yyyy-MM-DD')) }} /></span> : <button
                    style={{
                      margin: "0 10px",
                      background: "rgb(93, 93, 93)",
                      borderRadius: "5px",
                      font: "16px / 20px Graphik-Medium",
                      color: " rgb(255, 255, 255)",
                      textAlign: "center",
                      padding: "0 6px",
                      cursor: "pointer"
                    }}
                    className="selected-date-btn" onClick={() => this.setState({ endDateEnableBundle: !this.state.endDateEnableBundle })}>{this.state.audit_bundles_data.endDate != "" ? `${this.state.audit_bundles_data.endDate}` : 'Select Date'}</button>}
                  <span><button
                    style={{
                      margin: "0 10px",
                      marginBottom: "10px",
                      background: "rgb(93, 93, 93)",
                      borderRadius: "5px",
                      font: "16px / 20px Graphik-Medium",
                      color: " rgb(255, 255, 255)",
                      textAlign: "center",
                      padding: "0 6px",
                      cursor: "pointer"
                    }}
                    className="selected-date-btn" onClick={() => this.setBundleDataFilter('endDate', '')}>Clear</button></span>
                </div>
              </div>
            </div>
          )}
        </div>
        <section className="table-blk">
          <ul
            className="nav nav-tabs border-bottom-0"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item" role="presentation" key={"audit_calls"}>
              <button
                style={{
                  width: 140,
                }}
                className={"nav-link active"}
                // id={`${item["planName"].toLowerCase()}-tab`}
                data-bs-toggle="tab"
                data-bs-target={`#audit_calls`}
                type="button"
                role="tab"
                aria-controls={"audit_calls"}
                aria-selected="true"
                onClick={() => this.setState({ filter_type: 'call' })}
              >
                Calls
              </button>
            </li>
            <li className="nav-item" role="presentation" key={"audit_funds"}>
              <button
                style={{
                  width: 140,
                }}
                className={"nav-link "}
                // id={`${item["planName"].toLowerCase()}-tab`}
                data-bs-toggle="tab"
                data-bs-target={`#audit_funds`}
                type="button"
                role="tab"
                aria-controls={"audit_funds"}
                aria-selected="true"
                onClick={() => this.setState({ filter_type: 'funds' })}
              >
                Live funds
              </button>
            </li>
          </ul>

          <div className="tab-content" id="myTabContent">
            <div
              key={"audit_calls"}
              className={"tab-pane active usermanage"}
              id={"audit_calls"}
              role="tabpanel"
              aria-labelledby={`audit_calls-tab`}
            >
              <Datatable
                tableHeaders={[

                  { title: "call id", prop: "call_id", sortable: true },
                  {
                    title: "Created Date & Time",
                    prop: "entry_date",
                    cell: (row) => {
                      console.log(row.entry_date, "==== entry date")
                      return (
                        <>
                          <p>

                            {
                              row.entry_date == "" ? "" :
                                moment
                                  .utc(row.entry_date)
                                  .utcOffset("-00:00")
                                  .format(format1, "hh:mm A")}
                          </p>
                        </>
                      );
                    },
                  },
                  { title: "Class", prop: "type" },
                  { title: "Type", prop: "call_type" },
                  { title: "Scrip", prop: "name" },
                  { title: "Target", prop: "target" },
                  { title: "Status", prop: "status" },
                  {
                    title: "Call Close Type",
                    prop: "close_type",
                    cell: (call) => {
                      return (
                        <p>
                          {call.close_type === null ? "---" : call.close_type}
                        </p>
                      );
                    },
                  },
                  {
                    title: "Actions",
                    prop: "actions",
                    cell: (call) => {
                      return (
                        <>
                          <p>
                            {
                              call.entry_date == "" ? <div style={{ height: '20px' }}></div> :

                                <NavLink
                                  exact
                                  to={`auditcalldetails?id=${call.call_id}`}
                                  title="Details"
                                >
                                  Details
                                </NavLink>
                            }
                          </p>
                        </>
                      );
                    },
                  },
                ]}
                id="table-to-xls"
                tableBody={this.state.audit_calls?.data ? this.state.audit_calls.data?.filter(call => call.option_type != "Leg") : []}
                // rowsPerPage={this.state.audit_calls?.length}
                labels={{
                  filterPlaceholder: "Search",
                }}
              />
            </div>

            <div
              key={"audit_funds"}
              className={"tab-pane fade usermanage"}
              id={"audit_funds"}
              role="tabpanel"
              aria-labelledby={`audit_funds-tab`}
            >
              {
                this.state.bundle_calls?.length > 0 && <Datatable

                  tableHeaders={[

                    { title: "Bundle id", prop: "bundle_id", sortable: true },
                    {
                      title: "Created Date & Time",
                      prop: "createdAt",
                      cell: (row) => {
                        return (
                          <>
                            {
                              row?.createdAt ?
                                <p>
                                  {moment
                                    .utc(row.createdAt)
                                    .utcOffset("-00:00")
                                    .format(format1, "hh:mm A")}
                                </p> :
                                <></>
                            }
                          </>
                        );
                      },
                    },
                    { title: "title", prop: "title" },
                    { title: "Plan Id", prop: "planid" },
                    { title: "Status", prop: "status" },
                    { title: "Bundle Type", prop: "bundle_type" },
                    { title: "Returns", prop: "returns" },
                    {
                      title: "Actions",
                      prop: "actions",
                      cell: (bundle) => {
                        return (
                          <>
                            <div style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(`/bundles/details?id=${bundle.bundle_id}`)}>
                              <b>
                                Details
                              </b>
                            </div>
                          </>
                        );
                      },
                    },
                  ]}
                  id="table-to-xls"
                  tableBody={this.state?.bundle_calls || []}
                  // rowsPerPage={this.state.audit_calls?.length}
                  labels={{
                    filterPlaceholder: "Search",
                  }}
                />
              }


            </div>

          </div>
        </section>
      </section>
    );
  }
}

export default AuditCalls;

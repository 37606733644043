import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import * as API from "../../configuration/apiconfig";

export class updateAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      email: "",
      name: "",
      type: "",
      phone: "",
      showSuccess: false,
      showError: false,
      typesOfAdmin: [
        { value: "CMSUSER", label: "Super Admin" },
        { value: "CMSSALESADMINUSER", label: "Sales Admin" },
        { value: "CMSREASEARCHADMINUSER", label: "Research Admin" },
        { value: "CMSAUDITUSER", label: "Audit Admin" },
      ],
    };
  }

  getinput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  LoginGet = () => {
    let data = {
      name: this.state.name,
      email: this.state.email,
      phonenumber: this.state.phone,
      type: this.state.type,
    };
    API.callEndpoint(
      "PATCH",
      "Bearer",
      `/api/admin/update-admin?userid=${this.state.id}`,
      data
    )
      .then((response) => {
        try {
          this.setState({
            showSuccess: true,
          });
          setTimeout(() => {
            this.props.history.push(`/admin/list`);
          }, 1000);
        } catch (e) {
          this.setState({
            showError: true,
          });
          //  document.getElementById("wrongmess").style.display = "block";
        }
      })
      .catch((ex) => {
        // this.setState({ showLoader: false });
        this.setState({
          showError: true,
        });
        // document.getElementById("wrongmess").style.display = "block";
      });
  };

  componentDidMount = async () => {
    this.fetchcall();
  };

  fetchcall = async () => {
    let path = this.props.location.search;
    let id = parseInt(path.slice(path.indexOf("id=") + 3));
    this.setState({
      id: id,
    });
    return await API.callEndpoint("GET", "Bearer", `/api/admin?id=${id}`)
      .then((response) => {
        let fetchData = response.data[0];
        this.setState({
          name: fetchData.name,
          email: fetchData.email,
          phone: fetchData.phonenumber,
          type: fetchData.type,
        });
      })
      .catch((error) => {
        console.log(error.error);
      });
  };

  render() {
    // console.log(this.state)
    return (
      <section className="admin-bundleTable-page">
        <h1 className="head text-uppercase m-0">Update Account</h1>
        <section className="select-options-blk">
          <section className="row">
            <section className="col-lg-6 mb-20">
              <section className="forms-blk">
                <div className="head-1">Email</div>
                <div className="form-group">
                  <input
                    id="email"
                    type="text"
                    className="form-control"
                    name="email"
                    placeholder="Enter email"
                    autoComplete="off"
                    readOnly
                    onChange={(e) => this.getinput(e)}
                    // onChange={(e) =>
                    //   this.props.updateState("bundleTitle", e.target.value)
                    // }
                    value={this.state.email}
                  />
                  {/* <span className="b-editbtn">&nbsp;</span> */}
                </div>
              </section>
            </section>
            <section className="col-lg-6 mb-20">
              <section className="forms-blk">
                <div className="head-1">Name</div>
                <div className="form-group">
                  <input
                    id="name"
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="Enter name"
                    autoComplete="off"
                    onChange={(e) => this.getinput(e)}
                    // onChange={(e) =>
                    //   this.props.updateState("bundleTitle", e.target.value)
                    // }
                    value={this.state.name}
                  />
                  {/* <span className="b-editbtn">&nbsp;</span> */}
                </div>
              </section>
            </section>
            <section className="col-lg-6 mb-20">
              <section className="forms-blk">
                <div className="head-1">Phone Number</div>
                <div className="form-group">
                  <input
                    id="phone"
                    type="string"
                    className="form-control"
                    name="phone"
                    readOnly
                    // maxlength = "10"
                    // pattern="/^-?\d+\.?\d*$/"
                    onKeyPress={(e) => {
                      if (e.target.value.length >= 13) e.preventDefault();
                    }}
                    placeholder="Enter Phone number"
                    autoComplete="off"
                    onChange={(e) => this.getinput(e)}
                    // onChange={(e) =>
                    //   this.props.updateState("bundleTitle", e.target.value)
                    // }
                    value={this.state.phone}
                  />
                  {/* <span className="b-editbtn">&nbsp;</span> */}
                </div>
              </section>
            </section>

            <section className="col-lg-6 mb-20">
              <section className="forms-blk">
                <div className="head-1">Admin Type</div>
                <div className="form-group">
                  <Select
                    onChange={(e) =>
                      this.setState({
                        type: e.value,
                      })
                    }
                    options={this.state.typesOfAdmin}
                    placeholder="Select"
                    value={this.state.typesOfAdmin.filter(
                      (item) => item.value === this.state.type
                    )}
                  />
                </div>
              </section>
            </section>
          </section>
        </section>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Button
            disabled={
              !(
                this.state.email &&
                this.state.name &&
                this.state.phone &&
                this.state.type
              )
            }
            onClick={this.LoginGet}
          >
            Update
          </Button>
        </div>

        <div
          style={{ marginTop: "10px" }}
          className={`alert alert-success ${this.state.showSuccess ? "" : "d-none"
            }`}
          id="wrongmess"
        >
          Updated successfully.
        </div>
        <div
          style={{ marginTop: "10px" }}
          className={`alert alert-danger ${this.state.showError ? "" : "d-none"
            }`}
          id="wrongmess"
        >
          Something went wrong.
        </div>
      </section>
    );
  }
}

export default updateAdmin;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const options = [
  { value: null, label: "Select" },
  { value: 1, label: "FREE" },
  { value: 2, label: "Prime" },
  { value: 3, label: "Crown" },
  { value: 5, label: "2HR Trader" },
  { value: 6, label: "AOS" },
  { value: 7, label: "AOS + 2HR" },
  { value: 8, label: "AOS + 2HR + CROWN" },

];

const optionsMonth = [
  { value: null, label: "Select" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 18, label: "18" },
  { value: 24, label: "24" },
];

const UpdatePlanModal = (props) => {
  const [planid, setPlanid] = useState(null);
  const [month, setMonth] = useState(null);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={`modalblkk`}
    >
      <Modal.Body>
        <section className="row m-0 p-0 mt-4 justify-content-center addblk">
          <section className="col-lg-12">
            <section className="row m-0 p-0 justify-content-center">
              <section className="col-lg-8 formblk">
                <aside className="blk mt-3 w-100">
                  <label>Membership</label>
                  <Select
                    options={options}
                    onChange={(e) => setPlanid(e.value)}
                    classNamePrefix="addsele"
                  />
                </aside>
              </section>
              <section className="col-lg-8 formblk">
                <aside className="blk mt-3 w-100">
                  <label>Subscribed Month</label>
                  <Select
                    options={optionsMonth}
                    onChange={(e) => setMonth(e.value)}
                    classNamePrefix="addsele"
                  />
                </aside>
              </section>
            </section>
            <section className="row justify-content-center p-0 m-0 my-5">
              <section className="col-3">
                <button
                  className="btnsave"
                  onClick={async () => {
                    await props.updatePlan(planid, month);
                  }}
                >
                  Save
                </button>
              </section>
            </section>
          </section>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default UpdatePlanModal;

import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
// import './assets/css/style.scss';
import Navbar from "./components/navbar/navbar";
import Sidebar from "./components/sidebar/sidebar";
import Routing from "./components/router/router";
import * as API from "./configuration/apiconfig";
import Loginuser from "../src/components/loginPage/Loginuser";
import CreateAdmin from "../src/components/admin/create";
import ResetPage from "./components/resetPage/ResetPage";
import 'react-calendar/dist/Calendar.css';


function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: "",
      interval: () => null,
    };
  }

  logout = () => {
    this.setState({ accessToken: "" });
    document.cookie = "accessToken=" + "";
    document.cookie = "refreshToken=" + "";
    document.cookie = "role=" + "";
  };

  setAccessToken = (value) => {
    this.setState({ accessToken: value });
  };

  componentDidMount = () => {
    try {
      this.setState({ accessToken: API.getCookie("accessToken") });
    } catch (e) {
      this.setState({ accessToken: "" });
    }
    // API.getAccessToken("cmsuser@injin.com", "Test@123", true)
    //   .then((response) => {
    //     // console.log(response)
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });

    this.setState({
      interval: window.setInterval(() => {
        const token = getCookie("accessToken");

        if (!token) {
          this.setState({ accessToken: "" });
        }
      }, 5000),
    });
  };

  componentDidUpdate() {
    !this.accessToken && clearTimeout(this.interval);
  }

  render() {
    return (
      <React.Fragment>
        <Router>
          <section className="wrapper">
            <Navbar logout={this.logout} accessToken={this.state.accessToken} />
            {!getCookie("accessToken") ? (
              <Loginuser setAccessToken={this.setAccessToken} />
            ) : (
              // <CreateAdmin setAccessToken={this.setAccessToken} />
              <section className="admin-content-wrapper">
                <div className="row">
                  <div className="col-md-2">
                    <Sidebar />
                  </div>
                  <div className="col-md-10">
                    <div className="container">
                      <Routing />
                    </div>
                  </div>
                </div>
              </section>
            )}
          </section>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;

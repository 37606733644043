import * as API from '../../configuration/apiconfig';
import swal from 'sweetalert';
import moment from 'moment-timezone';

const format1 = 'YYYY-MM-DD HH:mm:ss';

//new
const fieldName = {
  scheduled_date: 'Publish',
  quantity: 'Quantity in Legs',
  entry_price_end: 'Max Price ',
  entry_price_start: 'Min Price ',
  call_target: 'Call Target',
  call_class: 'Call Class',
  call_type: 'Call Type',
  call_status: 'Call Status',
  scripname: 'Scrip Name',
  max_profit: 'Max Profit',
  max_loss: 'Max Loss',
  lot_size: 'Lot Size',
  expected_profit: 'Expected Profit',
  stop_loss: 'Stop Loss',
  stop_loss_type: 'Stop Loss Type',
  series: 'Series',
  iv_condition: 'IV Conditions',
  lot_size: 'Lot size',
  strategy: 'Strategy',
  capital_required: 'Capital Required',
  action: 'Action',
  strike_price: 'Strike Price',
  maximum_profit: 'Maximum Profit',
  capital_allocation: 'Capital Allocation',
};

//old
// function to create a new Call (Equity / Options)
// export async function createCall(data, setScheduleDisable) {
//   // console.log("----------------- API Create Call -------------------");
//   return await API.callEndpoint(
//     "POST",
//     "Bearer",
//     "/api/calls",
//     await formartData(data[0])
//   )
//     .then((response) => {
//       try {
//         // console.log(response);
//         setScheduleDisable(false);
//         return true;
//       } catch (e) {
//         // console.log(e);

//         swal({
//           title: "An error occured, try again!",
//           text: e.error,
//           icon: "info",
//         });
//         setScheduleDisable(false);
//         return false;
//       }
//     })
//     .catch((error) => {
//       console.log(error);
//       swal({
//         title: "An error occured, try again!",
//         text: error.error,
//         icon: "info",
//       });
//       setScheduleDisable(false);
//       return false;
//     });
// }

//new
export async function createCall(data, setScheduleDisable) {
  console.log('----------------- API Create Call -------------------');

  let data1 = await formartData(data[0]);
  let data2 = JSON.parse(JSON.stringify(data1));
  data2.now = data[0].now;

  if (checkIfAllFieldsArePresent(data2)) {
    setScheduleDisable(false);
    return false;
  } else {
    return await API.callEndpoint('POST', 'Bearer', '/api/calls', data1)
      .then((response) => {
        try {
          console.log(response);
          setScheduleDisable(false);
          return true;
        } catch (e) {
          console.log(e);
          swal({
            title: 'An error occured, try again!',
            text: e.error,
            icon: 'info',
          });
          setScheduleDisable(false);
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
        swal({
          title: 'An error occured, try again!',
          text: error.error,
          icon: 'info',
        });
        setScheduleDisable(false);
        return false;
      });
  }
}

//old
//function to update call
// export async function updateCall(data, id, setScheduleDisable) {
//   // console.log("----------------- API Update Call -------------------");
//   let Data = await formartData(data[0]);
//   Data["id"] = id;

//   return await API.callEndpoint("PATCH", "Bearer", "/api/calls", Data)
//     .then((response) => {
//       try {
//         // console.log(response);
//         setScheduleDisable(false);
//         return true;
//       } catch (e) {
//         console.log(e);

//         swal({
//           title: "An error occured, try again!",
//           text: e.error,
//           icon: "info",
//         });
//         setScheduleDisable(false);
//         return false;
//       }
//     })
//     .catch((error) => {
//       console.log(error);
//       swal({
//         title: "An error occured, try again!",
//         text: error.error,
//         icon: "info",
//       });
//       setScheduleDisable(false);
//       return false;
//     });
// }

//new
export async function updateCall(data, id, setScheduleDisable) {
  console.log('----------------- API Update Call -------------------', data);
  let Data = await formartData(data[0]);
  Data['id'] = id;

  let data1 = JSON.parse(JSON.stringify(Data));
  data1.now = data[0].now;

  if (checkIfAllFieldsArePresent(data1)) {
    setScheduleDisable(false);
    return false;
  } else {
    return await API.callEndpoint('PATCH', 'Bearer', '/api/calls', Data)
      .then((response) => {
        try {
          // console.log(response);
          setScheduleDisable(false);
          return true;
        } catch (e) {
          console.log(e);
          swal({
            title: 'An error occured, try again!',
            text: e.error,
            icon: 'info',
          });
          setScheduleDisable(false);
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
        swal({
          title: 'An error occured, try again!',
          text: error.error,
          icon: 'info',
        });
        setScheduleDisable(false);
        return false;
      });
  }
}

//old
//function to close call
// export async function closeCall(data) {
//   // console.log("----------------- API close Call -------------------");

//   return await API.callEndpoint("PATCH", "Bearer", "/api/calls", {
//     id: data.id,
//     publish: 2,
//   })
//     .then((response) => {
//       return API.callEndpoint("PUT", "Bearer", "/api/calls/closecall", data)
//         .then((response) => {
//           try {
//             // console.log(response);
//             return true;
//           } catch (e) {
//             console.log(e);

//             swal({
//               title: "An error occured, try again!",
//               text: e.error,
//               icon: "info",
//             });
//             return false;
//           }
//         })
//         .catch((error) => {
//           console.log(error);
//           swal({
//             title: "An error occured, try again!",
//             text: error.error,
//             icon: "info",
//           });
//           return false;
//         });
//     })
//     .catch((error) => {
//       console.log(error);
//       swal({
//         title: "An error occured, try again!",
//         text: error.error,
//         icon: "info",
//       });
//       return false;
//     });
// }

//new
export async function closeCall(data) {
  console.log('----------------- API close Call -------------------');

  return API.callEndpoint('PUT', 'Bearer', '/api/calls/closecall', data)
    .then((response) => {
      try {
        console.log(response);
        return true;
      } catch (e) {
        console.log(e);
        swal({
          title: 'An error occured, try again!',
          text: e.error,
          icon: 'info',
        });
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      swal({
        title: 'An error occured, try again!',
        text: error.error,
        icon: 'info',
      });
      return false;
    });
}

//this function is used to format the data in the desired format for backend to injust the data
let formartData = async (data) => {
  // console.log('some data, ', data)
  // console.log(data.seriesSelect);
  let call_target = data.experienced
    ? 'Experienced'
    : data.newbie
      ? 'Newbie'
      : 'Both';

  console.log("data", data);
  let outputData = {};
  if (data.option === 'Option') {
    //option
    let call_type = 'Neutral';
    if (data.optionsBulish !== '') {
      call_type = data.optionsBulish;
    } else if (data.optionsBearish !== '') {
      call_type = data.optionsBearish;
    } else if (data.optionsBidirectional !== '') {
      call_type = data.optionsBidirectional;
    }

    let trade_logics = [];
    trade_logics = data.optionTLPointArray[0].map((item, index) => {
      if (index === 0) {
        return {
          imageurl: item.img,
          smallImageurl: item.smallImageurl,
          notes: item.text,
        };
      }
    });

    let legs = [];
    legs = data.legsArray[0].map((item) => {
      return {
        action: item.legSelect,
        strike_price: `${item.enterStrikePrice}${item.legOption}`,
        entry_price_start: item.enterPrice1,
        entry_price_end: item.enterPrice2,
        quantity: item.entryQty,
        showCMP: item?.showCMP,
        // series: moment(item.series).format("Do MMM'YY"),
        series: moment(item.series, "Do MMM'YY").local().format("Do MMM'YY"),
        exit_price: item.exitPrice === undefined ? '' : item.exitPrice,
      };
    });
    console.log(data, 'data check');
    outputData = {
      call_target: call_target,
      call_class: 'Options',
      call_type: call_type,
      call_status: data.callStatus,
      enter_status: data.enter_status,
      payoffcharturl: data?.payoffcharturl,
      show_legs: data.show_legs,
      scripname: data.optionCompanyName,
      scripname_price: await getPrice(data.optionCompanySymbol),
      scripname_symbols: data.optionCompanySymbol,

      info: {
        legs: legs,

        //old
        // max_profit: parseInt(data.parMaxProfit) || 0,
        // max_loss: parseInt(data.parMaxLoss) || 0,
        // lot_size: parseInt(data.parLotSize) || 0,
        // expected_profit: parseInt(data.parExpectedProfit) || 0,
        // stop_loss: parseInt(data.parStopLoss) || 0,

        //new
        max_profit: data.parMaxProfit,
        max_loss: data.parMaxLoss,
        lot_size: data.parLotSize,
        expected_profit: data.parExpectedProfit,
        stop_loss: data.parStopLoss,
        stop_loss_type: data.parSelectValue,
      },
      series: data.seriesSelect,
      iv_condition: data.conditionsSelect,
      //old
      // lot_size: parseInt(data.page2LotSize) || 0,

      //new and updated
      lot_size: parseInt(data.parLotSize) || 0,
      trade_logics:
        trade_logics[0] === null || trade_logics[0] === undefined
          ? []
          : [trade_logics[0]],
      planid: data.optionPlan,
      publish: 1,
      scheduled_date:
        data.optionSchedule === ''
          ? ''
          : //old
          // : moment(data.optionSchedule)
          //     .utcOffset("-00:00")
          //     .format("YYYY-MM-DD hh:mm:ss"),

          //new
          moment(data.optionSchedule).format('YYYY-MM-DD HH:mm:ss'),
      notes: data.optionNotes,
      strategy: data.optionsSelect,
      //old
      // capital_required: parseInt(data.parCapitalRequied) || 0,

      //new
      capital_required: data.parCapitalRequied,
    };
  } else {
    //equity
    let call_type = data.long === 'Long' ? 'Long' : 'Short';
    let targets = data.target[0].map((item) => {
      return { value: item };
    });
    let trade_logics = [];

    trade_logics = data.equityTLPointArray[0].map((item, index) => {
      if (index === 0) {
        return {
          imageurl: item.img,
          smallImageurl: item.smallImageurl,
          notes: item.text,
        };
      }
    });
    outputData = {
      call_target: call_target,
      call_class: 'Equity',
      call_status: data.callStatus,
      enter_status: data.enter_status,
      payoffcharturl: data?.payoffcharturl,
      show_legs: data.show_legs,
      call_type: call_type,
      scripname: data.whichscripName,
      scripname_price: await getPrice(data.whichScripSymbol),
      scripname_symbols: data.whichScripSymbol,
      info: {
        entry_price_start: data.entryPrice,
        entry_price_end: data.entryPriceQuantity,
        //old
        // exit_price: data.exitPrice,

        //new
        exit_price: data.exitPrice === undefined ? '' : data.exitPrice,
        quanity: data.exitPriceQuantity,
        stop_loss: data.stopLoss,
        stop_loss_type: 'Value',
        max_loss: data.maxLoss,
        maximum_profit: data.maxProfit,
        targets: targets,
      },
      capital_required: data.capitalRequd,
      trade_logics:
        trade_logics[0] === null || trade_logics[0] === undefined
          ? []
          : [trade_logics[0]],
      capital_allocation: data.capitalAllocation,
      planid: data.equityPlan,
      publish: 1,
      scheduled_date:
        data.schedule === ''
          ? ''
          : // old

          // : moment(data.schedule)
          //     .utcOffset("-00:00")
          //     .format("YYYY-MM-DD hh:mm:ss"),

          // new
          moment(data.schedule).format('YYYY-MM-DD HH:mm:ss'),

      notes: data.equityNotes,
    };
  }

  // console.log(outputData);
  return outputData;
};

export let getPrice = async (symbol) => {
  return await API.callEndpoint('GET', 'Bearer', `/api/quote?symbols=${symbol}`)
    .then((response) => {
      try {
        return response.data[0].regularMarketPrice;
      } catch (e) {
        console.log(e);
        return 0;
      }
    })
    .catch((error) => {
      console.log(error);
      return 0;
    });
};

let checkIfAllFieldsArePresent = (data) => {
  console.log(data);
  for (const prop in data) {
    if (prop === 'info') {
      for (const prop1 in data[prop]) {
        if (prop1 === 'legs') {
          if (checkLegs(data[prop]['legs'], data)) {
            return true;
          }
        } else if (isEmpty(data[prop][prop1], prop1, data)) {
          return true;
        }
      }
    } else if (isEmpty(data[prop], prop, data)) {
      return true;
    }
  }
  return false;
};

let isEmpty = (field, property, data) => {
  console.log(field, property);
  if (
    property === 'scheduled_date' ||
    property === 'now' ||
    property === 'notes' ||
    property === 'exit_price' ||
    property === 'scripname_price' ||
    property === 'trade_logics'
  ) {
    return false;
  } else if (property === 'strike_price') {
    let strike_price = field.substring(0, field.length - 2);
    let strike_price_option = field.slice(-2);
    console.log(strike_price, strike_price_option);
    if (strike_price === '') {
      swal({
        text: `Strike Price field cannot be empty`,
        icon: 'error',
      });
      return true;
    } else if (strike_price_option === '') {
      swal({
        text: `Strike Price Option field cannot be empty`,
        icon: 'error',
      });
      return true;
    }
  } else if (field === undefined || field === null || field === '') {
    swal({
      text: `${fieldName[property] === undefined ? property : fieldName[property]
        } field cannot be empty`,
      icon: 'error',
    });
    return true;
  }
  return false;
};

let checkLegs = (legs, data) => {
  for (let i = 0; i < legs.length; i++) {
    for (const prop2 in legs[i]) {
      if (isEmpty(legs[i][prop2], prop2, data)) {
        return true;
      }
    }
  }
  return false;
};

export async function createIntradayCall(data) {
  let data1 = await formartData(data[0]);

  API.callEndpoint('POST', 'Bearer', 'api/intraday/createIntraday', data1)
    .then((response) => {
      try {
        console.log(response, 'create');
        console.log('new call added');
        return true;
      } catch (e) {
        console.log(e);
        swal({
          title: 'An error occured, try again!',
          text: e.error,
          icon: 'info',
        });

        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      swal({
        title: 'An error occured, try again!',
        text: error.error,
        icon: 'info',
      });

      return false;
    });
}

import React, { useState } from "react";
import Datatable from "react-bs-datatable";
import Moment from "moment";
import moment from "moment-timezone";


const header = [
  { title: "Event Topic", prop: "EventTopic" },
  { title: "Organiser", prop: "Organiser" },
  { title: "Created Date", prop: "CreatedDate",
  cell: (row) => {
    return (
      <>
        <p>
          {moment(row.CreatedDate)
           .utcOffset("-00:00")
           .format("DD MMM'YY | h:mm A")}
        </p>
      </>
    );
  }  },
  { title: "Date of Event", prop: "DateEvent", 
  cell: (row) => {
    return (
      <>
        <p>
          {moment(row.DateEvent)
           .utcOffset("-00:00")
           .format("DD MMM'YY | h:mm A")}
        </p>
      </>
    );
  } },
  
  { title: "Event Link", prop: "EventLink",
  cell: (row) => {
    return (
      <>
        <a href={row.EventLink} target="_blank">
          {row.EventLink}
        </a>
      </>
    );
  }  },
  { title: "Action", prop: "Action" },
];

const body = [
  {
    EventTopic: "Basic of Options Trading",
    Organiser: "shameer",
    DateEvent: "31.05.2021",
    CreatedDate: "31.05.2021",
    EventLink: "https://Zoom.in",
    Action: (
      <>
        <a href="javascript:;">Modify</a> | <a href="javascript:;"> Remove</a>
      </>
    ),
  },
  {
    EventTopic: "Features of Trading",
    Organiser: "Prateek",
    DateEvent: "29.05.2021",
    CreatedDate: "29.05.2021",
    EventLink: "https://Zoom.in",
    Action: (
      <>
        <a href="javascript:;">Modify</a> | <a href="javascript:;"> Remove</a>
      </>
    ),
  },
  {
    EventTopic: "Cons of Trading",
    Organiser: "Lionel",
    DateEvent: "26.05.2021",
    CreatedDate: "26.05.2021",
    EventLink: "https://Zoom.in",
    Action: (
      <>
        <a href="javascript:;">Modify</a> | <a href="javascript:;"> Remove</a>
      </>
    ),
  },
];

const Eventable = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  var bodyArr = [];
  props.state.newEventList?.map((item, index) => {
    let newObj = new Object();
    let evtdtt = Moment(item.evtdt).format("Do MMM YYYY");
    newObj.EventTopic = item.name;
    newObj.Organiser = item.organiser;
    newObj.DateEvent = item.event_date_time;
    newObj.CreatedDate = item.createdAt;
    newObj.EventLink = item.link;
    newObj.Action = (
      <>
        {/* <a href="javascript:;">Modify</a> |  */}
        <a href="javascript:;" onClick={()=>props.handleDeleteEvent(item.id)}> Remove</a>
      </>
    );
    bodyArr.push(newObj);
  });
  return (
    <div>
      <section className={`row m-0 p-0 mt-5`}>
        <aside className={`col-12 m-0 p-0`}>
          <span className="display-rows-placement">
            Display rows :{" "}
            <select
              className="display-select"
              onChange={(e) => {
                if( bodyArr.length===0){
                  setRowsPerPage(1);
                }
                else if (e.target.value === "All" ) {
                  setRowsPerPage(bodyArr.length);
                } else {
                  setRowsPerPage(e.target.value);
                }
              }}
            >
              {[5, 10, 15, 20, "All"].map((item) => {
                return <option value={item}>{item}</option>;
              })}
            </select>
          </span>
          <Datatable
            tableHeaders={header}
            id="table-to-xls"
            tableBody={bodyArr}
            rowsPerPage={rowsPerPage}
            labels={{ filterPlaceholder: "Search" }}
          />
        </aside>
      </section>
    </div>
  );
};

export default Eventable;

import React, { useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";

const Livevidcomp = (props) => {
  let membership = "";
  if (props.fakeobj.length > 0 && props.fakeobj[0] !== undefined) {
    if (props.fakeobj[0].membership !== null) {
      membership = { value: "", label: "" };
      membership.value = props.fakeobj[0].membership;
      membership.label = props.fakeobj[0].membership;
    } else {
      membership = { value: "", label: "" };
      membership.value = "";
      membership.label = "";
    }
  }

  const [membType, setmembType] = useState(membership);
  const [countdown, setcountdown] = useState(new Date());
  const [endDateTime, endDateTimedown] = useState(new Date());
  const [evtDate, setevtDate] = useState(new Date());
  const [evtTime, setevtTime] = useState(new Date());
  const [videoType, setVideoType] = useState('');


  const memberOnChange = (membType) => {
    setmembType(membType);
    props.memberOnChange(membType);
  };

  const videoTypeChange = type => {
    setVideoType(type);
    props.videoTypeOnChange(type)
}; 

  const stCountdown = (date) => {
    setcountdown(date);
    // props.setDatefun(date,'countdown');
    props.setDatefun(date, "bannerStartDate");
  };

  const endDateTimeSelect = (date) => {
    endDateTimedown(date);
    props.setDatefun(date, "bannerEndDate");
  };

  const stevtDt = (date) => {
    setevtDate(date);
    props.setDatefun(date, "evtdt");
  };
  const stevtTime = (date) => {
    console.log(date);
    setevtTime(date);
    props.setDatefun(date, "evttime");
  };
  const VideoThumb = ({ vdeo }) => {
    return (
      <video
        width="100%"
        height="100%"
        autoPlay={true}
        muted={true}
        loop={true}
        className={`vidresponive`}
      >
        <source
          src={URL.createObjectURL(vdeo)}
          type="video/mp4"
          alt={vdeo.name}
          className={`img-responsive`}
        />
      </video>
    );
  };
  const ImageThumb = ({ image }) => {
    if (typeof image === "string") {
      return <img src={image} alt={image.name} className={`img-responsive`} />;
    } else {
      return (
        <img
          src={URL.createObjectURL(image)}
          alt={image.name}
          className={`img-responsive`}
        />
      );
    }
  };
  return (
    <>
      <section className="row m-0 p-0 mt-4 justify-content-center addblk">
        <section className="col-lg-12">
          {/* <section className="dndblk text-center position-relative rounded-0 mb-3">
                    <label className="my-4 text-center position-absolute">Drag and drop a file here or click browse</label>
                    <input type="file" onChange={props.handlevidupload} name="myVid" placeholder="Browse" accept="image/x-png,image/gif,image/jpeg" id="vidfile"/>
                    <button className={`btnbrwose`}>Browse</button>
                    {props.stateinfo.fileinfo && <VideoThumb vdeo={props.stateinfo.fileinfo}/>}
                </section> */}
          <section className="row m-0 p-0 justify-content-center">
            <section className="col-lg-12 formblk">
              <section className="thumbnail-blk">
                <h4 className="mb-0 mt-4">Thumbnail Image</h4>
                <aside className="blk mt-2 w-100 dndblk text-center position-relative rounded-0">
                  <label className="my-4 text-center position-absolute">
                    Drag and drop a file here or click browse
                  </label>
                  <input
                    type="file"
                    onChange={(e) => props.handleimgupload(e)}
                    name="myImage"
                    placeholder="Browse"
                    accept="image/x-png,image/gif,image/jpeg"
                    id="imgefile"
                  />
                  <button className={`btnbrwose`}>Browse</button>
                  {props.stateinfo.imginfo && (
                    <ImageThumb image={props.stateinfo.imginfo} />
                  )}
                </aside>
                <p className="text-center img-size">
                  <i>Recommended image size - 90 x 90</i>
                </p>
              </section>
              <section className="blk w-100">
                                <label>Type</label>
                                <Select value={videoType} onChange={videoTypeChange} options={props.videoType} classNamePrefix="addsele" />
                            </section>
              {/* <aside className="blk mt-3 w-100">
                            <label>Name</label><input type="text" name="title" placeholder="" className="inpfield" onChange={(e)=>props.inputchange(e)}/>
                        </aside> */}
              <aside className="blk mt-3 w-100">
                <label>Topic</label>
                <input
                  type="text"
                  name="topic"
                  placeholder=""
                  className="inpfield"
                  autoComplete="off"
                  onChange={(e) => props.inputchange(e)}
                  value={props.fakeobj[0].topic}
                />
              </aside>
              <aside className="blk mt-3 w-100">
                <label>Description</label>
                <input
                  type="text"
                  name="description"
                  placeholder=""
                  className="inpfield"
                  autoComplete="off"
                  onChange={(e) => props.inputchange(e)}
                  value={props.fakeobj[0].description}
                />
              </aside>
              <aside className="blk mt-3 w-100">
                <label>Video URL</label>
                <input
                  type="text"
                  name="videourl"
                  placeholder=""
                  className="inpfield"
                  autoComplete="off"
                  onChange={(e) => props.inputchange(e)}
                  value={props.fakeobj[0].videourl}
                />
              </aside>
              <aside className="blk mt-3 w-100">
                <label>Organiser</label>
                <input
                  type="text"
                  name="organiser"
                  placeholder=""
                  className="inpfield"
                  autoComplete="off"
                  onChange={(e) => props.inputchange(e)}
                  value={props.fakeobj[0].organiser}
                />
              </aside>
              {videoType?.value !== "vimeo" && videoType !== "" &&
              <section className="blk mt-3 w-100">
                            <label>Password</label><input type="text" name="password" placeholder="" className="inpfield" autoComplete="off" onChange={(e)=>props.inputchange(e)}/>
                        </section>}
              {/* <aside className="blk mt-3 w-100">
                            <label>Membership</label>
                            <Select
                                value={membType}
                                onChange={memberOnChange}
                                options={props.addmembertype}
                                placeholder='Membership'
                                classNamePrefix="addsele"
                            />
                        </aside> */}
              <aside className="blk mt-3 w-100">
                <label>Category</label>
                <Select
                  value={
                    JSON.stringify(membType) ===
                      JSON.stringify({ value: undefined, label: undefined }) ||
                    JSON.stringify(membType) ===
                      JSON.stringify({ value: "", label: "" })
                      ? ""
                      : membType
                  }
                  onChange={memberOnChange}
                  options={props.addmembertype}
                  placeholder="Category"
                  classNamePrefix="addsele"
                />
              </aside>
              <section className="row m-0 p-0 mt-4 event-blk">
                <aside className="col-lg-6 pe-3 ps-0">
                  <h4>Event Date</h4>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Event Date"
                    selected={
                      props.fakeobj[0].evtdt === ""
                        ? new Date()
                        : new Date(props.fakeobj[0].evtdt)
                    }
                    onChange={(date) => stevtDt(date)}
                    className="rounded-0 mt-3 dtblk"
                    id="evtdate"
                  />
                </aside>
                <aside className="col-lg-6 pe-0 ps-0">
                  <h4>Event Time</h4>
                  <DatePicker
                    placeholderText="Event Time"
                    //selected={props.fakeobj[0].evttime}
                    selected={
                      props.fakeobj[0].evttime === ""
                        ? new Date()
                        : moment(props.fakeobj[0].evttime, "HH:mm:ss").toDate()
                    }
                    onChange={(date) => stevtTime(date)}
                    className="rounded-0 mt-3 dtblk"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                  />
                </aside>
                {/* <aside className="col-lg-4 d-flex flex-row justify-content-center align-items-end" style={{paddingBottom:'6px'}}>
                                <input type="checkbox" name="delchat" id="delchat" style={{marginBottom:'6px',marginRight:'5px',appearance:'auto'}}  onChange={(e)=>props.checkchange(e)}/>
                                <label htmlFor="delchat">Disable Chat <i>&nbsp;</i></label>     
                            </aside> */}
              </section>
              {/* <section className="row m-0 p-0 mt-4" style={{pointerEvents:'none !important'}}>
                            <aside className="col-lg-6 pe-5 ps-0">
                                <h4>Count Down Timer From</h4>
                                <DatePicker placeholderText="Select Date/Time" selected={countdown} onChange={date =>stCountdown(date)} timeInputLabel="Time:" className="rounded-0 mt-3 dtblk" showTimeInput dateFormat="dd/MM/yyyy h:mm aa"/>
                            </aside>
                        </section> */}
              {/* <h4 className="mb-0 mt-4">Thumbnail Image</h4>
                        <aside className="blk mt-2 w-100 dndblk text-center position-relative rounded-0">
                            <label className="my-4 text-center position-absolute">Drag and drop a file here or click browse</label>
                            <input type="file" onChange={props.handleimgupload} name="myImage" placeholder="Browse" accept="image/x-png,image/gif,image/jpeg" id="imgefile"/>
                            <button className={`btnbrwose`}>Browse</button>
                            {props.stateinfo.imginfo && <ImageThumb image={props.stateinfo.imginfo}/>}
                        </aside> */}
              <section
                className="row m-0 p-0 mt-4"
                style={{ pointerEvents: "none !important" }}
              >
                <aside className="col-lg-6 pe-3 ps-0">
                  <h4>Banner Start date/time</h4>
                  <DatePicker
                    placeholderText="Select Date/Time"
                    selected={
                      props.fakeobj[0].bannerStartDate === ""
                        ? new Date()
                        : new Date(props.fakeobj[0].bannerStartDate)
                    }
                    onChange={(date) => stCountdown(date)}
                    timeInputLabel="Time:"
                    className="rounded-0 mt-3 dtblk"
                    showTimeInput
                    dateFormat="dd/MM/yyyy h:mm aa"
                  />
                </aside>
                <aside className="col-lg-6 pe-0 ps-0">
                  <h4>Banner End date/time</h4>
                  <DatePicker
                    placeholderText="Select Date/Time"
                    selected={
                      props.fakeobj[0].bannerEndDate === ""
                        ? new Date()
                        : new Date(props.fakeobj[0].bannerEndDate)
                    }
                    onChange={(date) => endDateTimeSelect(date)}
                    timeInputLabel="Time:"
                    className="rounded-0 mt-3 dtblk"
                    showTimeInput
                    dateFormat="dd/MM/yyyy h:mm aa"
                  />
                </aside>
              </section>
            </section>
          </section>
          <section className="row justify-content-center p-0 m-0 my-3 pt-5">
            <section className="col-3">
              {/* <button className="btnsave" onClick={props.lividsave}>Save</button> */}
              {/* <button className={`btnsave ${props.liveVideoSaveDisabled() ? 'disabled':''}`} onClick={props.lividsave}>Save</button> */}
              {props.stateinfo.liveEditSaveBtnShow ? (
                <button
                  className={`btnsave-video ${
                    props.liveVideoSaveDisabled() ? "disabled" : ""
                  }`}
                  onClick={props.liveEditSaveBtn}
                >
                  Save
                </button>
              ) : (
                <button
                  className={`btnsave-video ${
                    props.liveVideoSaveDisabled() ? "disabled" : ""
                  }`}
                  onClick={props.lividsave}
                >
                  Save
                </button>
              )}
            </section>
          </section>
        </section>
      </section>
    </>
  );
};

export default Livevidcomp;

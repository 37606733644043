import React from "react";
import logo from "../../assets/images/logo.svg";
import { IoMdLogOut } from "react-icons/io";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";

class Navbar extends React.Component {
  render() {
    return (
      <section className="admin-header">
        <nav className="navbar pt-3 pb-3">
          <div className="container-fluid">
            <div className="col-md-2 col-sm-6">
              {/* <a className="navbar-brand" href="#"><img src={logo} alt="logo" className="img-fluid" /></a> */}
              <NavLink exact to={"/"} className="navbar-brand">
                <img src={logo} alt="logo" className="img-fluid" />
              </NavLink>
            </div>
            <div className="col-md-8 col-sm-6">
              <h4 className="text-center text-uppercase text-white mb-0">
                {/* nse live feed ticker */}
              </h4>
            </div>
            {this.props.accessToken === null ||
            this.props.accessToken === undefined ||
            this.props.accessToken === "" ? (
              <div className="col-md-2 col-sm-6 logout-btn"></div>
            ) : (
              <div
                className="col-md-2 col-sm-6 logout-btn"
                onClick={this.props.logout}
              >
                Logout <IoMdLogOut size="23" />
              </div>
            )}
          </div>
        </nav>
      </section>
    );
  }
}

export default Navbar;

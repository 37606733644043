import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Modifymodal = (props) => {
  const [memType, setmemType] = useState("");
  const [catType, setcatType] = useState("");
  const [liveHedgedType, liveHedgedsetcatType] = useState("");
  const [fromDate, setfromDate] = useState(new Date());
  const [toDate, settoDate] = useState(new Date());
  const [countdown, setcountdown] = useState(new Date());
  const [evtDate, setevtDate] = useState(new Date());
  const [evtTime, setevtTime] = useState(new Date());
  const [videoType, setVideoType] = useState('');


  const memberOnChange = (memType) => {
    setmemType(memType);
    props.memberOnChange(memType);
  };
  const categoryOnChange = (catType) => {
    setcatType(catType);
    props.categoryOnChange(catType);
  };

  const liveHedgedcategoryOnChange = (liveHedgedType) => {
    liveHedgedsetcatType(liveHedgedType);
    props.liveHedgedcategoryOnChange(liveHedgedType);
  };

  const stfrmDt = (date) => {
    setfromDate(date);
    props.setDatefun(date, "publishfrom");
  };
  const sttoDt = (date) => {
    settoDate(date);
    props.setDatefun(date, "publishto");
  };
  const stCountdown = (date) => {
    setcountdown(date);
    props.setDatefun(date, "countdown");
  };
  const stevtDt = (date) => {
    setevtDate(date);
    props.setDatefun(date, "evtdt");
  };
  const stevtTime = (date) => {
    setevtTime(date);
    props.setDatefun(date, "evttime");
  };
  const VideoThumb = ({ vdeo }) => {
    return (
      <video
        width="100%"
        height="100%"
        autoPlay={true}
        muted={true}
        loop={true}
        className={`vidresponive`}
      >
        <source
          src={URL.createObjectURL(vdeo)}
          type="video/mp4"
          alt={vdeo.name}
          className={`img-responsive`}
        />
      </video>
    );
  };
  const ImageThumb = ({ image }) => {
    return (
      <img
        src={URL.createObjectURL(image)}
        alt={image.name}
        className={`img-responsive`}
      />
    );
  };
  // console.log(props.state.fakeobj[0]);

  const videoTypeChange = type => {
    setVideoType(type);
    props.videoTypeOnChange(type)
}; 
  return (
    <React.Fragment>
      {props.state.fakeobj[0] !== undefined ? (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className={`modalblkk`}
        >
          <Modal.Body>
            <section className="row m-0 p-0 mt-4 justify-content-center addblk">
              <section className="col-lg-12">
                <section className="row m-0 p-0 justify-content-center">
                  <section className="col-lg-12 formblk">
                  <section className="blk w-100">
                                <label>Type</label>
                                <Select value={
                            props.state.fakeobj == ""
                              ? ""
                              : {
                                  value: props.state.fakeobj[0].source,
                                  label: props.state.fakeobj[0].source,
                                }
                          } onChange={videoTypeChange} options={props.videoType} classNamePrefix="addsele" />
                            </section>
                    <section className="blk mt-3 w-100">
                      <label>Topic</label>
                      <input
                        type="text"
                        name="title"
                        placeholder=""
                        className="inpfield"
                        autoComplete="off"
                        onChange={(e) => props.inputchange(e)}
                        value={
                          props.state.fakeobj == ""
                            ? ""
                            : props.state.fakeobj[0].title
                        }
                      />
                    </section>
                    <section className="blk mt-3 w-100">
                      <label>Description</label>
                      <input
                        type="text"
                        name="description"
                        placeholder=""
                        autoComplete="off"
                        className="inpfield"
                        onChange={(e) => props.inputchange(e)}
                        value={
                          props.state.fakeobj == ""
                            ? ""
                            : props.state.fakeobj[0].description
                        }
                      />
                    </section>
                    <section className="blk mt-3 w-100">
                      <label>Video URL</label>
                      <input
                        type="text"
                        name="videourl"
                        placeholder=""
                        autoComplete="off"
                        className="inpfield"
                        onChange={(e) => props.inputchange(e)}
                        value={
                          props.state.fakeobj == ""
                            ? ""
                            : props.state.fakeobj[0].videourl
                        }
                      />
                    </section>
                    <section className="mt-3">
                      <section className="blk w-100">
                        <label>Category</label>
                        {/* <Select value={catType} onChange={categoryOnChange} options={props.categorytype} classNamePrefix="addsele" /> */}
                        <Select
                          value={
                            props.state.fakeobj == ""
                              ? ""
                              : {
                                  value: props.state.fakeobj[0].category,
                                  label: props.state.fakeobj[0].category,
                                }
                          }
                          onChange={categoryOnChange}
                          options={props.categorytype}
                          classNamePrefix="addsele"
                        />
                      </section>
                    </section>
                    {props.state.fakeobj !== "" && props.state.fakeobj[0].source !== "vimeo" &&
                    <section className="blk mt-3 w-100">
                            <label>Password</label><input type="text"   value={
                          props.state.fakeobj == ""
                            ? ""
                            : props.state.fakeobj[0].password
                        } name="password" placeholder="" className="inpfield" autoComplete="off" onChange={(e)=>props.inputchange(e)}/>
                        </section>}
                    <section className="mt-3">
                      <section
                        className={`blk w-100 ${
                          props.state.vidmodal.iscatmem ? "d-block" : "d-none"
                        }`}
                      >
                        <label>Hedged Category</label>
                        {/* <Select value={liveHedgedType} onChange={liveHedgedcategoryOnChange} options={props.liveFromHedgedSelected} classNamePrefix="addsele" /> */}
                        <Select
                          onChange={liveHedgedcategoryOnChange}
                          options={props.liveFromHedgedSelected}
                          classNamePrefix="addsele"
                        />
                      </section>
                    </section>
                    <section className="mt-3 publish-on-off">
                      <input
                        type="checkbox"
                        name="publish"
                        id="publish"
                        onChange={(e) => props.publishOnOff(e)}
                        checked={
                          props.state.fakeobj == ""
                            ? ""
                            : props.state.fakeobj[0].publish
                        }
                      />
                      <label htmlFor="publish">Publish</label>
                    </section>
                    {props.state.fakeobj[0].publish ? (
                      <section className="mt-3 featured-blk">
                        <input
                          type="checkbox"
                          name="featured"
                          id="featured"
                          onChange={(e) => props.featuredOnOff(e)}
                          checked={
                            props.state.fakeobj == ""
                              ? ""
                              : props.state.fakeobj[0].featured
                          }
                        />
                        <label htmlFor="featured">
                          Featured<span>&nbsp;</span>
                        </label>
                      </section>
                    ) : null}
                  </section>
                </section>
                <section className="row justify-content-center p-0 m-0 my-3 pt-5 newvideo-saveBtn">
                  <section className="col-3">
                    {/* <button className="btnsave" onClick={props.addvidsave}>Save</button> */}
                    <button
                      className={`btnsave ${
                        props.newVideoSaveDisabled() ? "disabled" : ""
                      }`}
                      onClick={props.addModifyVidsave}
                    >
                      Save
                    </button>
                  </section>
                </section>
              </section>
            </section>
          </Modal.Body>
        </Modal>
      ) : null}
    </React.Fragment>
  );

  // return (
  //   <Modal
  //     {...props}
  //     size="lg"
  //     aria-labelledby="contained-modal-title-vcenter"
  //     centered
  //     className={`modalblkk`}
  //   >
  //     <Modal.Body>
  //       {props.info.length > 0 && (
  //         <section className="row m-0 p-0 mt-4 justify-content-center addblk">
  //           <section className="col-lg-12">
  //             <section className="dndblk text-center position-relative rounded-0 mb-3">
  //               <label className="my-4 text-center position-absolute">
  //                 Drag and drop a file here or click browse
  //               </label>
  //               <input
  //                 type="file"
  //                 onChange={props.handlevidupload}
  //                 name="myVideo"
  //                 placeholder="Browse"
  //                 accept="video/mp4,video/x-m4v,video/*"
  //                 id="vidfile"
  //               />
  //               <button className={`btnbrwose`}>Browse</button>
  //               {props.info[0].videourl && (
  //                 <VideoThumb vdeo={props.info[0].videourl} />
  //               )}
  //             </section>
  //             <section className="row m-0 p-0 justify-content-center">
  //               <section className="col-lg-8 formblk">
  //                 {props.info[0].title !== "" && (
  //                   <section className="blk mt-3 w-100">
  //                     <label>Name</label>
  //                     <input
  //                       type="text"
  //                       name="title"
  //                       placeholder={props.info[0].title}
  //                       className="inpfield"
  //                       onChange={(e) => props.inputchange(e)}
  //                     />
  //                   </section>
  //                 )}
  //                 {props.info[0].description !== "" && (
  //                   <section className="blk mt-3 w-100">
  //                     <label>Description</label>
  //                     <input
  //                       type="text"
  //                       name="description"
  //                       placeholder={props.info[0].description}
  //                       className="inpfield"
  //                       onChange={(e) => props.inputchange(e)}
  //                     />
  //                   </section>
  //                 )}
  //                 {props.info[0].organiser !== "" && (
  //                   <section className="blk mt-3 w-100">
  //                     <label>Organiser</label>
  //                     <input
  //                       type="text"
  //                       name="organiser"
  //                       placeholder=""
  //                       className="inpfield"
  //                       onChange={(e) => props.inputchange(e)}
  //                     />
  //                   </section>
  //                 )}
  //                 <section className="mt-3">
  //                   {props.info[0].category !== "" &&
  //                     props.info[0].category !== "LiveVideo" && (
  //                       <section className="blk w-100">
  //                         <label>Category</label>
  //                         <Select
  //                           value={catType}
  //                           onChange={categoryOnChange}
  //                           options={props.categorytype}
  //                           classNamePrefix="addsele"
  //                         />
  //                       </section>
  //                     )}
  //                   {(props.info[0].category === "Membership" ||
  //                     props.info[0].category === "LiveVideo") && (
  //                     <section className="blk mt-3 w-100">
  //                       <label>Membership</label>
  //                       <Select
  //                         value={memType}
  //                         onChange={memberOnChange}
  //                         options={props.addmembertype}
  //                         placeholder="Membership"
  //                         classNamePrefix="addsele"
  //                       />
  //                     </section>
  //                   )}
  //                 </section>
  //                 {props.info[0].category !== "LiveVideo" && (
  //                   <section
  //                     className="row m-0 p-0 mt-4"
  //                     style={{
  //                       display: "display:inline-block",
  //                       position: "relative",
  //                     }}
  //                   >
  //                     {props.info[0].publishfrom !== "" && (
  //                       <section className="col-lg-6 pe-5 ps-0">
  //                         <h4>Publish From</h4>
  //                         <DatePicker
  //                           placeholderText="Select Date/Time"
  //                           selected={fromDate}
  //                           onChange={(date) => stfrmDt(date)}
  //                           className="rounded-0 mt-3 dtblk"
  //                           timeInputLabel="Time:"
  //                           showTimeInput
  //                           dateFormat="dd/MM/yyyy h:mm aa"
  //                         />
  //                       </section>
  //                     )}
  //                     {props.info[0].publishto !== "" && (
  //                       <section className="col-lg-6 pe-0 ps-5">
  //                         <h4>Publish To</h4>
  //                         <DatePicker
  //                           placeholderText="Select Date/Time"
  //                           selected={toDate}
  //                           onChange={(date) => sttoDt(date)}
  //                           className="rounded-0 mt-3 dtblk"
  //                           timeInputLabel="Time:"
  //                           showTimeInput
  //                           dateFormat="dd/MM/yyyy h:mm aa"
  //                         />
  //                       </section>
  //                     )}
  //                   </section>
  //                 )}
  //                 {props.info[0].category === "LiveVideo" && (
  //                   <section className="row m-0 p-0 mt-4">
  //                     {props.info[0].evtdt !== "" && (
  //                       <aside className="col-lg-4 pe-3 ps-0">
  //                         <h4>Event Date</h4>
  //                         <DatePicker
  //                           placeholderText="Select Date"
  //                           selected={evtDate}
  //                           onChange={(date) => stevtDt(date)}
  //                           className="rounded-0 mt-3 dtblk"
  //                           id="evtdate"
  //                         />
  //                       </aside>
  //                     )}
  //                     {props.info[0].evttime !== "" && (
  //                       <aside className="col-lg-4 pe-3 ps-0">
  //                         <h4>Event Time</h4>
  //                         <DatePicker
  //                           placeholderText="Select Time"
  //                           selected={evtTime}
  //                           onChange={(date) => stevtTime(date)}
  //                           className="rounded-0 mt-3 dtblk"
  //                           showTimeSelect
  //                           timeIntervals={15}
  //                           timeCaption="Time"
  //                           dateFormat="h:mm aa"
  //                         />
  //                       </aside>
  //                     )}
  //                     <aside
  //                       className="col-lg-4 d-flex flex-row justify-content-center align-items-end"
  //                       style={{ paddingBottom: "6px" }}
  //                     >
  //                       <input
  //                         type="checkbox"
  //                         name="delchat"
  //                         id="delchat"
  //                         style={{
  //                           marginBottom: "6px",
  //                           marginRight: "5px",
  //                           appearance: "auto",
  //                         }}
  //                         onChange={(e) => props.checkchange(e)}
  //                       />
  //                       <label htmlFor="delchat">
  //                         Disable Chat <i>&nbsp;</i>
  //                       </label>
  //                     </aside>
  //                   </section>
  //                 )}
  //                 {props.info[0].category === "LiveVideo" && (
  //                   <section
  //                     className="row m-0 p-0 mt-4"
  //                     style={{ pointerEvents: "none !important" }}
  //                   >
  //                     {props.info[0].countdown !== "" && (
  //                       <aside className="col-lg-6 pe-5 ps-0">
  //                         <h4>Count Down Timer From</h4>
  //                         <DatePicker
  //                           placeholderText="Select Date/Time"
  //                           selected={countdown}
  //                           onChange={(date) => stCountdown(date)}
  //                           timeInputLabel="Time:"
  //                           className="rounded-0 mt-3 dtblk"
  //                           showTimeInput
  //                           dateFormat="dd/MM/yyyy h:mm aa"
  //                         />
  //                       </aside>
  //                     )}
  //                   </section>
  //                 )}
  //                 {props.info[0].category === "LiveVideo" &&
  //                   props.info[0].thumbimg !== "" && (
  //                     <>
  //                       <h4 className="mb-0 mt-4">Thumbnail Image</h4>
  //                       <aside className="blk mt-2 w-100 dndblk text-center position-relative rounded-0">
  //                         <label className="my-4 text-center position-absolute">
  //                           Drag and drop a file here or click browse
  //                         </label>
  //                         <input
  //                           type="file"
  //                           onChange={props.handleimgupload}
  //                           name="myImage"
  //                           placeholder="Browse"
  //                           accept="image/x-png,image/gif,image/jpeg"
  //                           id="imgefile"
  //                         />
  //                         <button className={`btnbrwose`}>Browse</button>
  //                         {props.info[0].thumbimg && (
  //                           <ImageThumb image={props.info[0].thumbimg} />
  //                         )}
  //                       </aside>
  //                     </>
  //                   )}
  //               </section>
  //             </section>
  //             <section className="row justify-content-center p-0 m-0 my-5">
  //               <section className="col-3">
  //                 <button className="btnsave" onClick={props.addvidsave}>
  //                   Save
  //                 </button>
  //               </section>
  //             </section>
  //           </section>
  //         </section>
  //       )}
  //     </Modal.Body>
  //   </Modal>
  // );
};

export default Modifymodal;

import React, { useState } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as API from '../../configuration/apiconfig';
import { FaRegEdit } from 'react-icons/fa';
import moment from 'moment';

const renameFile = (originalFile, newName) => {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
};

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
// const select1 = [
//   { value: "series 1", label: "Series 1" },
//   { value: "series 2", label: "Series 2" },
//   { value: "series 3", label: "Series 3" },
// ];

const select2 = [
  { value: 'Very Expensive', label: 'Very Expensive' },
  { value: 'Expensive', label: 'Expensive' },
  { value: 'Fair', label: 'Fair' },
  { value: 'Cheap', label: 'Cheap' },
  { value: 'Very Cheap', label: 'Very Cheap' },
  { value: 'IV independent Strategy', label: 'IV independent Strategy' },
];

const getStartDate = () => {
  let startDate = new Date();
  if (new Date().getHours() === 15 && new Date().getMinutes() > 30) {
    startDate.setDate(startDate.getDate() + 1);
  } else if (new Date().getHours() > 15) {
    startDate.setDate(startDate.getDate() + 1);
  }
  return startDate;
};

function DatePickerFunComp(props) {
  const [startDate, setStartDate] = useState(props.getStartDate);
  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => {
    return (
      <section className="custom-datepicker">
        <button className="example-custom-input" onClick={onClick} ref={ref}>
          Schedule
        </button>
        {props.optionSchedule === '' ? null : <strong>{value}</strong>}
      </section>
    );
  });

  return (
    <DatePicker
      selected={startDate}
      minDate={getStartDate()}
      onChange={(date) => {
        setStartDate(date);
        props.optionToggleClass('optionSchedule', new Date(date));
      }}
      timeInputLabel="Time:"
      dateFormat="MM/dd/yyyy  h:mm aa"
      showTimeInput
      disabledKeyboardNavigation
      placeholderText="This has disabled keyboard navigation"
      customInput={<ExampleCustomInput />}
    />
  );
}

class SystemBulishSaveProceedRight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuIsOpen: true,
      startDate: new Date(),
      setStartDate: new Date(),
      tradeImageURL: '',
      tradeErrorMsg: '',
      tradeSuccessMsg: '',
      plans: [],
      submit: true,
      seriesMsg: '',
      uploadStatus: false,
      disableBTn: true,
      imageValues: {
        loaded: false,
        url: '',
        type: '',
        name: '',
        source: '',
      },
    };
  }

  uploadFile = async (e) => {
    try {
      let file = e.target.files[0];

      const fileFormat = file.type?.split('/')[1];
      const queryObj = {
        fileFormat,
        type: 'tradelogics',
      };

      await API.callEndpoint(
        'GET',
        'Bearer',
        `/api/files/presigned?fileFormat=${queryObj.fileFormat}&type=${queryObj.type}`
      )
        .then((response) => {
          const { key, url } = response.data.data;
          const fileName = `${key}.${fileFormat}`;
          const renamedFile = renameFile(file, fileName);

          const myHeaders = new Headers({ 'Content-Type': 'image/*' });
          fetch(url, {
            method: 'PUT',
            headers: myHeaders,
            body: renamedFile,
          })
            .then(() => {
              API.callEndpoint(
                'GET',
                'Bearer',
                `/api/files/presigned-url?fileName=${fileName}&type=${queryObj.type}`
              )
                .then((response) => {
                  this.setState({
                    tradeSuccessMsg: 'Uploaded!',
                    tradeErrorMsg: '',
                    tradeImageURL: response.data?.data?.split('?X-')[0],
                  });
                })
                .catch((error) => {
                  console.log(error.error);
                  this.setState({
                    tradeErrorMsg: error.error,
                    tradeSuccessMsg: '',
                  });
                });
            })
            .catch((error) => {
              console.log(error.error);
              this.setState({
                tradeErrorMsg: error.error,
                tradeSuccessMsg: '',
              });
            });
        })
        .catch((error) => {
          console.log(error.error);
          this.setState({ tradeErrorMsg: error.error, tradeSuccessMsg: '' });
        });
    } catch (e) {
      console.log(e);
      this.setState({ tradeErrorMsg: e.error, tradeSuccessMsg: '' });
    }
  };

  componentDidMount = async () => {
    await API.callEndpoint(
      'GET',
      'Bearer',
      '/api/plans?include=id&include=planName'
    )
      .then((response) => {
        this.setState({ plans: response.data });
      })
      .catch((error) => {
        console.log(error.error);
      });
    this.setState({
      submit: this.props.state.optionTLPointArrayNew.length === 0,
    });

    let legs = this.props.state.legsArray;
    let seriesMsg = moment(legs[0]['series']).format("Do MMM'YY");
    try {
      legs.map((item) => {
        if (moment(item.series).format("Do MMM'YY") !== seriesMsg) {
          seriesMsg = 'Multiple';
        }

        // if (
        //   new Date(
        //     moment(item.series, "Do MMM'YY").local().format("Do MMM'YY")
        //   ) !== seriesMsg
        // ) {
        //   seriesMsg = "Multiple";
        // }
      });
    } catch (e) {
      console.log(e);
      seriesMsg = 'No Legs Added';
    }
    this.setState({ seriesMsg });
    this.props.select1OnChange(seriesMsg);
    if (this?.props?.state?.payoffcharturl) {
      this.setState({
        imageValues: {
          ...this.state.imageValues,
          loaded: true,
          url: this?.props?.state?.payoffcharturl,
        },
      });
    }
  };
  onImageChange = async (e) => {
    if (e?.target?.files && e?.target?.files[0]) {
      this.setState(
        {
          disableBTn: false,
        }
      )
     this.props.updatePayOffChart()
      
      let img = e?.target?.files[0];
      let type = e?.target?.files[0]?.type;
      var srcss = await getBase64(e.target.files[0]);
      // console.log(srcss, "srcss");
      if (type == 'image/jpeg' || type == 'image/png' || type == 'image/jpg') {
        this.setState({
          imageValues: {
            loaded: true,
            source: e?.target?.files[0],
            url: srcss,
          },
        });
      } else {
        this.setState(
          {
            disableBTn: true
          }
        )
        // setChangeImageError("Please Select Jpeg/Png/Jpg files only");
      }
    }
  };

  uploadPayOffChart = async (e) => {
    this.setState({
      uploadStatus: 2,
    });
    try {
      let file = this.state.imageValues.source;

      const fileFormat = file.type?.split('/')[1];
      const queryObj = {
        fileFormat,
        type: 'payoffchart',
      };

      await API.callEndpoint(
        'GET',
        'Bearer',
        `/api/files/presigned?fileFormat=${queryObj.fileFormat}&type=${queryObj.type}`
      )
        .then((response) => {
          const { key, url } = response.data.data;
          const fileName = `${key}.${fileFormat}`;
          const renamedFile = renameFile(file, fileName);

          const myHeaders = new Headers({ 'Content-Type': 'image/*' });
          fetch(url, {
            method: 'PUT',
            headers: myHeaders,
            body: renamedFile,
          })
            .then(() => {
              API.callEndpoint(
                'GET',
                'Bearer',
                `/api/files/presigned-url?fileName=${fileName}&type=${queryObj.type}`
              )
                .then((response) => {
                  var raw = {
                    url: response.data?.data?.split('?X-')[0],
                  };
                  // API.callEndpoint(
                  //   'POST',
                  //   'Bearer',
                  //   `/api/road-block/update-image`,
                  //   raw
                  // )
                  //   .then((response) => {
                  //     // this.setState({
                  //     //   uploadStatus: 2,
                  //     // });
                  //     // swal({
                  //     //   text: "Updated Success",
                  //     //   icon: "success",
                  //     // });
                  //   })
                  //   .catch((error) => {
                  //     console.log(error.error);
                  //     // swal({
                  //     //   text: "An error occured, try again!",
                  //     //   icon: "info",
                  //     // });
                  //   });
                  this.setState({
                    uploadStatus: 3,
                    // });
                    // this.setState({
                    successMsg: 'Uploaded!',
                    errorMsg: '',
                    payOffChart: raw.url,
                  });
                  // console.log(this.state, 'permanently delete');
                  this.props.onPayChartEdit(raw.url);
                })
            })
            .catch((error) => {
              console.log(error.error);
              this.setState({
                uploadStatus: 3,
                errorMsg: error.error,
              });
            });
        })

        .catch((error) => {
          console.log(error.error);
          this.setState({ tradeErrorMsg: error.error, tradeSuccessMsg: '' });
        })
    } catch (e) {
      console.log(e);
      this.setState({ tradeErrorMsg: e.error, tradeSuccessMsg: '' });
    }
  };
  getbtnText(code) {
    let btntext
    switch (code) {
      case 1:
        btntext = "Upload";
        break;
      case 2:
        btntext = "Uploading...";
        break;
      case 3:
        btntext = "Uploaded";
        break;
      default:
        btntext = "Upload";
    }
    return btntext;
  }
  render() {
    return (
      <section className="admin-bulishSaveProceedRight">
        <section className="select-blk">
          <section className="row">
            <aside className="lot-size-blk col-lg-4">
              <div className="form-group">
                <label>Series</label>
                <input
                  className="form-control"
                  disabled={true}
                  value={this.state.seriesMsg}
                //menuIsOpen={this.state.menuIsOpen}
                />
              </div>
            </aside>
            <aside className="select-rgt col-lg-4">
              <h2>IV Conditions</h2>
              <Select
                value={select2.filter(
                  (item) => item.value === this.props.state.conditionsSelect
                )}
                onChange={this.props.select2OnChange}
                options={select2}
                placeholder="Select"
              />
            </aside>
            <aside className="lot-size-blk col-lg-4">
              <div className="form-group">
                <label>Lot Size</label>
                <input
                  // value={this.props.state.page2LotSize}
                  value={this.props.state.parLotSize}
                  type="text"
                  className="field-disabled"
                  autoComplete="off"
                  onChange={this.props.optionsPage2LotSize}
                  disabled
                />
              </div>
            </aside>
          </section>
        </section>

        {/* <section className="list-blk">
          <h2>For</h2>
          {this.state.plans.map((item) => (
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id={item.id}
                onChange={(e) => {
                  if (!this.props.state.modifyHeader) {
                    this.props.optionForCheckedFields(e, item.id);
                  }
                }}
                checked={this.props.state.optionPlan === item.id ? true : false}
              />
              <label className="form-check-label" htmlFor={item.id}>
                {item.planName}
              </label>
            </div>
          ))}
         
        </section> */}
        <section className="list-blk">
          <h2>Pay-off Chart</h2>
          <input
            onChange={(e) => this.onImageChange(e)}
            type="file"
            accept="image/png, image/gif, image/jpeg"
          />
          <img
            style={{
              width: '100%',
            }}
            src={this.state?.imageValues?.url}
          />
          <br />
          <button
            onClick={() => {
              this.uploadPayOffChart();
            }}
            style={{
              marginTop: 10,
            }}
            className="btn btn-secondary"
            disabled={this?.props?.state?.payoffcharturl ? true : false}
          >
            {/* {this.getbtnText(this.state?.uploadStatus)} */}
            {this?.props?.state?.payoffcharturl ? "Uploaded" : this.getbtnText(this.state?.uploadStatus) }
            {/* == 0
              ? 'upload'
              : this?.state?.uploadStatus == 1
                ? 'uploading'
                : 'uploaded'} */}
          </button>
        </section>
        <section className="list-blk">
          <h2>Publish</h2>
          <ul className="list-group list-group-horizontal">
            <li
              className={`list-group-item border-0 px-2 py-0 ${this.props.state.optionNow ? 'active' : ''
                }`}
              onClick={() => this.props.optionToggleClass('optionNow', true)}
            >
              <span className="d-block text-center">Now</span>
            </li>
            <li
              className={`list-group-item border-0 px-2 py-0 ${this.props.state.optionSchedule !== '' ? 'active' : ''
                }`}
              onClick={() => this.props.resetOptionNow()}
            >
              <DatePickerFunComp
                optionToggleClass={this.props.optionToggleClass}
                getStartDate={
                  this.props.state.optionSchedule !== ''
                    ? this.props.state.optionSchedule
                    : getStartDate()
                }
                optionSchedule={this.props.state.optionSchedule}
              />
            </li>
          </ul>
        </section>
        <section className="list-blk">
          <div className="form-group">
            <div className="TradeLogicMargin">
              <label>Trade Logic</label>
              {/* {console.log("bullish reached")} */}
              {!this.state.submit ? (
                <FaRegEdit
                  className="equityEdit"
                  onClick={() => {
                    let obj = this.props.optionTradeLogicEdit()[0];
                    this.setState({ submit: true, tradeImageURL: obj.img });
                    let e = {
                      target: {
                        value: obj.text,
                      },
                    };
                    this.props.tradeNoteOnchange(e, 'optionTradeLogic');
                  }}
                />
              ) : null}
            </div>
            <section className="accordion-blk">
              <div className="accordion" id="accordionExample">
                {this.props.state.optionTLPointArray.map((point, index) => {
                  if (index <= this.props.state.optionTLPointArrayLength) {
                    return (
                      <div className="accordion-item" key={index}>
                        <h2
                          className="accordion-header"
                          id={`heading_${index + 1}`}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse_${index + 1}`}
                            aria-expanded="false"
                            aria-controls={`collapse_${index + 1}`}
                          >
                            <span>{point.text}</span>
                          </button>
                        </h2>
                        <div
                          id={`collapse_${index + 1}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`heading_${index + 1}`}
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <img
                              src={point.img?.split('?X-')[0]}
                              className="img-fluid"
                            />
                            <p
                              style={{
                                whiteSpace: 'pre-line',
                              }}
                            >
                              {point.text}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
              {/* <aside className="add-trade-logic">
                  <p>Add new trade logic</p>
                </aside> */}
            </section>
            {this.state.submit ? (
              <>
                <section className="file-upload-blk">
                  <label for="file">Upload image</label>
                  <span className="upload-image-recommend1">{`(600px X 300px | Size < 1MB recommended)`}</span>
                  <input
                    type="file"
                    name="image"
                    id="file"
                    onChange={this.uploadFile}
                    accept="image/x-png,image/gif,image/jpeg"
                  />
                </section>
                {this.state.tradeSuccessMsg === 'Uploaded!' ? (
                  <div className="trade-success-msg">
                    {this.state.tradeSuccessMsg}
                  </div>
                ) : (
                  <div className="trade-error-msg">
                    {this.state.tradeErrorMsg}
                  </div>
                )}
                <textarea
                  value={this.props.state.optionTradeLogic}
                  id="trade-note"
                  className="form-control"
                  onChange={(e) => {
                    this.props.tradeNoteOnchange(e, 'optionTradeLogic');
                  }}
                ></textarea>
                <aside className="submit-btn">
                  <button
                    type="button"
                    id="submit-Btn"
                    className={`btn btn-secondary ${this.props.submitDisabled() ? 'disabled' : ''
                      }`}
                    onClick={() => {
                      this.props.optionTradeLogicSubmit(
                        this.state.tradeImageURL,
                        this.state.tradeSmallImageURL
                      );
                      this.setState({ submit: false });
                    }}
                  >
                    Submit
                  </button>
                </aside>
              </>
            ) : null}
          </div>
          <div className="form-group">
            <label>Notes</label>
            <textarea
              value={this.props.state.optionNotes}
              rows={12}
              className="form-control"
              onChange={(e) => this.props.tradeNoteOnchange(e, 'optionNotes')}
            ></textarea>
          </div>
        </section>
      </section>
    );
  }
}

export default SystemBulishSaveProceedRight;

import React from "react";
import Form from 'react-bootstrap/Form';
class SystemRightComponent extends React.Component {
  componentDidMount() {
    document.getElementById("entry-price").focus();
  }

  render() {
    return (
      <section className="newcall-rgt-blk">
        <section className="list-blk">
          <h2>Entry &amp; Exit</h2>
          <section className="form-blk">
            <div className="form-group p-2 w-50">
              <div className="call-field-header"> Entry Price Start </div>
              <input
                type="text"
                id="entry-price"
                className="form-control"
                name="entryPrice"
                placeholder="Entry Price Start"
                autoComplete="off"
                onChange={this.props.handleChange}
                value={this.props.state.entryPrice}
              />
            </div>
            <div className="form-group p-2 w-50">
              <div className="call-field-header">Entry Price End </div>
              <input
                type="text"
                className="form-control"
                name="entryPriceQuantity"
                placeholder="Entry Price End"
                autoComplete="off"
                onChange={this.props.handleChange}
                value={this.props.state.entryPriceQuantity}
              />
            </div>
            <div className="form-group p-2 w-50">
              <div className="call-field-header"> Target Price </div>
              <input
                type="text"
                className="form-control"
                name="exitPrice"
                placeholder="Target Price"
                autoComplete="off"
                onChange={this.props.handleChange}
                value={this.props.state.exitPrice}
              />
            </div>
            <div className="form-group p-2 w-50">
              <div className="call-field-header"> Quantity </div>
              <input
                type="text"
                className="form-control"
                name="exitPriceQuantity"
                placeholder="Quantity"
                autoComplete="off"
                onChange={this.props.handleChange}
                value={this.props.state.exitPriceQuantity}
              />
            </div>
            <div className="form-group p-2 w-100">
              <div className="call-field-header"> Stop Loss</div>
              <input
                type="text"
                className="form-control"
                name="stopLoss"
                placeholder="Stop Loss"
                autoComplete="off"
                onChange={this.props.handleChange}
                value={this.props.state.stopLoss}
              />
            </div>
            <div className="form-group p-2 w-50">
              <div className="call-field-header"> Capital Required </div>
              <input
                type="text"
                className="form-control"
                name="capitalRequd"
                placeholder="Capital Required"
                autoComplete="off"
                onChange={this.props.handleChange}
                value={this.props.state.capitalRequd}
              />
            </div>
            <div className="form-group p-2 w-50">
              <div className="call-field-header">Max Profit </div>
              <input
                type="text"
                className="form-control"
                name="maxProfit"
                placeholder="Max Profit"
                autoComplete="off"
                onChange={this.props.handleChange}
                value={this.props.state.maxProfit}
              />
            </div>
            <div className="form-group p-2 w-50">
              <div className="call-field-header">Max Loss </div>
              <input
                type="text"
                className="form-control"
                name="maxLoss"
                placeholder="Max Loss"
                autoComplete="off"
                onChange={this.props.handleChange}
                value={this.props.state.maxLoss}
              />
            </div>
            <h2 className="ps-2 mt-3 ">Targets</h2>
            {this.props.state.target.map((target, index) => {
              return (
                <div className="form-group p-2 w-100" key={index}>
                  <input
                    type="text"
                    className="form-control"
                    value={target}
                    name={`target${index}`}
                    placeholder={`Target ${index + 1}`}
                    autoComplete="off"
                    onChange={(e) => this.props.inputOnchange(e, index)}
                  />
                  <aside
                    className="target-delete-btn"
                    onClick={() => this.props.targetDeleteBtn(index)}
                  >
                    <span>&nbsp;</span>
                  </aside>
                </div>
              );
            })}
            <aside
              className={`plus-btn ${this.props.state.istarget === true ? "avail" : ""
                }`}
              onClick={(e) => this.props.TargetField(e)}
            >
              <span>&nbsp;</span>
            </aside>
          </section>
          <Form.Check
            onChange={(event) => {
              this.props.updateNotify(event.target.checked);
              console.log(event.target.checked, 'check value')
            }}
            label="Notify User"
            name="group1"
            type={"checkbox"}
            checked={this.props.state.notify}
            id={`reverse-checkbox-1`}
            className="notifycheck"
          />
        </section>
      </section>
    );
  }
}

export default SystemRightComponent;

import React from "react";
import * as API from "../../configuration/apiconfig";
import Artworkr from "../../assets/images/artwork-1.png";
import Influx from "../../assets/images/logobk.png";
import { ReactComponent as Loader } from "../../assets/images/loader.svg";

class ResetPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: "",
      password: "",
      showError: false,
      showLoader: false,
    };
  }

  LoginGet = () => {
    this.setState({
      showError: false,
      showLoader: true,
    });
    API.getAccessToken(this.state.Username, this.state.password, true)
      .then((response) => {
        try {
          this.setState({ showLoader: false });
          this.props.setAccessToken(response.data.accessToken);
        } catch (e) {
          this.setState({
            showError: true,
          });
         document.getElementById("wrongmess").style.display = "block";
        }
      })
      .catch((ex) => {
        this.setState({ showLoader: false });
        this.setState({
          showError: true,
        });
        document.getElementById("wrongmess").style.display = "block";
      });
  };

  getinput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleKeypress = (e) => {
    if (e.key === "Enter") {
      this.LoginGet();
    }
  };

  render() {
    return (
      <section className="innerpage" onKeyDown={this.handleKeypress}>
        <div
          className={`customLoader ${
            this.state.showLoader == true ? "show d-block" : "d-none"
          }`}
        >
          <Loader className="loaderIcon"></Loader>
        </div>
        <section className="loginpage position-relative">
          <div className="main-login main-login--fullscreen">
            <div className="main-login-header">
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-5 mobile-responsive-login">
                  <div className="main-login-block">
                    <div className="main-login-form">
                      <div className="main-login-header">
                        <span className="main-login-header-first">Reset Password</span>
                      </div>
                      <div className="login-form">
                        <div class="form-group">
                          <label for="username">Username</label>
                          <input
                            type="text"
                            class="form-control"
                            id="username"
                            name="Username"
                            onChange={(e) => this.getinput(e)}
                          />
                        </div>
                        <div class="form-group">
                          <label for="password">Password</label>
                          <input
                            type="password"
                            class="form-control"
                            id="password"
                            name="password"
                            onChange={(e) => this.getinput(e)}
                          />
                        </div>
                        {/* <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>
                              <label class="form-check-label" for="defaultCheck1">Keep me signed in</label>
                            </div> */}
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                <button
                                  type="button"
                                  class="btn btn-primary"
                                  disabled={
                                    this.state.Username.length != 0 &&
                                    this.state.password.length != 0
                                      ? false
                                      : true
                                  }
                                  onClick={this.LoginGet}
                                >
                                  Login
                                </button>
                                <p style={{ cursor:'pointer', color:'#f67452'}}>
                                  Forget password ?
                                </p>
                            </div>
                      </div>
                      <div
                        className={`alert alert-danger ${
                          this.state.showError ? "" : "d-none"
                        }`}
                        id="wrongmess"
                      >
                        Invalid credentials. Please try again.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mobile-responsive">
                  <div className="main-login__header__left">
                    <img src={Artworkr} alt="Clean UI Admin Template" />
                  </div>
                </div>
              </div>
            </div>
            <span className="footerlogo">
              Powered by <img src={Influx} alt="Influx" />
            </span>
          </div>
        </section>
      </section>
    );
  }
}

export default ResetPage;

import React from "react";
import Chart from "react-apexcharts";
import * as API from "../../configuration/apiconfig";
import moment from "moment";
import Datatable from "react-bs-datatable";
import swal from "sweetalert";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { easeQuadInOut, easeLinear } from "d3-ease";
import AnimatedProgressProvider from "../hedgeometer/AnimatedProgressProvider";
//import ChangingProgressProvider from "../hedgeometer/ChangingProgressProvider";

const dateTimeFormat1 = "DD/MM/YYYY hh:mm a";

class Hedgeometer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: -1,
      activeId: -1,
      hedgeometerArray: [],
      hedgeometerCurrentStatus: [],
      hedgeometerHistory: [],
      chartShow: false,
      statusGetName: "",
      statusGetValue: "",
      popupdescription: "",
      statusIndex: 0,
      rowsPerPage: 5,
      header: [
        { title: "Status", prop: "status" },
        {
          title: "Start Date & Time",
          prop: "starttime",
          cell: (row) => {
            return (
              <>
                <p>
                  {moment(row.starttime)
                    .utcOffset("-00:00")
                    .format("DD MMM'YY | h:mm A")}
                </p>
              </>
            );
          },
        },
        {
          title: "End Date & Time",
          prop: "endtime",
          cell: (row) => {
            return (
              <>
                <p>
                  {moment(row.endtime)
                    .utcOffset("-00:00")
                    .format("DD MMM'YY | h:mm A")}
                </p>
              </>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    //Get Hedgeometer Current Status.
    this.getValues();
    this.getCurrentStatus();
    this.getHistory();
  }

  getCurrentStatus = async () => {
    await API.callEndpoint("GET", "Bearer", "/api/hedgeometer?active=1")
      .then(async (response) => {
        var currentStatusData = response.data;
        var currentStatusValue = currentStatusData[0].value.toString();
        var locationIndex = this.state.hedgeometerArray?.findIndex(
          (x) => x.value === currentStatusValue
        );
        // if (locationIndex != -1) {
        //this.statusUpdatedChart(locationIndex);
        this.setState(
          {
            activeIndex: locationIndex,
            hedgeometerCurrentStatus: currentStatusData,
          },
          () => {
            // console.log(this.state.hedgeometerCurrentStatus);
          }
        );
        // } else {
        //   console.log("Hedeometer: Current Status match not available.");
        // }
      })
      .catch((error) => {
        console.log(error.error);
        swal({
          text: "An error occured, try again!",
          icon: "info",
        });
      });
  };

  getHistory = async () => {
    await API.callEndpoint("GET", "Bearer", "/api/hedgeometer")
      .then(async (response) => {
        // if (locationIndex != -1) {
        //this.statusUpdatedChart(locationIndex);
        this.setState({
          hedgeometerHistory: response.data,
        });
        // } else {
        //   console.log("Hedeometer: Current Status match not available.");
        // }
      })
      .catch((error) => {
        console.log(error.error);
        swal({
          text: "An error occured, try again!",
          icon: "info",
        });
      });
  };
  getValues = async () => {
    await API.callEndpoint("GET", "Bearer", "/api/hedgeometervalues")
      .then(async (response) => {
        // console.log('values ', response.data)
        this.setState({
          hedgeometerArray: response.data,
        });
      })
      .catch((error) => {
        console.log(error.error);
        swal({
          text: "An error occured, try again!",
          icon: "info",
        });
      });
  };

  updateCurrentStatus = (
    dataStatus = this.state.statusGetName,
    dataValue = this.state.statusGetValue,
    currentIndex = this.state.statusIndex
  ) => {
    // console.log(dataStatus, dataValue, currentIndex, this.state.activeIndex);
    //  if (this.state.activeIndex !== currentIndex) {
    //console.log(dataStatus);
    //console.log(dataValue);
    var dataObject = new Object();
    // dataObject.id = this.state.hedgeometerCurrentStatus[0]?.id;
    dataObject.status = dataStatus;
    dataObject.value = dataValue.toString();
    dataObject.starttime = new Date();
    dataObject.description = this.state.popupdescription;
    // console.log(dataObject);
    //return false;
    API.callEndpoint("POST", "Bearer", "/api/hedgeometer/add-value", dataObject)
      .then(async (response) => {
        try {
          // console.log(response);
          this.getCurrentStatus();
          this.getHistory();
          // this.statusUpdatedChart()
        } catch (e) {
          console.log(e.error);
          swal({
            text: "An error occured, try again!",
            icon: "info",
          });
        }
      })
      .catch((error) => {
        console.log(error.error);
        swal({
          text: "An error occured, try again!",
          icon: "info",
        });
      });
    //}
  };
  updateValues = () => {
    var dataObject = new Object();
    dataObject.id = this.state.activeId;
    dataObject.status = this.state.statusGetName;
    dataObject.description = this.state.popupdescription;
    //return false;
    API.callEndpoint("PATCH", "Bearer", "/api/hedgeometervalues", dataObject)
      .then(async (response) => {
        try {
          // console.log('patched', response);
          this.getValues();
          this.getCurrentStatus();
          this.getHistory();
          this.updateCurrentStatus();
        } catch (e) {
          console.log(e.error);
          swal({
            text: "An error occured, try again!",
            icon: "info",
          });
        }
      })
      .catch((error) => {
        console.log(error.error);
        swal({
          text: "An error occured, try again!",
          icon: "info",
        });
      });
    //}
  };

  // Right side > chart
  statusUpdatedChart = (index) => {
    const selectedHedged = this.state.hedgeometerCurrentStatus?.filter(
      (item) => item.active === true
    );
    let status = selectedHedged[0].status;
    let value = selectedHedged[0].value;
    const getIndexTitle = status;
    const getIndexValue = value;
    this.setState({
      activeIndex: index,
      chartShow: true,
      chartSeries: [getIndexValue],
      chartOptions: {
        chart: {
          height: 330,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              // value: {
              //   formatter: function(val) {
              //     return parseInt(val);
              //   },
              // }
            },
            hollow: {
              size: "60%",
            },
          },
        },
        labels: [getIndexTitle],
      },
    });
    // console.log(getIndexTitle, getIndexValue);
  };

  // Popup name input > onchange function
  // getNameOnchange = (event) => {
  //   if(this.state.statusGetValue===''){
  //     this.setState({
  //       [event.target.name]: event.target.value
  //     })
  //     console.log('if');
  //   }else if(this.state.statusGetValue !== ''){
  //     this.setState({
  //       [event.target.name]: event.target.value,
  //       popupOptions: {
  //         chart: {
  //           height: 300,
  //           type: 'radialBar',
  //         },
  //         plotOptions: {
  //           radialBar: {
  //             dataLabels: {
  //               value: {
  //                 formatter: function(val) {
  //                   return parseInt(val);
  //                 },
  //               }
  //             },
  //             hollow: {
  //               size: '60%',
  //             }
  //           },
  //         },
  //         labels: [event.target.value],
  //       }
  //     })
  //     console.log('else if');
  //   }
  // }

  getNameOnchange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  // Popup value input > onchange function
  getIVSChart = (event) => {
    const getValue = event.target.value;
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({
        [event.target.name]: getValue,
        popupSeries: [getValue],
        popupOptions: {
          chart: {
            height: 300,
            type: "radialBar",
          },
          plotOptions: {
            radialBar: {
              dataLabels: {
                value: {
                  formatter: function (val) {
                    return parseInt(val);
                  },
                },
              },
              hollow: {
                size: "60%",
              },
            },
          },
          labels: [this.state.statusGetName],
        },
      });
    }
  };

  // Edit Popup > function
  editBtn = (index) => {
    // this.setState({
    //   statusGetName: '',
    //   statusGetValue: ''
    // })
    var getName = this.state.hedgeometerArray[index]?.status;
    var getValue = this.state.hedgeometerArray[index]?.value;
    var getDesc = this.state.hedgeometerArray[index]?.description;
    var getId = this.state.hedgeometerArray[index]?.id;
    this.setState({
      statusGetName: getName,
      statusGetValue: getValue,
      popupdescription: getDesc,
      statusIndex: index,
      activeId: getId,
    });
  };

  render() {
    return (
      <section className="admin-hedgeometer">
        <h1 className="head text-uppercase m-0">hedgeometer</h1>
        <div className="row pt-4">
          <div className="col-lg-6">
            <section className="lft-blk pe-4">
              {/* <p className="common-para text-uppercase">Status <span className="plus-btn" data-bs-toggle="modal" data-bs-target="#statusModal" onClick={this.addBtn}>&nbsp;</span></p> */}
              <p className="common-para text-uppercase">Status</p>
              <ul className="row mb-0 ps-0 pt-2">
                {this.state.hedgeometerArray?.map((hom, index) => {
                  return (
                    <li
                      className={`col-lg-6 ${
                        this.state.hedgeometerCurrentStatus?.filter(
                          (item) => item.active === true
                        )[0]?.value === hom.value
                          ? "active"
                          : ""
                      }`}
                      key={index}
                    >
                      {/* <div className="box d-flex justify-content-center" onClick={() => this.statusUpdatedChart(index)}> */}
                      <div
                        className="box d-flex justify-content-center"
                        onClick={() =>
                          this.updateCurrentStatus(hom.status, hom.value, index)
                        }
                      >
                        <div className="box-inner d-flex align-items-center">
                          <h2>
                            {hom.status}{" "}
                            <span className="d-block text-center">
                              {" "}
                              <strong className="d-block"></strong>
                            </span>
                          </h2>
                          {/* {hom.value} */}
                        </div>
                        <span
                          className="edit-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#statusModal"
                          onClick={() => this.editBtn(index)}
                        >
                          <i class="fa fa-pencil fs-6"></i>
                        </span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </section>
          </div>

          <div className="col-lg-6">
            <div id="cpWrapper" label="Dashboard/speedometer">
              {this.state.hedgeometerCurrentStatus?.length > 0 ? (
                <>
                  <AnimatedProgressProvider
                    valueStart={0}
                    valueEnd={
                      this.state.hedgeometerCurrentStatus?.filter(
                        (item) => item.active === true
                      )[0]?.value / 1.7
                    }
                    duration={1.4}
                    easingFunction={easeQuadInOut}
                  >
                    {(value) => {
                      //const roundedValue = Math.round(value);
                      return (
                        <CircularProgressbar
                          value={value / 1.7}
                          text={
                            this.state.hedgeometerCurrentStatus?.filter(
                              (item) => item.active === true
                            )[0]?.status
                          }
                          background
                          backgroundPadding={4}
                          circleRatio={0.9}
                          styles={buildStyles({
                            rotation: 0.55,
                            backgroundColor: "#fff",
                            //rotation: 1 / 2 + 1 / 8,
                            //strokeLinecap: "butt",
                            //trailColor: "#eee",
                          })}
                        />
                      );
                    }}
                  </AnimatedProgressProvider>
                  <h3>
                    <span>start date &amp; time</span>
                    {moment(this.state?.hedgeometerCurrentStatus[0].starttime)
                      .utcOffset("-00:00")
                      .format("DD MMM'YY | h:mm A")}
                  </h3>
                </>
              ) : (
                ""
              )}
            </div>
            {this.state.chartShow ? (
              <Chart
                options={this.state.chartOptions}
                series={this.state.chartSeries}
                type="radialBar"
                height={330}
              />
            ) : (
              ""
            )}
            {/* {this.state.hedgeometerCurrentStatus.length > 0 ? (
              <section className="rgt-blk ps-4">
                <p className="common-para text-uppercase">status history</p>
                <div className="table-blk pt-2">
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th>status</th>
                        <th>start date &amp; time</th>
                        <th>end date &amp; time</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.hedgeometerCurrentStatus?.reverse().map((item) => {
                      return (
                        <>
                          {!item.active && 
                          <tr>
                            <td>{item.status}</td>
                            <td>
                              {moment(item.starttime).format(dateTimeFormat1)}
                            </td>
                            <td>{moment(item.endtime).format(dateTimeFormat1)}</td>
                          </tr>}
                        </>
                      );
                    })}
                    </tbody>
                  </table>
                </div>
              </section>
            ) : (
              ""
            )} */}
            {this.state.hedgeometerHistory.length > 0 ? (
              <section className={`row m-0 p-0 mt-1`}>
                <p className="common-para text-uppercase">status history</p>

                <aside className={`col-12 m-0 p-0`}>
                  {/* <span className="display-rows-placement">
                Display rows :{" "}
                <select
                  className="display-select"
                  onChange={(e) => {
                    if( 
                      this.state.hedgeometerCurrentStatus.length === 0){
                        this.setState({
                          rowsPerPage: 1,
                        });
                      }
                    else if (
                      e.target.value === "All"
                    ) {
                      this.setState({
                        rowsPerPage: this.state.hedgeometerCurrentStatus.length,
                      });
                    } else {
                      this.setState({ rowsPerPage: e.target.value });
                    }
                  }}
                >
                  {[5, 10, 15, 20, "All"].map((item) => {
                    return <option value={item}>{item}</option>;
                  })}
                </select>
              </span> */}
                  <Datatable
                    tableHeaders={this.state.header}
                    id="table-to-xls"
                    tableBody={this.state.hedgeometerHistory
                      ?.reverse()
                      .filter((item) => item.active === false)}
                    rowsPerPage={this.state.rowsPerPage}
                    labels={{
                      filterPlaceholder: "Search",
                    }}
                  />
                </aside>
              </section>
            ) : null}
          </div>
        </div>

        <div
          className="modal fade status-popup-blk"
          id="statusModal"
          aria-labelledby="statusModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="statusModalLabel">
                  Status
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="form-group pt-2 pb-2">
                  <input
                    type="text"
                    className="form-control"
                    name="statusGetName"
                    placeholder="Name"
                    autoComplete="off"
                    onChange={(e) => this.getNameOnchange(e)}
                    value={this.state.statusGetName}
                  />
                </div>
                {/* <div className="form-group pt-2 pb-2"> */}
                {/* <input type="text" className="form-control" name="statusGetValue" placeholder="Value" autoComplete="off" onChange={(e) => this.getIVSChart(e)} value={this.state.statusGetValue} maxLength="3" /> */}
                {/* <input type="text" className="form-control" name="statusGetValue" placeholder="Value" autoComplete="off" maxLength="3" onChange={(e) => this.getNameOnchange(e)} value={this.state.statusGetValue} /> */}
                {/* </div> */}
                {/* {this.state.statusGetValue==='' ? '':<Chart options={this.state.popupOptions} series={this.state.popupSeries} type="radialBar" height={300} /> } */}
                <div className="form-group pt-2 pb-2">
                  <textarea
                    value={this.state.popupdescription}
                    className="form-control"
                    rows="5"
                    cols="10"
                    id="hedgeometerPopUp"
                    placeholder="Description"
                    onChange={(e) => {
                      this.setState({ popupdescription: e.target.value });
                    }}
                  ></textarea>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={(e) => {
                    this.updateValues();
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Hedgeometer;

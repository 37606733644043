import React from "react";
import Datatable from "react-bs-datatable";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Tablecomp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 5,
      total:0,
      header : [
        { title: "Date", prop: "date" },
        { title: "Stock", prop: "stock" },
        { title: "Strategy", prop: "strategy" },
        { title: "Capital", prop: "capital" },
        { title: "Profit/Loss (INR) ", prop: "pl" },
      ],
      stockResult:props.state.stockResultAPIData
    };
  }

componentDidUpdate=(prevProps, prevState)=>{
  if(prevProps.state.stockResultAPIData !== this.props.state.stockResultAPIData){
    let totalCap = this.props.state.stockResultAPIData.reduce((accumulator, object) => {
      return accumulator + Number(object.capital.split("₹ ").pop());
    }, 0)
    let totalPl = this.props.state.stockResultAPIData.reduce((accumulator, object) => {
      return accumulator + Number(object.pl.split("₹ ").pop());
    }, 0)
    this.setState({
      stockResult:  [...this.props.state.stockResultAPIData, {
        "date": "Total",
        "stock": "",
        "strategy": "",
        "capital": `₹ ${totalCap}`,
        "pl": `₹ ${totalPl}`
    }]
    })
  }
}



  render() {
    return (
      <>
        <section className={`innerblk`}>
          <h4 className="d-none">Results - / 2021</h4>
          <section className={`infoblk row`}>
            <aside className={`col-lg-4 d-none`}>
              <h5>
                hit rate <span>91%</span>
              </h5>
              <h5>
                ytd roc <span>85%</span>
              </h5>
            </aside>
            <aside className={`col-lg-9`}></aside>
            <aside className={`col-lg-3 btnexport`}>
              <ExcelFile
                filename={`${this.props.state.TraderType}_${this.props.state.MonthType}`}
                element={<button>Export in Excel</button>}
              >
                <ExcelSheet
                  data={this.state.stockResult}
                  name="Employees"
                >
                  <ExcelColumn label="date" value="date" />
                  <ExcelColumn label="stock" value="stock" />
                  <ExcelColumn label="strategy" value="strategy" />
                  <ExcelColumn label="capital" value="capital" />
                  <ExcelColumn label="pl" value="pl" />
                </ExcelSheet>
              </ExcelFile>
            </aside>
          </section>
        </section>

        {/* <span className="display-rows-placement">
          Display rows :{" "}
          <select
            className="display-select"
            onChange={(e) => {
              if (this.props.state.stockResultAPIData.length === 0) {
                this.setState({
                  rowsPerPage: 1,
                });
              } else if (e.target.value === "All") {
                this.setState({
                  rowsPerPage: this.props.state.stockResultAPIData.length,
                });
              } else {
                this.setState({ rowsPerPage: e.target.value });
              }
            }}
          >
            {[5, 10, 15, 20, "All"].map((item) => {
              return <option value={item}>{item}</option>;
            })}
          </select>
        </span> */}
        <Datatable
        //  tableHeaders={this.props.state.header}
         tableHeaders={this.state.header}
          id="table-to-xls"
          tableBody={this.state.stockResult}
        //  tableBody={body}
          // rowsPerPage={this.state.rowsPerPage}
          labels={{ filterPlaceholder: "Search" }}
        />
      </>
    );
  }
}

export default Tablecomp;

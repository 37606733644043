import React from "react";

class EquitySaveProceedLeft extends React.Component {
  render() {
    console.log(this.props.call_type, 'call_type', this.props.state.free);
    return (
      <section className="admin-saveProceedLeft">
        <section className="pv-inner">
          <section className="row">
            <div className="col-lg-12">
              <ul className="list-group list-group-horizontal">
                <li className={`list-group-item col d-block`}>
                  <span>Call For</span>
                  <span className="price">
                    {/* {this.props.call_type} */}
                    {this.props.state.free
                      ? "Free"
                      : this.props.state.prime
                        ? "Prime"
                        : this.props.state.crown ? "Crown" : "2HR Trader"}
                    {/* {this.props.state.free ? "Free" : this.props.state.prime ? "Prime" : 'Crown'} */}
                  </span>
                </li>
                <li className={`list-group-item col d-block`}>
                  <span>Call Target</span>
                  <span className="price">
                    {this.props.state.experienced ? "Experienced" : this.props.state.newbie ? "Newbie" : 'Both'}
                  </span>
                </li>
                <li
                  className={`list-group-item col ${this.props.state.equity ? "d-block" : "d-none"
                    }`}
                >
                  <span>Call Class</span>
                  <span className="price">Equity</span>
                </li>
                <li
                  className={`list-group-item col ${this.props.state.long ? "d-block" : "d-none"
                    }`}
                >
                  <span>Call Type</span>
                  <span className="price">
                    {this.props.state.long ? "Long" : "Short"}
                  </span>
                </li>
              </ul>
            </div>
          </section>
          <section className="row">
            <div className="col-lg-12">
              <ul className="list-group">
                <li className="list-group-item">
                  <span>Which scrip?</span>
                  <span className="price">
                    {this.props.state.companyName}{" "}
                    {this.props.state.priceVal === ""
                      ? ""
                      : ` - ${this.props.state.priceVal}`}
                  </span>
                </li>
              </ul>
            </div>
          </section>
          <section className="row pt-5">
            <h2 className="mb-0">Entry &amp; Exit</h2>
            <div className="col-lg-6">
              <ul className="list-group">
                <li className="list-group-item">
                  <span>Entry Price &amp; Volume</span>
                  <span className="price">
                    Rs. {this.props.state.entryPrice} (
                    {this.props.state.entryPriceQuantity})
                  </span>
                </li>
                <li className="list-group-item">
                  <span>Exit Price &amp; Volume</span>
                  <span className="price">
                    Rs. {this.props.state.exitPrice} (
                    {this.props.state.exitPriceQuantity})
                  </span>
                </li>
                <li className="list-group-item">
                  <span>Stop Loss</span>
                  <span className="price">Rs. {this.props.state.stopLoss}</span>
                </li>
                <li className="list-group-item">
                  <span>Capital Required</span>
                  <span className="price">
                    Rs. {this.props.state.capitalRequd}
                  </span>
                </li>
                <li className="list-group-item">
                  <span>Max Profit</span>
                  <span className="price">
                    Rs. {this.props.state.maxProfit}
                  </span>
                </li>
                <li className="list-group-item">
                  <span>Max Loss</span>
                  <span className="price">Rs. {this.props.state.maxLoss}</span>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <ul className="list-group">
                {this.props.state.target.map((t, index) => {
                  return (
                    <li className="list-group-item" key={index}>
                      <span>Target {index + 1}</span>
                      <span className="price">Rs. {t}</span>
                    </li>
                  );
                })}
              </ul>
              {/* <li className="list-group-item"><span>Target 1</span><span className="price">Rs, 10,00</span></li>
                  <li className="list-group-item"><span>Target 2</span><span className="price">Rs, 10,00</span></li> */}
            </div>
          </section>
        </section>
      </section>
    );
  }
}

export default EquitySaveProceedLeft;

import React, {useState, useEffect} from "react";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Bannermodal(props) {
  const [onstartDate, setonStartDate] = useState(new Date());
  const [onstartTime, setonStartTime] = useState(new Date());
  const [tilstartDate, settilStartDate] = useState(new Date());
  const [tilstartTime, settilStartTime] = useState(new Date());
  const [checkboxRightNow, setRightNow] = useState(false);
  const [checkboxForever, setForever] = useState(false);
  const stOnstDt = (date) => {
    setonStartDate(date);
    setTimeout(function () {
      let getinfo = document.getElementById("onsetdate").value;
      props.setTiming(getinfo, "publishondate");
      // console.log(getinfo);
    }, 1000);
  };
  const stOnstTme = (date) => {
    setonStartTime(date);
    setTimeout(function () {
      let getinfo = document.getElementById("onsettime").value;
      props.setTiming(getinfo, "publishontime");
    }, 1000);
  };
  const settilstDt = (date) => {
    settilStartDate(date);
    setTimeout(function () {
      let getinfo = document.getElementById("settildate").value;
      props.setTiming(getinfo, "publishtilldate");
    }, 1000);
  };
  const settilstTme = (date) => {
    settilStartTime(date);
    setTimeout(function () {
      let getinfo = document.getElementById("settiltime").value;
      props.setTiming(getinfo, "publishtilltime");
    }, 1000);
  };
  const addBanner = () => {
    let getdate = new Date();
    setonStartDate(getdate);
    setonStartTime(getdate);
    settilStartDate(getdate);
    settilStartTime(getdate);
    props.addBanner();
  };
  const ImageThumb = ({image}) => {
    return <img src={URL.createObjectURL(image)} alt={image.name} className={`img-responsive`} />;
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className={`modalblk`}>
      <Modal.Body>
        {props.stateinfo.alertbox && (
          <div className="alert alert-danger" role="alert">
            Please fill out all fields
          </div>
        )}
        <h3 className={`mb-3`}>Upload Banner</h3>
        <section className={`mdl-innerblk text-center`}>
          <section className="dndblk">
            <label className="my-4 text-center">Drag and drop a file here or click browse</label>
            <input type="file" onChange={props.handleUpload} name="myImage" placeholder="Browse" accept="image/x-png,image/gif,image/jpeg" id="imagefile" />
            <button className={`btnbrwose`}>Browse</button>
            {props.stateinfo.fileinfo && <ImageThumb image={props.stateinfo.fileinfo} />}
            <aside className={`overlay ${props.state.uploadingOverlay ? "" : "d-none"}`}>
              <span>Uploading...</span>
            </aside>
          </section>
          <p className="error-msg">{props.state.uploadErrorMsg}</p>
          <i>Recommended image size - 640 x 230</i>
          <section className="row d-flex flex-row modalformblk">
            <aside className="col-lg-3 p-0">
              <h4>Publish on</h4>
              <DatePicker
                placeholderText="Select Date"
                minDate={new Date()}
                selected={onstartDate}
                onChange={(date) => {
                  setRightNow(false);
                  stOnstDt(date);
                }}
                id="onsetdate"
              />
              <DatePicker
                placeholderText="Select Time"
                selected={onstartTime}
                onChange={(date) => {
                  setRightNow(false);
                  stOnstTme(date);
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={10}
                timeCaption="Time"
                dateFormat="h:mm aa"
                id="onsettime"
              />
              <aside>
                <label htmlFor="rightnow">Right Now</label>
                <input
                  type="checkbox"
                  value="Right Now"
                  name="rightnow"
                  id="rightnow"
                  checked={checkboxRightNow}
                  onChange={(e) => {
                    setRightNow(!checkboxRightNow);
                    stOnstDt(new Date());
                    stOnstTme(new Date());
                    props.getStatus(e);
                  }}
                />
              </aside>
            </aside>
            <aside className="col-lg-1"></aside>
            <aside className="col-lg-3 p-0">
              <h4>Publish till</h4>
              <DatePicker
                placeholderText="Select Date"
                minDate={onstartDate}
                selected={tilstartDate === "" ? "" : new Date(tilstartDate) > new Date(onstartDate) ? tilstartDate : onstartDate}
                //selected={tilstartDate}
                onChange={(date) => {
                  setForever(false);
                  settilstDt(date);
                }}
                id="settildate"
              />
              <DatePicker
                placeholderText="Select Time"
                selected={tilstartTime}
                onChange={(date) => {
                  setForever(false);
                  settilstTme(date);
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={10}
                timeCaption="Time"
                dateFormat="h:mm aa"
                id="settiltime"
              />
              <aside>
                <label htmlFor="forever">Forever</label>
                <input
                  type="checkbox"
                  value="Forever"
                  name="forever"
                  id="forever"
                  checked={checkboxForever}
                  onChange={(e) => {
                    setForever(!checkboxForever);
                    settilStartDate("");
                    settilStartTime("");
                    props.setTiming(new Date(new Date().setFullYear(new Date().getFullYear() + 10)), "publishtilldate");
                    props.setTiming("", "publishtilltime");
                    props.getStatus(e);
                  }}
                />
              </aside>
            </aside>
            <aside className="col-lg-1"></aside>

            <aside className="col-lg-4 p-0">
              <h4>Publish for</h4>
              <aside className="row">
                <aside className="col-lg-6 firstblk pt-1 pb-2">
                  <aside>
                    <input type="radio" name="tradtyp" value="All" id="all" onChange={(e) => props.getradioinfo(e)} />
                    <label htmlFor="all">
                      All <i>&nbsp;</i>
                    </label>
                  </aside>
                  <aside>
                    <input type="radio" name="tradtyp" value="Experienced" id="experienced" onChange={(e) => props.getradioinfo(e)} />
                    <label htmlFor="experienced">
                      Experienced <i>&nbsp;</i>
                    </label>
                  </aside>
                  <aside>
                    <input type="radio" name="tradtyp" value="Newbie" id="newbie" onChange={(e) => props.getradioinfo(e)} />
                    <label htmlFor="newbie">
                      Newbie <i>&nbsp;</i>
                    </label>
                  </aside>
                </aside>
                <aside className="col-lg-6 pt-1">
                  <aside>
                    <input type="radio" name="tradmembership" value="1" id="free" onChange={(e) => props.getradioinfo(e)} />
                    <label htmlFor="free">
                      Free <i>&nbsp;</i>
                    </label>
                  </aside>
                  <aside>
                    <input type="radio" name="tradmembership" value="2" id="prime" onChange={(e) => props.getradioinfo(e)} />
                    <label htmlFor="prime">
                      Prime <i>&nbsp;</i>
                    </label>
                  </aside>
                  <aside>
                    <input type="radio" name="tradmembership" value="3" id="crown" onChange={(e) => props.getradioinfo(e)} />
                    <label htmlFor="crown">
                      Crown <i>&nbsp;</i>
                    </label>
                  </aside>
                </aside>
              </aside>
            </aside>
          </section>
          {/* <button className="btnconfirm" onClick={addBanner}> */}
          <button className={`btnconfirm ${props.modelConfirmBtnDisabled() ? "" : "disabled"}`} onClick={addBanner}>
            Confirm
          </button>
        </section>
      </Modal.Body>
    </Modal>
  );
}

export default Bannermodal;

import React, { Component } from "react";
import * as API from "../../configuration/apiconfig";
import Datatable from "react-bs-datatable";
import download from "downloadjs";
import FileDownload from "js-file-download";
import moment from "moment-timezone";
import swal from "sweetalert";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import jsPdf from "jspdf";

export class userDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentDetailsData: [],
      rowsPerPage: 5,
      header: [
        // { title: "Index", prop: "id" },
        {
          title: "Order Id",
          prop: "subscriptionid",
          cell: (row) => {
            return (
              <>
                <p>
                  {row.subscriptionid === null ? "---" : row.subscriptionid}
                </p>
              </>
            );
          },
        },
        {
          title: "Payment mode",
          prop: "transactionDetails",
          cell: (row) => {
            return (
              <>
                <p>
                  {row?.transactionDetails === null
                    ? "---"
                    : row?.transactionDetails?.paymentMethod}
                </p>
              </>
            );
          },
        },
        { title: "Plan", prop: "planName" },
        { title: "Price Before Tax", prop: "priceBeforeTax" },
        { title: "Currency", prop: "currency" },
        { title: "Tax value", prop: "taxValue" },
        { title: "Value", prop: "value" },
        // { title: "Details", prop: "transactionDetails" },
        {
          title: "Date of Transaction",
          prop: "transactiondate",
          cell: (row) => {
            return (
              <>
                <p>
                  {moment(row.transactiondate)
                    .utcOffset("-00:00")
                    .format("DD MMM'YY | h:mm A")}
                </p>
              </>
            );
          },
        },
        // { title: "Invoice", prop: "transactiondate" },
        {
          prop: "actions",
          title: "Invoice",
          cell: (row) => {
            return (
              <>
                <a onClick={() => this.getInvoice(row.subscriptionid)}>
                  Download
                </a>
              </>
            );
          },
        },
      ],
      userDetails: {},
    };
  }

  componentDidMount = async () => {
    this.fetchcall();
  };

  getInvoice = async (id) => {
    return await API.callDownloadEndpoint(
      "GET",
      "Bearer",
      `/api/subscription/invoice?id=${id}`
    ).then((res) => {
      const blobData = new Blob([res.data], { type: "application/pdf" });

      const url = window.URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoice" + new Date().getDate() + ".pdf");
      document.body.appendChild(link);
      link.click();
    });
  };

  fetchcall = async () => {
    let path = this.props.location.search;
    let id = parseInt(path.slice(path.indexOf("id=") + 3));

    return await API.callEndpoint(
      "GET",
      "Bearer",
      `/api/subscription/paymentdetails?userid=${id}`
    )
      .then((response) => {
        let fetchData = response.data.data.data;
        let fetchDetails = response.data.data.details;
        // console.log(fetchData, "get data");

        let getbndldtl = fetchData.filter((bndldtl) => {
          return bndldtl.id == id;
        });
        // console.log(getbndldtl,'filter');
        this.setState({
          paymentDetailsData: fetchData,
          userDetails: fetchDetails,
        });
        // console.log(this.state.bundleDetailsData);
      })
      .catch((error) => {
        console.log(error.error);
        swal({
          text: "An error occured, try again!",
          icon: "info",
        });
      });
  };
  render() {
    return (
      <section className={`usermanage`}>
        <section className={`contblk`}>
          <h1 className="head text-uppercase m-0 mt-lg-4 mb-lg-4 pt-lg-3 pb-lg-2">
            User Payment Details
          </h1>
          <aside className="notes">
            <p>
              <strong>Name: </strong>
              <span>{this.state.userDetails.name}</span>
            </p>
            <p>
              <strong>Email: </strong>
              <span>{this.state.userDetails.email}</span>
            </p>
          </aside>
          <section className={`row m-0 p-0 mt-5`}>
            <aside className={`col-12 m-0 p-0`}>
              <span className="display-rows-placement">
                Display rows :{" "}
                <select
                  className="display-select"
                  onChange={(e) => {
                    if (this.state.paymentDetailsData.length === 0) {
                      this.setState({
                        rowsPerPage: 1,
                      });
                    } else if (e.target.value === "All") {
                      this.setState({
                        rowsPerPage: this.state.paymentDetailsData.length,
                      });
                    } else {
                      this.setState({ rowsPerPage: e.target.value });
                    }
                  }}
                >
                  {[5, 10, 15, 20, "All"].map((item) => {
                    return <option value={item}>{item}</option>;
                  })}
                </select>
              </span>
              <Datatable
                tableHeaders={this.state.header}
                id="table-to-xls"
                tableBody={this.state.paymentDetailsData}
                rowsPerPage={this.state.rowsPerPage}
                labels={{
                  filterPlaceholder: "Search",
                }}
              />
            </aside>
          </section>
        </section>
      </section>
    );
  }
}

export default userDetails;

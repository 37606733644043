import React from "react";
import * as API from "../../configuration/apiconfig";
import moment from "moment";
import { DateTimeFormatISO } from "../dateTimeFormat/DateTimeFormat";

const dateTimeFormat1 = "DD/MM/YYYY";
const dateTimeFormat2 = "DD/MM/YY";
const childAccordionDate = "DD MMM'YY";

class BundleDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // bundleDetailsData: this.props.state.callDetailData[0],
      bundleDetailsData: [],
    };
  }

  componentDidMount = async () => {
    this.fetchcall();
  };

  fetchcall = async () => {
    let path = this.props.props.location.search;
    let id = parseInt(path.slice(path.indexOf("id=") + 3));

    return await API.callEndpoint(
      "GET",
      "Bearer",
      `/api/bundles?childtables=calls&childtables=history&id=${id}`
    )
      .then((response) => {
        let fetchData = response.data;
        // console.log(fetchData, "get data");

        let getbndldtl = fetchData.filter((bndldtl) => {
          return bndldtl.id == id;
        });
        // console.log(getbndldtl,'filter');
        this.setState({ bundleDetailsData: getbndldtl[0] });
        // console.log(this.state.bundleDetailsData);
      })
      .catch((error) => {
        console.log(error.error);
      });
  };

  render() {
    return (
      <section className="admin-call-details bundle-details-page">
        <h1 className="head text-uppercase m-0">Live Funds DETAILS</h1>
        <section className="bundle-tabs-blk">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link py-2 px-4 active"
                id="current-update-tab"
                data-bs-toggle="tab"
                data-bs-target="#current-update"
                type="button"
                role="tab"
                aria-controls="current-update"
                aria-selected="true"
              >
                Current update
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link py-2 px-4"
                id="tracker-tab"
                data-bs-toggle="tab"
                data-bs-target="#tracker"
                type="button"
                role="tab"
                aria-controls="tracker"
                aria-selected="false"
              >
                Tracker
              </button>
            </li>
          </ul>

          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="current-update"
              role="tabpanel"
              aria-labelledby="current-update-tab"
            >
              {this.state.bundleDetailsData != undefined &&
                this.state.bundleDetailsData != null && (
                  <>
                    {this.state.bundleDetailsData.bundles_histories !=
                      undefined &&
                      this.state.bundleDetailsData.bundles_histories.length >
                      0 && (
                        <>
                          {this.state.bundleDetailsData.bundles_histories.map(
                            (bundleHistory, historyIndex) => {
                              return (
                                <section
                                  className="equity-options-wrapper"
                                  key={historyIndex}
                                >
                                  <aside>
                                    <h3 className="modify-title">
                                      {historyIndex ===
                                        this.state.bundleDetailsData
                                          .bundles_histories.length -
                                        1
                                        ? "Created on "
                                        : "Modified on "}
                                      {bundleHistory.updatedAt != undefined
                                        ? moment(bundleHistory.updatedAt)
                                          .utcOffset("-00:00")
                                          .format("DD/MM/YYYY | hh:mm A")
                                        : ""}
                                    </h3>
                                  </aside>
                                  <aside className="subhead">
                                    <h2>Equity</h2>
                                  </aside>

                                  {/* Equity > start */}
                                  <section className="equity-blk">
                                    <section className="box-blk">
                                      <aside className="box" key={historyIndex}>
                                        <aside className="inner-box">
                                          <section className="bundle-table-blk">
                                            <table className="table">
                                              <thead>
                                                <tr>
                                                  <th>Stock</th>
                                                  <th>Date/Time</th>
                                                  <th>Quantity</th>
                                                  <th>Entry Price</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {bundleHistory.bundles_calls !=
                                                  undefined &&
                                                  bundleHistory.bundles_calls
                                                    .length > 0 && (
                                                    <>
                                                      {bundleHistory.bundles_calls
                                                        .sort(function (a, b) {
                                                          if (
                                                            a.updatedAt <
                                                            b.updatedAt
                                                          ) {
                                                            return 1;
                                                          }
                                                          if (
                                                            a.updatedAt >
                                                            b.updatedAt
                                                          ) {
                                                            return -1;
                                                          }
                                                          return 0;
                                                        })
                                                        .map(
                                                          (
                                                            bundleCalls,
                                                            callIndex
                                                          ) => {
                                                            if (
                                                              bundleCalls.call_class ==
                                                              "Equity"
                                                            ) {
                                                              return (
                                                                <tr
                                                                  key={
                                                                    callIndex
                                                                  }
                                                                >
                                                                  <td>
                                                                    <span className="top-strip">
                                                                      {bundleCalls.call_status ===
                                                                        "closed"
                                                                        ? "Exit"
                                                                        : bundleCalls.call_status}
                                                                    </span>
                                                                    <span className="name">
                                                                      {
                                                                        bundleCalls.name
                                                                      }
                                                                    </span>
                                                                  </td>
                                                                  <td>
                                                                    {bundleCalls.createdAt !=
                                                                      undefined ? (
                                                                      <p>
                                                                        {moment(
                                                                          bundleCalls.createdAt
                                                                        ).format(
                                                                          "DD/MM/YY "
                                                                        )}
                                                                        <br />
                                                                        {moment(
                                                                          bundleCalls.createdAt
                                                                        ).format(
                                                                          " hh:mm A"
                                                                        )}
                                                                      </p>
                                                                    ) : (
                                                                      "No date and time"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {
                                                                      bundleCalls
                                                                        .info
                                                                        .quantity
                                                                    }{" "}
                                                                    <span>
                                                                      (
                                                                      {
                                                                        bundleCalls
                                                                          .info
                                                                          .quantity_percent
                                                                      }
                                                                      %)
                                                                    </span>
                                                                  </td>
                                                                  <td>
                                                                    &#8377;{" "}
                                                                    {
                                                                      bundleCalls
                                                                        .info
                                                                        .maxprice
                                                                    }{" "}
                                                                    -{" "}
                                                                    <span>
                                                                      {
                                                                        bundleCalls
                                                                          .info
                                                                          .minprice
                                                                      }
                                                                    </span>
                                                                  </td>
                                                                </tr>
                                                              );
                                                            }
                                                          }
                                                        )}
                                                    </>
                                                  )}
                                              </tbody>
                                            </table>
                                          </section>
                                        </aside>
                                        <aside className="notes">
                                          <p>
                                            <strong>Notes: </strong>
                                            {bundleHistory.equity_notes ==
                                              null ? (
                                              ""
                                            ) : (
                                              <span>
                                                {bundleHistory.equity_notes}
                                              </span>
                                            )}
                                          </p>
                                        </aside>
                                      </aside>
                                    </section>
                                  </section>
                                  {/* Equity > end */}

                                  {/* Accorion > start */}
                                  <section className="options-blk">
                                    <section className="box-blk">
                                      <aside className="subhead">
                                        <h2>Hedge</h2>
                                      </aside>
                                      <aside className="box">
                                        <aside className="inner-box">
                                          <ul>
                                            <li>
                                              <section className="accordion-blk">
                                                <div
                                                  className="accordion"
                                                  id={`parentaccordion-${historyIndex}`}
                                                >
                                                  <div className="accordion-item">
                                                    <h2
                                                      className="accordion-header"
                                                      id={`new-parentheading-${historyIndex}`}
                                                    >
                                                      <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#new-parentcollapse-${historyIndex}`}
                                                        aria-expanded="true"
                                                        aria-controls={`new-parentcollapse-${historyIndex}`}
                                                      >
                                                        New
                                                      </button>
                                                    </h2>
                                                    <div
                                                      id={`new-parentcollapse-${historyIndex}`}
                                                      className="accordion-collapse collapse"
                                                      aria-labelledby={`new-parentheading-${historyIndex}`}
                                                      data-bs-parent={`#parentaccordion-${historyIndex}`}
                                                    >
                                                      <div className="accordion-body">
                                                        <section className="child-accordion">
                                                          <div
                                                            className="accordion"
                                                            id={`new-child-accordion-parent-${historyIndex}`}
                                                          >
                                                            {bundleHistory.bundles_calls !=
                                                              undefined &&
                                                              bundleHistory
                                                                .bundles_calls
                                                                .length > 0 && (
                                                                <>
                                                                  {bundleHistory.bundles_calls.map(
                                                                    (
                                                                      optionsBCData,
                                                                      newBCIndex
                                                                    ) => {
                                                                      if (
                                                                        optionsBCData.call_class ==
                                                                        "Option" &&
                                                                        optionsBCData.call_status ==
                                                                        "new"
                                                                      ) {
                                                                        return (
                                                                          <div
                                                                            className="accordion-item"
                                                                            key={
                                                                              newBCIndex
                                                                            }
                                                                          >
                                                                            <h2
                                                                              className="accordion-header"
                                                                              id={`new-child-heading-${newBCIndex}-${historyIndex}`}
                                                                            >
                                                                              <button
                                                                                className="accordion-button collapsed"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target={`#new-child-collapse-${newBCIndex}-${historyIndex}`}
                                                                                aria-expanded="true"
                                                                                aria-controls={`new-child-collapse-${newBCIndex}-${historyIndex}`}
                                                                              >
                                                                                <aside className="options-lft">
                                                                                  <p>
                                                                                    <span>
                                                                                      Strategy
                                                                                    </span>
                                                                                    <span>
                                                                                      |{" "}
                                                                                      {
                                                                                        optionsBCData
                                                                                          .info
                                                                                          .strategy
                                                                                      }
                                                                                    </span>
                                                                                  </p>
                                                                                  <h3>
                                                                                    {
                                                                                      optionsBCData.name
                                                                                    }
                                                                                  </h3>
                                                                                </aside>
                                                                                {/* <aside className="options-rgt">
                                                                                    <aside className="price-blk">
                                                                                      <span className="exit-price">
                                                                                        Exit
                                                                                        Price
                                                                                      </span>
                                                                                      <span className="total">
                                                                                        &#8377;{" "}
                                                                                        {
                                                                                          optionsBCData
                                                                                            .info
                                                                                            .exit_price
                                                                                        }
                                                                                      </span>
                                                                                    </aside>
                                                                                  </aside> */}
                                                                              </button>
                                                                            </h2>
                                                                            <div
                                                                              id={`new-child-collapse-${newBCIndex}-${historyIndex}`}
                                                                              className="accordion-collapse collapse"
                                                                              aria-labelledby={`new-child-heading-${newBCIndex}-${historyIndex}`}
                                                                              data-bs-parent={`#new-child-accordion-parent-${historyIndex}`}
                                                                            >
                                                                              <div className="accordion-body">
                                                                                <section className="child-table-blk">
                                                                                  <table>
                                                                                    <thead>
                                                                                      <tr>
                                                                                        <th>
                                                                                          Strike
                                                                                          and
                                                                                          Expiry
                                                                                        </th>
                                                                                        <th>
                                                                                          Quantity
                                                                                        </th>
                                                                                        <th>
                                                                                          Price
                                                                                        </th>
                                                                                      </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                      {optionsBCData.info.legs_info.map(
                                                                                        (
                                                                                          optionsLFData,
                                                                                          optionsLFIndex
                                                                                        ) => {
                                                                                          return (
                                                                                            <tr
                                                                                              key={
                                                                                                optionsLFIndex
                                                                                              }
                                                                                            >
                                                                                              <td>
                                                                                                <span className="td-display-block">
                                                                                                  {
                                                                                                    optionsLFData.type
                                                                                                  }{" "}
                                                                                                  {
                                                                                                    optionsLFData.series
                                                                                                  }{" "}
                                                                                                  {
                                                                                                    optionsLFData.strike_price
                                                                                                  }
                                                                                                </span>
                                                                                                <span className="td-display-block">
                                                                                                  {optionsLFData.exit_price !==
                                                                                                    undefined &&
                                                                                                    optionsLFData.exit_price !==
                                                                                                    "" ? (
                                                                                                    <span className="exit-box">
                                                                                                      Exit
                                                                                                    </span>
                                                                                                  ) : (
                                                                                                    ""
                                                                                                  )}
                                                                                                </span>
                                                                                              </td>
                                                                                              <td>
                                                                                                {optionsLFData.type ==
                                                                                                  "buy"
                                                                                                  ? `+${optionsLFData.quantity}`
                                                                                                  : `-${optionsLFData.quantity}`}
                                                                                              </td>
                                                                                              <td>
                                                                                                &#8377;{" "}
                                                                                                {
                                                                                                  optionsLFData.price
                                                                                                }
                                                                                              </td>
                                                                                            </tr>
                                                                                          );
                                                                                        }
                                                                                      )}
                                                                                    </tbody>
                                                                                  </table>
                                                                                </section>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        );
                                                                      }
                                                                    }
                                                                  )}
                                                                </>
                                                              )}
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="accordion-item">
                                                    <h2
                                                      className="accordion-header"
                                                      id={`modified-parentheading-${historyIndex}`}
                                                    >
                                                      <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#modified-parentcollapse-${historyIndex}`}
                                                        aria-expanded="true"
                                                        aria-controls={`modified-parentcollapse-${historyIndex}`}
                                                      >
                                                        Modified
                                                      </button>
                                                    </h2>
                                                    <div
                                                      id={`modified-parentcollapse-${historyIndex}`}
                                                      className="accordion-collapse collapse"
                                                      aria-labelledby={`modified-parentheading-${historyIndex}`}
                                                      data-bs-parent={`#parentaccordion-${historyIndex}`}
                                                    >
                                                      <div className="accordion-body">
                                                        <section className="child-accordion">
                                                          <div
                                                            className="accordion"
                                                            id={`modified-child-accordion-parent-${historyIndex}`}
                                                          >
                                                            {bundleHistory.bundles_calls !=
                                                              undefined &&
                                                              bundleHistory
                                                                .bundles_calls
                                                                .length > 0 && (
                                                                <>
                                                                  {bundleHistory.bundles_calls.map(
                                                                    (
                                                                      optionsBCData,
                                                                      modifyBCIndex
                                                                    ) => {
                                                                      if (
                                                                        optionsBCData.call_class ==
                                                                        "Option" &&
                                                                        optionsBCData.call_status ==
                                                                        "modified"
                                                                      ) {
                                                                        return (
                                                                          <div
                                                                            className="accordion-item"
                                                                            key={
                                                                              modifyBCIndex
                                                                            }
                                                                          >
                                                                            <h2
                                                                              className="accordion-header"
                                                                              id={`modified-child-heading-${modifyBCIndex}-${historyIndex}`}
                                                                            >
                                                                              <button
                                                                                className="accordion-button collapsed"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target={`#modified-child-collapse-${modifyBCIndex}-${historyIndex}`}
                                                                                aria-expanded="true"
                                                                                aria-controls={`modified-child-collapse-${modifyBCIndex}-${historyIndex}`}
                                                                              >
                                                                                <aside className="options-lft">
                                                                                  <p>
                                                                                    <span>
                                                                                      Strategy
                                                                                    </span>
                                                                                    <span>
                                                                                      |{" "}
                                                                                      {
                                                                                        optionsBCData
                                                                                          .info
                                                                                          .strategy
                                                                                      }
                                                                                    </span>
                                                                                  </p>
                                                                                  <h3>
                                                                                    {
                                                                                      optionsBCData.name
                                                                                    }
                                                                                  </h3>
                                                                                </aside>
                                                                                {/* <aside className="options-rgt">
                                                                                    <aside className="price-blk">
                                                                                      <span className="exit-price">
                                                                                        Exit
                                                                                        Price
                                                                                      </span>
                                                                                      <span className="total">
                                                                                        &#8377;{" "}
                                                                                        {
                                                                                          optionsBCData
                                                                                            .info
                                                                                            .exit_price
                                                                                        }
                                                                                      </span>
                                                                                    </aside>
                                                                                  </aside> */}
                                                                              </button>
                                                                            </h2>
                                                                            <div
                                                                              id={`modified-child-collapse-${modifyBCIndex}-${historyIndex}`}
                                                                              className="accordion-collapse collapse"
                                                                              aria-labelledby={`modified-child-heading-${modifyBCIndex}-${historyIndex}`}
                                                                              data-bs-parent={`#modified-child-accordion-parent-${historyIndex}`}
                                                                            >
                                                                              <div className="accordion-body">
                                                                                <section className="child-table-blk">
                                                                                  <table>
                                                                                    <thead>
                                                                                      <tr>
                                                                                        <th>
                                                                                          Strike
                                                                                          and
                                                                                          Expiry
                                                                                        </th>
                                                                                        <th>
                                                                                          Quantity
                                                                                        </th>
                                                                                        <th>
                                                                                          Price
                                                                                        </th>
                                                                                      </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                      {optionsBCData.info.legs_info.map(
                                                                                        (
                                                                                          optionsLFData,
                                                                                          optionsLFIndex
                                                                                        ) => {
                                                                                          return (
                                                                                            <tr
                                                                                              key={
                                                                                                optionsLFIndex
                                                                                              }
                                                                                            >
                                                                                              <td>
                                                                                                <span className="td-display-block">
                                                                                                  {
                                                                                                    optionsLFData.type
                                                                                                  }{" "}
                                                                                                  {
                                                                                                    optionsLFData.series
                                                                                                  }{" "}
                                                                                                  {
                                                                                                    optionsLFData.strike_price
                                                                                                  }
                                                                                                </span>
                                                                                                <span className="td-display-block">
                                                                                                  {optionsLFData.exit_price !==
                                                                                                    undefined &&
                                                                                                    optionsLFData.exit_price !==
                                                                                                    "" ? (
                                                                                                    <span className="exit-box">
                                                                                                      Exit
                                                                                                    </span>
                                                                                                  ) : (
                                                                                                    ""
                                                                                                  )}
                                                                                                </span>
                                                                                              </td>
                                                                                              <td>
                                                                                                {optionsLFData.type ==
                                                                                                  "buy"
                                                                                                  ? `+${optionsLFData.quantity}`
                                                                                                  : `-${optionsLFData.quantity}`}
                                                                                              </td>
                                                                                              <td>
                                                                                                &#8377;{" "}
                                                                                                {
                                                                                                  optionsLFData.price
                                                                                                }
                                                                                              </td>
                                                                                            </tr>
                                                                                          );
                                                                                        }
                                                                                      )}
                                                                                    </tbody>
                                                                                  </table>
                                                                                </section>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        );
                                                                      }
                                                                    }
                                                                  )}
                                                                </>
                                                              )}
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="accordion-item">
                                                    <h2
                                                      className="accordion-header"
                                                      id={`exit-parentheading-${historyIndex}`}
                                                    >
                                                      <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#exit-parentcollapse-${historyIndex}`}
                                                        aria-expanded="true"
                                                        aria-controls={`exit-parentcollapse-${historyIndex}`}
                                                      >
                                                        Exit
                                                      </button>
                                                    </h2>
                                                    <div
                                                      id={`exit-parentcollapse-${historyIndex}`}
                                                      className="accordion-collapse collapse"
                                                      aria-labelledby={`exit-parentheading-${historyIndex}`}
                                                      data-bs-parent={`#parentaccordion-${historyIndex}`}
                                                    >
                                                      <div className="accordion-body">
                                                        <section className="child-accordion">
                                                          <div
                                                            className="accordion"
                                                            id={`exit-child-accordion-parent-${historyIndex}`}
                                                          >
                                                            {bundleHistory.bundles_calls !=
                                                              undefined &&
                                                              bundleHistory
                                                                .bundles_calls
                                                                .length > 0 && (
                                                                <>
                                                                  {bundleHistory.bundles_calls.map(
                                                                    (
                                                                      optionsBCData,
                                                                      exitBCIndex
                                                                    ) => {
                                                                      if (
                                                                        optionsBCData.call_class ==
                                                                        "Option" &&
                                                                        optionsBCData.call_status ==
                                                                        "closed"
                                                                      ) {
                                                                        return (
                                                                          <div
                                                                            className="accordion-item"
                                                                            key={
                                                                              exitBCIndex
                                                                            }
                                                                          >
                                                                            <h2
                                                                              className="accordion-header"
                                                                              id={`exit-child-heading-${exitBCIndex}-${historyIndex}`}
                                                                            >
                                                                              <button
                                                                                className="accordion-button collapsed"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target={`#exit-child-collapse-${exitBCIndex}-${historyIndex}`}
                                                                                aria-expanded="true"
                                                                                aria-controls={`exit-child-collapse-${exitBCIndex}-${historyIndex}`}
                                                                              >
                                                                                <aside className="options-lft">
                                                                                  <p>
                                                                                    <span>
                                                                                      Strategy
                                                                                    </span>
                                                                                    <span>
                                                                                      |{" "}
                                                                                      {
                                                                                        optionsBCData
                                                                                          .info
                                                                                          .strategy
                                                                                      }
                                                                                    </span>
                                                                                  </p>
                                                                                  <h3>
                                                                                    {
                                                                                      optionsBCData.name
                                                                                    }
                                                                                  </h3>
                                                                                </aside>
                                                                                <aside className="options-rgt">
                                                                                  <aside className="price-blk">
                                                                                    <span className="exit-price">
                                                                                      Exit
                                                                                      Price
                                                                                    </span>
                                                                                    <span className="total">
                                                                                      &#8377;{" "}
                                                                                      {
                                                                                        optionsBCData
                                                                                          .info
                                                                                          .exit_price
                                                                                      }
                                                                                    </span>
                                                                                  </aside>
                                                                                </aside>
                                                                              </button>
                                                                            </h2>
                                                                            <div
                                                                              id={`exit-child-collapse-${exitBCIndex}-${historyIndex}`}
                                                                              className="accordion-collapse collapse"
                                                                              aria-labelledby={`exit-child-heading-${exitBCIndex}-${historyIndex}`}
                                                                              data-bs-parent={`#exit-child-accordion-parent-${historyIndex}`}
                                                                            >
                                                                              <div className="accordion-body">
                                                                                <section className="child-table-blk">
                                                                                  <table>
                                                                                    <thead>
                                                                                      <tr>
                                                                                        <th>
                                                                                          Strike
                                                                                          and
                                                                                          Expiry
                                                                                        </th>
                                                                                        <th>
                                                                                          Quantity
                                                                                        </th>
                                                                                        <th>
                                                                                          Price
                                                                                        </th>
                                                                                      </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                      {optionsBCData.info.legs_info.map(
                                                                                        (
                                                                                          optionsLFData,
                                                                                          optionsLFIndex
                                                                                        ) => {
                                                                                          return (
                                                                                            <tr
                                                                                              key={
                                                                                                optionsLFIndex
                                                                                              }
                                                                                            >
                                                                                              <td>
                                                                                                <span className="td-display-block">
                                                                                                  {
                                                                                                    optionsLFData.type
                                                                                                  }{" "}
                                                                                                  {
                                                                                                    optionsLFData.series
                                                                                                  }{" "}
                                                                                                  {
                                                                                                    optionsLFData.strike_price
                                                                                                  }
                                                                                                </span>
                                                                                                <span className="td-display-block">
                                                                                                  {optionsLFData.exit_price !==
                                                                                                    undefined &&
                                                                                                    optionsLFData.exit_price !==
                                                                                                    "" ? (
                                                                                                    <span className="exit-box">
                                                                                                      Exit
                                                                                                    </span>
                                                                                                  ) : (
                                                                                                    ""
                                                                                                  )}
                                                                                                </span>
                                                                                              </td>
                                                                                              <td>
                                                                                                {optionsLFData.type ==
                                                                                                  "buy"
                                                                                                  ? `+${optionsLFData.quantity}`
                                                                                                  : `-${optionsLFData.quantity}`}
                                                                                              </td>
                                                                                              <td>
                                                                                                &#8377;{" "}
                                                                                                {
                                                                                                  optionsLFData.price
                                                                                                }
                                                                                              </td>
                                                                                            </tr>
                                                                                          );
                                                                                        }
                                                                                      )}
                                                                                    </tbody>
                                                                                  </table>
                                                                                </section>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        );
                                                                      }
                                                                    }
                                                                  )}
                                                                </>
                                                              )}
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </section>
                                            </li>
                                          </ul>
                                          <aside className="notes">
                                            <p>
                                              <strong>Notes: </strong>
                                              {bundleHistory.hedged_notes ==
                                                null ? (
                                                ""
                                              ) : (
                                                <span>
                                                  {bundleHistory.hedged_notes}
                                                </span>
                                              )}
                                            </p>
                                          </aside>
                                        </aside>
                                      </aside>
                                    </section>
                                  </section>
                                  {/* Accorion > end */}
                                </section>
                              );
                            }
                          )}
                        </>
                      )}
                  </>
                )}
            </div>
            <div
              className="tab-pane fade tracker-blk"
              id="tracker"
              role="tabpanel"
              aria-labelledby="tracker-tab"
            >
              <section className="table-blk">
                <table>
                  <thead>
                    <tr>
                      <th>Published</th>
                      <th>Stock</th>
                      <th>Strategy</th>
                      <th>Closed on</th>
                      <th>
                        Profit/Loss <span>(in INR)</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.bundleDetailsData != undefined &&
                      this.state.bundleDetailsData != null && (
                        <>
                          {this.state.bundleDetailsData.bundles_histories !=
                            undefined &&
                            this.state.bundleDetailsData.bundles_histories
                              .length > 0 && (
                              <>
                                {this.state.bundleDetailsData.bundles_histories.map(
                                  (bundleHistory, historyIndex) => {
                                    if (historyIndex === 0) {
                                      return bundleHistory.bundles_calls
                                        .sort((a, b) => {
                                          if (
                                            new Date(a.updatedAt).getTime() <
                                            new Date(b.updatedAt).getTime()
                                          ) {
                                            return 1;
                                          }
                                          if (
                                            new Date(a.updatedAt).getTime() >
                                            new Date(b.updatedAt).getTime()
                                          ) {
                                            return -1;
                                          }
                                          return 0;
                                        })
                                        .map((bundleCalls, callsIndex) => {
                                          if (
                                            bundleCalls.call_status === "closed"
                                          ) {
                                            return (
                                              <tr key={callsIndex}>
                                                <td>
                                                  {moment(bundleCalls.createdAt)
                                                    .local()
                                                    .format("DD/MM/YY hh:mm A")}
                                                </td>
                                                <td>{bundleCalls.name}</td>
                                                <td>
                                                  {bundleCalls.call_class ==
                                                    "Option"
                                                    ? bundleCalls.info.strategy
                                                    : bundleCalls.call_type}
                                                </td>
                                                <td>
                                                  {moment(bundleCalls.updatedAt)
                                                    .local()
                                                    .format("DD/MM/YY hh:mm A")}
                                                </td>
                                                <td>
                                                  {bundleCalls.close_type !=
                                                    null ? (
                                                    bundleCalls.close_type ==
                                                      "profit" ? (
                                                      <span>
                                                        &#8377;{" "}
                                                        {bundleCalls.returns}
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        &#8377; &#8722;
                                                        {bundleCalls.returns}
                                                      </span>
                                                    )
                                                  ) : (
                                                    "Null"
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        });
                                    }
                                  }
                                )}
                              </>
                            )}
                        </>
                      )}
                  </tbody>
                </table>
              </section>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default BundleDetails;

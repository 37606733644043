import React, { Component } from "react";
import * as API from "../../configuration/apiconfig";
import moment from "moment";

class IntradayCallDetailsPage extends Component {
  constructor() {
    super();
    this.state = {
      getsigleCall: [],
    };
  }

  componentDidMount = async () => {
    await this.fetchCall();
  };

  fetchCall = async () => {
    let path = this.props.location.search;
    let id = parseInt(path.slice(path.indexOf("id=") + 3));
    return await API.callEndpoint("GET", "Bearer", `/api/intraday?id=${id}`)
      .then((response) => {
        this.setState({
          getsigleCall: response?.data?.data,
        });
        // return response.data[0];
      })
      .catch((error) => {
        console.log(error.error);
      });
  };
  render() {
    return (
      <section className="admin-call-details">
        <section className="call-inner">
          <section className={"equity-blk d-bock"}>
            <aside className="subhead">
              {/* <span>scrip </span> */}
              <h2>
                {this.state.getsigleCall[0] &&
                  this.state.getsigleCall[0].scripname?.toUpperCase()}
              </h2>

              <ul>
                <li>
                  {this.state.getsigleCall[0] &&
                    this.state.getsigleCall[0].strategy}
                </li>
                <li>
                  {this.state.getsigleCall[0] &&
                    this.state.getsigleCall[0].call_type}
                </li>
                <li>
                  {this.state.getsigleCall[0] &&
                    this.state.getsigleCall[0].status}
                </li>
              </ul>

              {console.log(
                this.state.getsigleCall[0]?.intrdays_histories?.reverse(),
                "this.state.getsigleCall "
              )}
              {this.state.getsigleCall &&
                this.state.getsigleCall[0]?.intrdays_histories
                  ?.reverse()
                  .map((item, index) => {
                    return (
                      <section className="calldetails-inner" key={index}>
                        <section className="box-blk options-blk">
                          <aside className="box">
                            <h3>
                              {`Call ${
                                index == 0 ? "made" : "modified"
                              } on ${moment(item.createdAt)
                                .utcOffset("-00:00")
                                .format("DD MMM'YY | h:mm A")}`}
                            </h3>

                            {/* new  */}
                            <table>
                              <tr>
                                <th></th>
                                <th className="table-th-exit">Exit Price</th>
                              </tr>
                              <tr>
                                <td>
                                  <aside className="inner-box">
                                    <table>
                                      {item.info.legs.map((subItem, index) => {
                                        return (
                                          <tr key={index}>
                                            <td className="td-action">
                                              {subItem.action}
                                            </td>

                                            <td className="td-series">
                                              {subItem?.series}
                                            </td>
                                            <td> {subItem.strike_price}</td>

                                            <td className="td-center">
                                              {subItem.action == "BUY" ||
                                              subItem.action == "Buy" ||
                                              subItem.action == "buy"
                                                ? `+${subItem.quantity}`
                                                : subItem.action == "SELL" ||
                                                  subItem.action == "Sell" ||
                                                  subItem.action == "sell"
                                                ? `-${subItem.quantity}`
                                                : subItem.action == ""
                                                ? subItem.quantity
                                                : ""}
                                            </td>
                                            <td className="td-center1">
                                              &#8377; {subItem.entry_price}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </table>
                                  </aside>
                                </td>
                                <td>
                                  {/*}   <table className="table-td">
                                  {item.info.legs.map((subItem, index) => {
                                    return (
                                      <tr key={index}>
                                        <td className="td-action1">
                                          {subItem.exit_price === undefined ||
                                          subItem.exit_price === '' ? (
                                            <span>&nbsp;</span>
                                          ) : (
                                            subItem.exit_price
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </table>
                                */}
                                </td>
                              </tr>
                            </table>
                          </aside>
                        </section>
                      </section>
                    );
                  })}
            </aside>
          </section>
        </section>
      </section>
    );
  }
}

export default IntradayCallDetailsPage;

import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import DatePicker from "react-datepicker";
import * as API from "../../configuration/apiconfig";
import "react-datepicker/dist/react-datepicker.css";

export class CreateMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message:"",
      code:""
    };
  }

  getinput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  createErrorMessage = ()=> {
    let data = {
      message: this.state.message || "",
      code: this.state.code || "",
    };
    API.callEndpoint("POST", "Bearer", "/api/errormesg", data)
      .then((response) => {
        try {
          this.setState({
            showSuccess: true,
            showError: false,
          });
          setTimeout(() => {
            this.props.onHide();
           this?.props.getErrorMessages()
          }, 1000);
        } catch (e) {
          this.setState({
            showError: true,
          });
          //  document.getElementById("wrongmess").style.display = "block";
        }
      })
      .catch((ex) => {
        // this.setState({ showLoader: false });
        this.setState({
          showError: true,
        });
        // document.getElementById("wrongmess").style.display = "block";
      });
  };



  render() {
    const { props } = this;
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`modalblkk`}
      >
        <h2
          style={{ fontSize: "25px", textAlign: "center", fontWeight: "600" }}
        >
          Create Message
        </h2>
        <Modal.Body>
          <section className="row m-0 p-0 mt-4 justify-content-center addblk">
            <section className="col-lg-12">
              <section className="select-options-blk">
                <section className="row">
                  <section
                    className="col-lg-12 mb-20"
                    style={{ margin: "10px 0" }}
                  >
                    <section className="forms-blk">
                      <div className="head-1 head-label">Error Code</div>
                      <div className="form-group">
                        <input
                          id="code"
                          type="text"
                          className="form-control"
                          name="code"
                          placeholder="Enter Error Code"
                          autoComplete="off"
                          onChange={(e) => this.getinput(e)}
                          // onChange={(e) =>
                          //   this.props.updateState("bundleTitle", e.target.value)
                          // }
                          value={this.state.code}
                        />
                        {/* <span className="b-editbtn">&nbsp;</span> */}
                      </div>
                    </section>
                  </section>
                </section>
                <section className="row">
                  <section
                    className="col-lg-12 mb-20"
                    style={{ margin: "10px 0" }}
                  >
                    <section className="forms-blk">
                      <div className="head-1 head-label">Message</div>
                      <div className="form-group">

                      <textarea
                    onChange={(e) => this.getinput(e)}
                    className="form-control"
                    name="message"
                    id="message"
                    placeholder="Enter error message"
                    value={this.state.message}
                    autoComplete="off"
                  />

                      </div>
                    </section>
                  </section>
                  </section>

              </section>
              <section className="row justify-content-center p-0 m-0 my-5">
                <section className="col-3">
                  <button
                    className="btnsave"
                    onClick={this.createErrorMessage}
                    disabled={
                      !(
                        this.state.code &&
                        this.state.message 
                      )
                    }
                    style={{padding: "10px"}}
                  >
                    Save
                  </button>
                </section>
              </section>
            </section>
          </section>
        </Modal.Body>
      </Modal>
    );
  }
}

export default CreateMessage;

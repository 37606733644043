import React from "react";

class SystemBulishSaveProceedLeft extends React.Component {

  render() {
    // console.log("this.props.state?.modifyOptionPlan", this.props.state.modifyOptionPlan, this.props.state.optionPlan, ((this.props.state?.modifyOptionPlan == null || this.props.state?.optionplan == 25)||(this.props.state?.modifyEquityPlan == null &&  this.props.state?.equityplan == 25)) ? "Rohan" : "Nupur");
    return (
      <section className="admin-bulishSaveProceedLeft">
        <section className="pv-inner">
          <section className="row">
            <div className="col-lg-12">
              <ul className="list-group list-group-horizontal w-33">
                <li className="list-group-item col">
                  <span>Call Fors</span>
                  <span className="price">
                    {/* {this.props.state.call_type} */}
                    {this.props.state.call_type ? this.props.state.call_type : this.props.state.free
                      ? "Free"
                      : this.props.state.prime
                        ? "Prime"
                        : this.props.state.crown ?
                          "Crown" :
                          ((this.props.state?.modifyOptionPlan == null || this.props.state?.optionplan == 25)||(this.props.state?.modifyEquityPlan == null &&  this.props.state?.equityplan == 25)) ? "System Plan" : "2HR Trader"}
                  </span>
                </li>
                <li className="list-group-item col">
                  <span>Call Target</span>
                  <span className="price">
                    {this.props.state.experienced
                      ? "Experienced"
                      : this.props.state.newbie
                        ? "Newbie"
                        : "Both"}
                  </span>
                </li>
                <li className="list-group-item col">
                  <span>Call Class</span>
                  <span className="price">
                    {this.props.state.option ? "Option" : ""}
                  </span>
                </li>
                {this.props.state.option ? (
                  <li className="list-group-item col">
                    <span>Call Status</span>
                    <span className="price">{this.props.state.callStatus}</span>
                  </li>
                ) : null}

                <li className="list-group-item col">
                  <span>Call Type</span>
                  <span className="price">
                    {this.props.state.optionsBulish ? "Bullish" : ""}
                    {this.props.state.optionsBearish ? "Bearish" : ""}
                    {this.props.state.optionsNeutral ? "Neutral" : ""}
                    {this.props.state.optionsBidirectional ? "Bi-Di" : ""}
                  </span>
                </li>
              </ul>
            </div>
          </section>
          <section className="row pb-4">
            <div className="col-lg-12">
              <ul className="list-group">
                <li className="list-group-item">
                  <span>Which scrip?</span>
                  <span className="price">
                    {this.props.state.optionCompanyName}
                    {this.props.state.optionPriceValue === ""
                      ? ""
                      : ` - ${this.props.state.optionPriceValue}`}
                  </span>
                </li>
              </ul>
            </div>
          </section>
          <section className="row">
            <section className="col-lg-12">
              {this.props.state.legsArray.length === 0 ? (
                ""
              ) : (
                <section className="total-calls-blk">
                  {this.props.state.legsArray.map((legs, index) => {
                    return (
                      <section className="calls" key={index}>
                        <h3>Leg {index + 1}</h3>
                        <p>
                          <span>{legs.legSelect}</span>
                          <span>
                            {legs.enterStrikePrice} {legs.legOption}
                          </span>
                          <span>{legs.enterPrice1}</span>
                          <span>{legs.enterPrice2}</span>
                          <span>{legs.entryQty}</span>
                        </p>
                      </section>
                    );
                  })}
                </section>
              )}
            </section>
          </section>
          <section className="row pt-4">
            <h2 className="mb-0">Entry &amp; Exit</h2>
            <div className="col-lg-12">
              <ul className="list-group">
                <li className="list-group-item">
                  <span>Max Profit</span>
                  <span className="price">
                    Rs. {this.props.state.parMaxProfit}
                  </span>
                </li>
                <li className="list-group-item">
                  <span>Max Loss</span>
                  <span className="price">
                    Rs. {this.props.state.parMaxLoss}
                  </span>
                </li>
                <li className="list-group-item">
                  <span>Lot Size</span>
                  <span className="price">{this.props.state.parLotSize}</span>
                </li>
                <li className="list-group-item">
                  <span>Expected Profit</span>
                  <span className="price">
                    Rs. {this.props.state.parExpectedProfit}
                  </span>
                </li>
                <li className="list-group-item">
                  <span>Capital Requied</span>
                  <span className="price">
                    Rs. {this.props.state.parCapitalRequied}
                  </span>
                </li>
                <li className="list-group-item">
                  <span>Stop Loss</span>
                  <span className="price">
                    Rs. {this.props.state.parStopLoss}
                  </span>
                </li>
                {/* <li className="list-group-item"><span>Stop Loss Type</span><span className="price">{this.props.state.parSelectValue.value}</span></li> */}
                <li className="list-group-item">
                  <span>Stop Loss Type</span>
                  <span className="price">
                    {this.props.state.parSelectValue}
                  </span>
                </li>
              </ul>
            </div>
          </section>
        </section>
      </section>
    );
  }

}
export default SystemBulishSaveProceedLeft;

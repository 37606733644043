import React from "react";
import { DateTimeFormatISO } from "../dateTimeFormat/DateTimeFormat";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import moment from "moment-timezone";

// const dateTimeFormat1 = "DD MMM YYYY | h:mm:ss A";
const dateTimeFormat1 = "DD MMM'YY";

class BundlesTable extends React.Component {
  componentDidMount = () => {
    this.props.getBundleDatas();
  };
  render() {
    //console.log('Bundle Data: ',this.props.state.Bundledatas);
    return (
      <section className="admin-bundleTable-page">
        <h1 className="head text-uppercase m-0">LIVE FUNDS</h1>
        <aside className="add-bundle">
          <h2>
            <NavLink exact activeClassName="active" to={"/bundles/tabs"}>
              Add Live Funds
            </NavLink>
          </h2>
        </aside>
        <section className="vid-mainblk d-lg-block pt-4">
          <ul className="vid-navblk mb-3">
            {this.props.state.navList.map((menu, index) => {
              return (
                <li
                  key={menu.id}
                  style={{
                    color: `${menu.navStatus ? "white" : "black"}`,
                    cursor: "pointer",
                    backgroundColor: `${menu.navStatus ? "grey" : ""}`,
                    marginRight: "1px",
                    borderColor: "#000",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    fontFamily: "Graphik-Medium",
                  }}
                  className={`d-inline-block ${menu.navStatus ? "active" : ""}`}
                  onClick={() => this.props.changemenuTab(menu.tabname, index)}
                >
                  <span className="d-block py-2 px-4">{menu.tabname}</span>
                </li>
              );
            })}
          </ul>
        </section>
        <section className="bundle-table-blk">
          <table className="table">
            <thead>
              <tr>
                <th>Live Funds</th>
                <th>Type of Live Funds</th>
                <th>Subscription</th>
                <th>Created on</th>
                <th>Last modified</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.props.state.Bundledatas &&
                this.props.state.Bundledatas.length > 0 &&
                this.props.state.Bundledatas.filter(
                  (fill) => fill.planid == this.props.state.curTab.id
                ).map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{data.title}</td>
                      <td>{data.bundle_type}</td>
                      <td>
                        {data.planid == "1"
                          ? "Free"
                          : data.planid == "2"
                          ? "Prime"
                          : data.planid == "3"
                          ? "Crown"
                          : " No Target "}
                      </td>
                      <td>
                        {moment(data.createdAt)
                          .utcOffset("-00:00")
                          .format("DD MMM'YY | h:mm A")}
                      </td>
                      <td>
                        {moment(data.updatedAt)
                          .utcOffset("-00:00")
                          .format("DD MMM'YY | h:mm A")}
                      </td>
                      <td>
                        <a
                          href="javascript:;"
                          title="Details"
                          onClick={() => this.props.getDetails(data.id)}
                        >
                          Details
                        </a>
                        {/* <NavLink
                        exact
                        activeClassName="active"
                        to={`/bundles/details?id=${data.id}`}
                        // to={{
                        //   pathname: `/bundles/details?id=${data.id}`,
                        //   bundleDetailsData: data,
                        // }}
                      >
                        Details
                      </NavLink> */}
                        <NavLink
                          exact
                          activeClassName="active"
                          to={{
                            pathname: `/bundles/modify?id=${data.id}`,
                            bundle: data,
                          }}
                        >
                          Modify
                        </NavLink>
                        <a
                          href="javascript:;"
                          title="Remove"
                          // onClick={() => this.props.tableRemoveBtn(index)}
                          onClick={() => this.props.tableRemoveBtn(data.id)}
                        >
                          Delete
                        </a>
                      </td>
                    </tr>
                  );
                })}
            </tbody>

            {/* <tbody>
                {
                  this.props.state.bundleTableData.map((data, index) => {
                    return(
                      <tr key={index}>
                        <td>{data.bundle}</td>
                        <td>{data.traget}</td>
                        {
                          data.createOn.map((create,index) => {
                            return(
                              <td key={index}>{create.time} | {create.date}</td>
                            )
                          })
                        }
                        {
                          data.lastModify.map((modify,i) => {
                            return(
                              <td key={index}>{modify.time} | {modify.date}</td>
                            )
                          })
                        }
                        <td>
                          <NavLink exact activeClassName="active" to={'/bundles/details'}>{data.detailsBtn}</NavLink>
                          <NavLink exact activeClassName="active" to={'/bundles/modify'}>{data.modifyBtn}</NavLink>
                          <a href="#" title="Remove" onClick={() => this.props.tableRemoveBtn(index)}>{data.removeBtn}</a>
                        </td>
                      </tr>      
                    )
                  })
                }
              </tbody> */}
          </table>
        </section>
      </section>
    );
  }
}

export default BundlesTable;
